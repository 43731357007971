/** @jsxImportSource @emotion/react */
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { useTenantFeature } from '@seeeverything/ui.primitives/src/hooks/useTenantFeature.ts';
import { SheetType } from '../../redux/sheets/types.ts';
import { SheetAuditContainer } from '../SheetAudit/SheetAuditContainer.tsx';
import { SheetAutomatedActions } from '../SheetAutomatedActions/SheetAutomatedActions.tsx';
import { SheetBulkUploadClickThroughGridContainer } from '../SheetBulkUploadClickThroughGrid/SheetBulkUploadClickThroughGridContainer.tsx';
import { SheetCommentsListContainer } from '../SheetCommentsList/SheetCommentsListContainer.tsx';
import {
  DashboardType,
  SheetDashboardV2Container,
} from '../SheetDashboardV2/SheetDashboardV2Container.tsx';
import { SheetFormContainer } from '../SheetForm/SheetFormContainer.tsx';
import { SheetFormDesignerContainer } from '../SheetFormDesigner/SheetFormDesignerContainer.tsx';
import { SheetFormsBulkUploadHistoryContainer } from '../SheetFormsBulkUploadHistory/SheetFormsBulkUploadHistoryContainer.tsx';
import { SheetFormTemplatesContainer } from '../SheetFormTemplates/SheetFormTemplatesContainer.tsx';
import { SheetGoalsActionsGridContainer } from '../SheetGoalsActionsGrid/SheetGoalsActionsGridContainer.tsx';
import { SheetGridV2Container } from '../SheetGridV2/SheetGridV2Container.tsx';
import { SheetSchedulesContainer } from '../SheetSchedules/SheetSchedulesContainer.tsx';
import { DashboardV2TemplatesByModule } from '../types.ts';
import { BrandingPanel } from './components/BrandingPanel.tsx';
import { FeedbackPanel } from './components/FeedbackPanel.tsx';

export type SheetContentFactoryProps = {
  dashboardComponentId?: string;
  dashboardKey?: string;
  dashboardType?: DashboardType;
  dashboardV2Templates: DashboardV2TemplatesByModule;
  dateRangeLabel?: string;
  endDate?: string;
  entityId?: string;
  entityType?: 'PERSON' | 'TEAM';
  goalsActionsFilter?: 'Open' | 'All';
  header?: React.ReactElement;
  id: string;
  instanceId?: string;
  isLoading?: boolean;
  startDate?: string;
  type: SheetType;
};

export const SheetContentFactory: React.FC<SheetContentFactoryProps> = ({
  dashboardComponentId,
  dashboardKey,
  dashboardType,
  dateRangeLabel,
  endDate,
  entityId,
  entityType,
  goalsActionsFilter,
  header,
  id,
  instanceId,
  isLoading,
  startDate,
  type,
}) => {
  const feedbackPanelEnabled = useTenantFeature(
    (f) => f.dashboardsFeedbackPanel,
  );

  if (isLoading) return <Spinner center={true} />;

  switch (type) {
    case 'COACHING_DASHBOARD_PERSON':
    case 'COACHING_DASHBOARD_TEAM':
      return (
        <SheetDashboardV2Container
          id={id}
          dashboardType={dashboardType}
          leftPanelBottomContent={
            feedbackPanelEnabled ? <FeedbackPanel /> : <BrandingPanel />
          }
        />
      );

    case 'DASHBOARD_CLICK_THROUGH_GRID':
    case 'V2_GRID':
      return (
        <SheetGridV2Container
          dashboardKey={dashboardKey}
          dashboardType={dashboardType}
        />
      );

    case 'GOALS_ACTIONS_GRID': {
      return (
        <SheetGoalsActionsGridContainer
          entityId={entityId}
          entityType={entityType}
          startDate={startDate}
          endDate={endDate}
          dateRangeLabel={dateRangeLabel}
          initialFilter={goalsActionsFilter}
        />
      );
    }

    case 'BULK_UPLOAD_CLICK_THROUGH_GRID':
      return <SheetBulkUploadClickThroughGridContainer />;

    case 'COMMENTS_LIST':
      return (
        <SheetCommentsListContainer
          dashboardComponentId={dashboardComponentId}
        />
      );

    case 'FORM':
      return <SheetFormContainer instanceId={instanceId} header={header} />;

    case 'FORM_AUDIT_LOG':
      return <SheetAuditContainer />;

    case 'FORMS_BULK_UPLOAD_HISTORY':
      return <SheetFormsBulkUploadHistoryContainer />;

    case 'FORMS_DESIGNER':
      return <SheetFormDesignerContainer />;

    case 'FORMS_DESIGNER_LIST':
      return <SheetFormTemplatesContainer />;

    case 'SETTINGS_SCHEDULES':
      return <SheetSchedulesContainer />;

    case 'SETTINGS_AUTOMATED_ACTIONS':
      return <SheetAutomatedActions />;

    default:
      throw new Error(`Factory: Sheets type '${type}' not supported.`);
  }
};
