import * as R from 'ramda';
import { ChipKey, IChip } from '../../../../api/api.queryBuilder/types.ts';
import { IInternalSelectionState, IInternalState } from '../../types.ts';
import {
  getActiveChip,
  getChipAfter,
  getChipBefore,
  getChipForKey,
  getEditingChip,
} from './selectors.ts';
import {
  clearAllEmptyChips,
  clearCurrentKeyEditingState,
  createChip,
  deleteAllChipsAfter,
  focusTextInput,
  replaceChips,
  sanitizeState,
  updateCurrentEditingChip,
} from './util.ts';

/**
 * ACTIONS
 * =============
 *
 * This file contains the actions (i.e. public api) for the Query Builder state management.
 * An action is something which takes some state, and returns some new state.
 *
 * As this is the public API for the state of the Query Builder, try to keep the surface area
 * as small as possible.
 *
 */

/**
 * Append a chip to the end of the current chip list, and empty the text input.
 * @param internalState the Query Builder state
 * @param chip the chip with data to add to the state
 */
export const addChip = (
  internalState: IInternalState,
  chip: IChip,
): IInternalState => {
  const chipHasNoValue =
    chip.data.value === '' || chip.data.value === undefined;

  const newState = R.pipe(
    (state: IInternalState) => {
      const editingChip = getEditingChip(state);
      if (editingChip) {
        const indexOfChip = state.chips.findIndex(
          ({ key }) => key === editingChip.key,
        );
        if (indexOfChip < 0) {
          return state;
        }
        return deleteAllChipsAfter(state, indexOfChip);
      }
      return state;
    },
    sanitizeState,
    clearAllEmptyChips,
    (state) => replaceChips(state, [...state.chips, chip]),
    (state) => updateTextInput(state, ''),
    (state) =>
      chipHasNoValue
        ? updateCurrentEditingChip(state, chip.key)
        : focusTextInput(state),
  )(internalState);

  return newState;
};

/**
 * Delete the last chip
 * @param state the Query Builder state
 */
export const deleteLastChip = (state: IInternalState): IInternalState =>
  deleteAllChipsAfter(state, state.chips.length - 1);

/**
 * Create a chip from the word surrounding or just before the cursor.
 *
 * 1. Find the proposed type from the input.
 * 2. Check if this type exists in the possible chip
 *  -> Yes, go to 3.
 *  -> No, do nothing.
 * 3. Create a chip with the proposed type from the input.
 * 4. Place the cursor inside the chip in the correct position.
 */
export const addChipFromInput = (state: IInternalState): IInternalState => {
  // Step 1
  const proposedType = state.textInput.trim().toLowerCase();

  // Step 2
  const typeExistsInEntityMap = Boolean(state.entityDictionary[proposedType]);
  if (!typeExistsInEntityMap) return state;

  // Step 3
  const clearedState = sanitizeState(state);
  const newChip = createChip({
    type: proposedType,
  });
  const stateWithChip = addChip(clearedState, newChip);
  const stateWithChipEditing = updateCurrentEditingChip(
    stateWithChip,
    newChip.key,
  );

  return stateWithChipEditing;
};

/**
 * Ensure the state is in a valid state after a chip finishes editing.
 * Operations:
 *  - check the chip has a non-empty value => if not, remove the chip (and potentially the chips to the right of it)
 *  - change the chip to a non-editing state
 *  - select the next "thing" (chip or input)
 */
export const handleChipFinishedEditing = (
  state: IInternalState,
): IInternalState => {
  const nextItemSelected = selectNext(state);
  const stateWithoutEmptyChips = clearAllEmptyChips(nextItemSelected);
  const stateWithoutEmptyChipsAndCorrectEditingState =
    clearCurrentKeyEditingState(stateWithoutEmptyChips);

  return stateWithoutEmptyChipsAndCorrectEditingState;
};

/**
 * Set the target chip to a selected state.
 * - Ensure there are no other chips editing or being selected.
 */
export const handleChipSelect = (
  state: IInternalState,
  key: ChipKey,
  selectionType: IInternalSelectionState['type'] = 'SELECTED',
): IInternalState => {
  // If the current editing chip is not the same as the current chip, clear the editing state on that chip.
  // Assumption: It's okay for a chip to be editing and selected at the same time.
  // TODO: Check above assumption
  const stateWithEditingStateSanitized = (() => {
    const currentEditingChip = getEditingChip(state);
    if (currentEditingChip && currentEditingChip.key !== key) {
      return clearCurrentKeyEditingState(state);
    }
    return state;
  })();

  // Update the current selected chip.
  const newState: IInternalState = {
    ...stateWithEditingStateSanitized,
    textInputFocused: false,
    selectedChip: { key, type: selectionType },
  };

  return newState;
};

/**
 * Transition a chip a state of "showing dropdown"
 * which is a selection state but not actively editing
 * inline within the chip.
 * @param state the Query Builder state
 * @param key the key of the chip to edit
 */
export const handleChipShowDropdown = (
  state: IInternalState,
  key: ChipKey,
): IInternalState => handleChipSelect(state, key, 'DROPDOWN');

/**
 * Transition a chip into an editing state.
 * @param state the Query Builder state.
 * @param key the key of the chip to edit.
 */
export const handleChipStartEditing = (
  state: IInternalState,
  key: ChipKey,
): IInternalState => {
  const clearState = sanitizeState(state);
  const newState = updateCurrentEditingChip(clearState, key);
  return newState;
};

/**
 * Focus the filter input.
 *  - Ensure that no chips are editing or selected.
 * @param state the Query Builder state.
 */
export const handleTextInputStartEditing = (
  state: IInternalState,
): IInternalState => {
  const clearState = sanitizeState(state);
  const newState: IInternalState = {
    ...clearState,
    textInputFocused: true,
  };
  return newState;
};

/**
 * Blur the filter input.
 * @param state the Query Builder state.
 */
export const handleTextInputStopEditing = (
  state: IInternalState,
): IInternalState => ({
  ...state,
  textInputFocused: false,
});

/**
 * Delete a chip, and all chips to the right of it.
 * @param state the Query Builder state.
 * @param chipKey the key of the chip to delete.
 */
export const handleChipDelete = (
  state: IInternalState,
  chipKey: ChipKey,
): IInternalState => {
  const targetChipIndex = state.chips.findIndex((chip) => chip.key === chipKey);
  if (targetChipIndex === -1) return state;

  const stateWithChipDeleted = deleteAllChipsAfter(state, targetChipIndex);
  return handleTextInputStartEditing(stateWithChipDeleted);
};

/**
 * Select the previous search box element (input, chip, etc).
 * - If the input is focused, do nothing
 * - OR, if a chip is selected and:
 *      - a chip exists after it, select that,
 *      - OR, focus the input.
 * - Otherwise, select the first chip, or input.
 */
export const selectNext = (state: IInternalState): IInternalState => {
  // If the text input is focused, it is already the last element, so don't do anything.
  if (state.textInputFocused) {
    return state;
  }

  const activeChip = getActiveChip(state);
  if (activeChip !== undefined) {
    // a chip is active
    const nextChip = getChipAfter(state, activeChip.key);
    const currentChipIsLast = nextChip === undefined;
    if (currentChipIsLast) {
      return focusTextInput(state);
    }
    if (nextChip) {
      // Always true because of the previous condition, but Typescript throws a fit if I don't include it.
      return handleChipSelect(state, nextChip.key);
    }
  }

  // If there is at least one chip, select the first one.
  if (state.chips.length > 0) {
    return handleChipSelect(state, state.chips[0].key);
  } else {
    // If there are no chips, focus the input.
    return focusTextInput(state);
  }
};

/**
 * Select the previous search box element (input, chip, etc).
 * - If a chip is selected and a chip exists before it, select that.
 * - Otherwise, select the last chip.
 */
export const selectPrev = (state: IInternalState): IInternalState => {
  if (state.chips.length === 0) {
    return state;
  }
  const activeChip = getActiveChip(state);
  const prevChip = activeChip
    ? getChipBefore(state, activeChip.key)
    : state.chips[state.chips.length - 1];

  if (!prevChip) {
    return state;
  }

  const newState = handleChipSelect(state, prevChip.key);
  return newState;
};

/**
 * Clear all data and UI state from the Query Builder.
 * @param state the Query Builder state.
 */
export const reset = (state: IInternalState): IInternalState => ({
  ...state,
  chips: [],
  editingChip: undefined,
  inputBlurred: false,
  selectedChip: undefined,
  textInput: '',
  textInputFocused: true,
});

/**
 * Update the current text input value.
 */
export const updateTextInput = (
  state: IInternalState,
  value: string,
): IInternalState => ({
  ...state,
  textInput: value,
});

export const updateChipValue = (
  state: IInternalState,
  key: ChipKey,
  value?: string,
  label?: string,
): IInternalState => {
  const chip = getChipForKey(state, key);
  if (!chip) return state;

  return {
    ...state,
    chips: state.chips.map((existingChip) =>
      existingChip.key !== key
        ? existingChip
        : { ...chip, data: { ...chip.data, value, label } },
    ),
  };
};

export const handleBlur = (state: IInternalState): IInternalState =>
  sanitizeState(state);
