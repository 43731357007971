/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  ActionBar,
  IActionBarButton,
} from '@seeeverything/ui.primitives/src/components/ModalDialog/index.ts';
import { ScrollContainer } from '@seeeverything/ui.primitives/src/components/Scroll/ScrollContainer.tsx';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/Spinner.tsx';
import { Transition } from '@seeeverything/ui.primitives/src/components/Transition/index.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useMemo } from 'react';
import { editDistributionListSlice } from '../../../redux/editDistributionList/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';
import { DistributionListFieldsContainer } from './DistributionListFieldsContainer.tsx';
import {
  DistributionListStatusBar,
  DistributionListStatusBarType,
} from './DistributionListStatusBar.tsx';

export type DistributionListEditPanelContainerProps = {
  isSaving?: boolean;
  status: DistributionListStatusBarType;
  statusMessage?: string;
};

/**
 * Wrapper component for displaying schedule fields in a container with save/cancel actions and status bar.
 */
export const DistributionListEditPanelContainer: React.FC<
  DistributionListEditPanelContainerProps
> = ({ isSaving, status, statusMessage }) => {
  const dispatch = useFormsDispatch();

  const draft = useFormsSelector(
    (state) => state.formEditDistributionList.draft,
  );

  const canSave = useFormsSelector(
    (state) => state.formEditDistributionList.draft?.canSave ?? false,
  );

  const closeAction = useMemo<IActionBarButton>(
    () => ({
      id: 'CANCEL',
      label: 'Cancel',
      isEnabled: !isSaving,
      backgroundAlways: false,
      onAction: () =>
        dispatch(editDistributionListSlice.cancelEditingDistributionList()),
    }),
    [dispatch, isSaving],
  );

  const previewAction = useMemo<IActionBarButton>(
    () => ({
      id: 'PREVIEW',
      label: 'Preview',
      isEnabled: canSave && !isSaving,
      onAction: () => {
        dispatch(
          editDistributionListSlice.previewDistributionList({
            listName: draft.listName,
            rules: draft.rules,
            type: draft.type,
          }),
        );
      },
    }),
    [canSave, dispatch, draft, isSaving],
  );

  const saveAction = useMemo<IActionBarButton>(
    () => ({
      id: 'SAVE',
      label: 'Save',
      isEnabled: canSave && !isSaving,
      onAction: () =>
        dispatch(editDistributionListSlice.saveDistributionList()),
    }),
    [canSave, dispatch, isSaving],
  );

  return (
    <div css={styles.base}>
      <Transition.Collapse in={Boolean(statusMessage)}>
        <DistributionListStatusBar status={status} message={statusMessage} />
      </Transition.Collapse>
      <div css={styles.savingContainer}>
        <Transition.Fade in={isSaving} mountOnEnter={true} unmountOnExit={true}>
          <div css={styles.savingOverlay}>
            <Spinner />
          </div>
        </Transition.Fade>
        <ScrollContainer>
          <div css={styles.fieldsContainer}>
            <DistributionListFieldsContainer />
          </div>
        </ScrollContainer>
      </div>
      <ActionBar
        actions={[closeAction, previewAction, saveAction]}
        style={styles.footerActions}
      />
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: 'white',
  }),
  fieldsContainer: css({
    padding: '5px 30px 5px 30px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: 'white',
  }),
  footerActions: css({
    padding: '5px 15px 5px 15px',
  }),
  savingOverlay: css({
    position: 'absolute',
    inset: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: color.format(0.8),
    zIndex: 1,
  }),
  savingContainer: css({
    position: 'relative',
    flex: '1 1 auto',
  }),
};
