/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { GoalsActionsGridContainer } from '@seeeverything/ui.forms/src/components/GoalsActionsGrid/GoalsActionsGridContainer.tsx';
import { goalsActionsGridSlice } from '@seeeverything/ui.forms/src/redux/goalsActionsGrid/index.ts';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { ToolButtonSet } from '@seeeverything/ui.primitives/src/components/ToolButtonSet/ToolButtonSet.tsx';
import { useTenantLocale } from '@seeeverything/ui.primitives/src/hooks/useTenantLocale.ts';
import { useTenantModule } from '@seeeverything/ui.primitives/src/hooks/useTenantModule.ts';
import { useTrackInViewport } from '@seeeverything/ui.primitives/src/hooks/useTrackInViewport.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import moment from 'moment';
import { useMemo } from 'react';
import { DASHBOARD_INTERSECTION_OPTIONS } from '../common/constants.ts';
import { dateRangeFromFilter, toDateFilter } from '../data/util.ts';
import {
  useDashboardsDispatch,
  useDashboardsSelector,
} from '../redux/store.ts';
import { IDashboardGoalsAndActions } from '../types.ts';

export interface IFactoryGoalsActionsContainerProps {
  entityId: string;
  entityType: 'PERSON' | 'TEAM';
  item: IDashboardGoalsAndActions;
  parentId?: string;
}

export const FactoryGoalsActionsContainer: React.FC<
  IFactoryGoalsActionsContainerProps
> = ({ entityId, entityType, item, parentId }) => {
  const dispatch = useDashboardsDispatch();
  const module = useTenantModule();

  const [forwardedRef, inViewport] = useTrackInViewport({
    dataId: item.id,
    parentDataId: parentId,
    observerOptions: DASHBOARD_INTERSECTION_OPTIONS,
  });

  const actionLabel = useTenantLocale((l) => l.label.action);

  const dateFilter = useDashboardsSelector((state) => toDateFilter(state));

  const dateRange = useMemo(() => {
    const { startDate, endDate } = dateRangeFromFilter(
      dateFilter.selection,
      dateFilter.financialYearStartMonth,
      dateFilter.selectedCustomDates,
    );

    return {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
    };
  }, [dateFilter]);

  const scrollToDataId = useDashboardsSelector(
    (state) => state.scroll.containers.scrollPanel?.scrollToDataId,
  );

  const filter = useDashboardsSelector(
    (state) => state.formGoalActionsGrid.filter,
  );

  const goalsAndActionsLabel =
    module === 'coaching'
      ? `goals and ${str.plural(actionLabel).toLowerCase()}`
      : str.plural(actionLabel).toLowerCase();

  return (
    <div
      css={
        scrollToDataId === item.id
          ? [styles.base, CommonStyles.ScrollPulse]
          : [styles.base]
      }
      id={item.id}
      data-id={item.id}
      parent-data-id={parentId}
      ref={forwardedRef}
    >
      <div css={styles.toolbar}>
        <ToolButtonSet
          border={true}
          toolButtons={[
            {
              id: 'Open',
              icon: Icons.list,
              label: 'Open',
              onClick: () =>
                dispatch(goalsActionsGridSlice.updateFilter({ to: 'Open' })),
              tooltip: `Excludes completed ${goalsAndActionsLabel}.`,
              isSelected: filter === 'Open',
            },
            {
              id: 'All',
              icon: Icons.list,
              label: 'All',
              onClick: () =>
                dispatch(goalsActionsGridSlice.updateFilter({ to: 'All' })),
              tooltip: `Includes completed ${goalsAndActionsLabel}.`,
              isSelected: filter === 'All',
            },
          ]}
        />
      </div>
      <GoalsActionsGridContainer
        dashboardId={item.id}
        dateRangeLabel={dateFilter.selection.toLabel(dateFilter)}
        endDate={dateRange.endDate}
        entityId={entityId}
        entityType={entityType}
        inViewport={inViewport}
        sheetType={'DASHBOARD'}
        startDate={dateRange.startDate}
      />
    </div>
  );
};

const styles = {
  base: css({
    margin: '30px 30px 0 30px',
    display: 'flex',
    flexDirection: 'column',
  }),
  toolbar: css({
    display: 'flex',
    alignSelf: 'flex-end',
  }),
};
