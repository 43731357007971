/** @jsxImportSource @emotion/react */
import { Spacer } from '@seeeverything/ui.primitives/src/components/Spacer/index.ts';
import { DashboardToolButtonsContainer } from '../components/DashboardToolButtons/DashboardToolButtonsContainer.tsx';
import { DashboardComponent } from '../types.ts';
import { parentSectionIdFromItems } from '../util/util.ts';
import { FactoryChartContainer } from './factory.chart.tsx';
import { FactoryCommentsListContainer } from './factory.commentsList.tsx';
import { FactoryDigitalContentContainer } from './factory.digitalContent.tsx';
import { FactoryGoalsActionsContainer } from './factory.goalsActions.tsx';
import { FactoryGridContainer } from './factory.grid.tsx';
import { FactoryIssuesInsightsContainer } from './factory.issuesInsights.tsx';
import { FactoryDashboardSectionContainer } from './factory.section.tsx';

export const dashboardFactory = (
  dashboardItems: DashboardComponent[],
  entityId?: string,
  entityType?: 'PERSON' | 'TEAM',
): React.ReactElement[] => {
  const result = dashboardItems.reduce((acc, item) => {
    switch (item.componentType) {
      case 'CHART': {
        acc.push(
          <FactoryChartContainer
            id={item.id}
            parentId={parentSectionIdFromItems(dashboardItems, item.id)}
            key={`factory-${item.id}`}
          />,
        );
        return acc;
      }
      case 'COMMENTS_LIST': {
        acc.push(
          <FactoryCommentsListContainer
            id={item.id}
            parentId={parentSectionIdFromItems(dashboardItems, item.id)}
            key={`factory-${item.id}`}
          />,
        );
        return acc;
      }
      case 'DIGITAL_CONTENT': {
        acc.push(
          <FactoryDigitalContentContainer
            id={item.id}
            key={item.id}
            parentId={parentSectionIdFromItems(dashboardItems, item.id)}
          />,
        );
        return acc;
      }
      case 'GOALS_AND_ACTIONS': {
        acc.push(
          <FactoryGoalsActionsContainer
            entityId={entityId}
            entityType={entityType}
            parentId={parentSectionIdFromItems(dashboardItems, item.id)}
            item={item}
            key={item.id}
          />,
        );
        return acc;
      }
      case 'GRID': {
        acc.push(
          <FactoryGridContainer
            parentId={parentSectionIdFromItems(dashboardItems, item.id)}
            key={`factory-${item.id}`}
            id={item.id}
          />,
        );
        return acc;
      }
      case 'ISSUES_AND_INSIGHTS': {
        acc.push(
          <FactoryIssuesInsightsContainer
            id={item.id}
            parentId={parentSectionIdFromItems(dashboardItems, item.id)}
            key={`factory-${item.id}`}
          />,
        );
        return acc;
      }
      case 'SECTION': {
        acc.push(
          <FactoryDashboardSectionContainer
            id={item.id}
            parentId={parentSectionIdFromItems(dashboardItems, item.id)}
            key={`factory-${item.id}`}
          />,
        );
        return acc;
      }
      case 'GRID_TOOLS': {
        acc.push(
          <DashboardToolButtonsContainer
            id={item.id}
            parentId={parentSectionIdFromItems(dashboardItems, item.id)}
            key={`factory-${item.id}`}
            type={'GRID_TOOLS'}
          />,
        );
        return acc;
      }
      case 'CHART_TOOLS': {
        acc.push(
          <DashboardToolButtonsContainer
            id={item.id}
            parentId={parentSectionIdFromItems(dashboardItems, item.id)}
            key={`factory-${item.id}`}
            type={'CHART_TOOLS'}
          />,
        );
        return acc;
      }
      default:
        return acc;
    }
  }, []);

  result.push(<Spacer key={'bottom-padding'} paddingBottom={86} />);
  return result;
};
