/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import React from 'react';
import { OptionCircle } from './OptionCircle.tsx';

export interface IOptionProps {
  id: string;
  label?: string;
  onLabelChanged?: (to: string) => void;
  onDelete?: () => void;
  error?: string;
}

export const Option: React.FC<IOptionProps> = (props) => (
  <div key={props.id} css={styles.option}>
    <OptionCircle innerIcon={'clear'} onClick={props.onDelete} />
    <div css={styles.optionTextOuter}>
      <TextField
        id={`option-${props.id}`}
        onChange={props.onLabelChanged}
        label={'Question Text'}
        style={styles.fullWidth}
        value={props.label}
        multiline={true}
        error={props.error}
      />
    </div>
  </div>
);

const styles = {
  option: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 10,
  }),
  optionTextOuter: css({
    paddingTop: 10,
    width: '100%',
  }),
  fullWidth: css({
    width: '100%',
  }),
};
