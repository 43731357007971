/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  FormActionAnswerDropdown,
  FormActionTemplateQuestionDropdown,
} from '@se/data/forms/types.ts';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import { useCallback, useMemo } from 'react';
import { formActionSlice } from '../../../../redux/formAction/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../../redux/store.ts';

export interface IActionQuestionDropdownContainerProps {
  questionId: string;
  siblingQuestionId?: string;
  columnIndex: number;
}

export const ActionQuestionDropdownContainer: React.FC<
  IActionQuestionDropdownContainerProps
> = ({ questionId, siblingQuestionId, columnIndex }) => {
  const dispatch = useFormsDispatch();

  const answer = useFormsSelector((state) => {
    const draftAnswer = state.formActionV2.draftChanges.answers.find(
      (a): a is FormActionAnswerDropdown =>
        a.questionId === questionId && a.type === 'Dropdown',
    );

    const existingAnswer = state.formActionV2.existingAction?.answers?.find(
      (a): a is FormActionAnswerDropdown =>
        a.questionId === questionId && a.type === 'Dropdown',
    );

    return draftAnswer ?? existingAnswer;
  });

  const error = useFormsSelector(
    (state) =>
      state.formActionV2.errors.answers.find((a) => a.questionId === questionId)
        ?.error,
  );

  const siblingError = useFormsSelector((state) => {
    if (!siblingQuestionId) return;

    return state.formActionV2.errors.answers.find(
      (a) => a.questionId === siblingQuestionId,
    )?.error;
  });

  const question = useFormsSelector((state) =>
    state.formActionV2.actionTemplate.questions.find(
      (q): q is FormActionTemplateQuestionDropdown =>
        q.id === questionId && q.type === 'Dropdown',
    ),
  );

  const selections = useMemo(() => {
    if (!question) return;
    if (!question?.options?.length) return;

    return question.options.map(({ id, label }) => ({
      id,
      content: { text: label },
    }));
  }, [question]);

  const value = useMemo(() => {
    if (!question) return;
    if (!question?.options?.length) return;
    if (!answer) return;
    if (!answer.selectedOptionIds?.length) return;

    const selectedOption = question.options.find((o) =>
      answer.selectedOptionIds.includes(o.id),
    );
    if (!selectedOption) return;

    return {
      id: selectedOption.id,
      content: {
        text: selectedOption.label,
      },
    };
  }, [answer, question]);

  const canEdit = useFormsSelector((state) =>
    state.formActionV2.existingAction
      ? ['Open', 'Overdue'].includes(state.formActionV2.existingAction.status)
      : true,
  );

  const updateAnswer = useCallback(
    (to: ISelectionListItem) => {
      dispatch(
        formActionSlice.updateDropdownAnswer({
          questionId,
          selectedId: to.id.toString(),
        }),
      );
    },
    [dispatch, questionId],
  );

  return (
    <div css={siblingError && !error ? styles.siblingError : styles.base}>
      {columnIndex > 0 && <div css={styles.space} />}
      <TextFieldDropdown
        id={questionId}
        value={value}
        label={question.label}
        isEnabled={canEdit}
        error={error}
        selections={selections}
        onChange={updateAnswer}
      />
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'row',
  }),
  siblingError: css({
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 23,
  }),
  space: css({
    width: 35,
  }),
};
