/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useShellSelector } from '../../../redux/store.ts';
import { AlertDialogContainer } from '../../AlertDialog/AlertDialogContainer.tsx';
import { SheetContentFactory } from '../../SheetContentFactory/SheetContentFactory.tsx';
import { SheetsPortrait } from '../../SheetsPortrait/SheetsPortrait.tsx';
import { StatusBarContainer } from '../../StatusBar/StatusBarContainer.tsx';
import { DashboardV2TemplatesByModule } from '../../types.ts';

export type ShellSheetsProps = {
  dashboardV2Templates: DashboardV2TemplatesByModule;
  isLoading: boolean;
};

/**
 * The container for <Sheets> within the <Shell>.
 */
export const ShellSheetsContainer: React.FC<ShellSheetsProps> = ({
  dashboardV2Templates,
  isLoading = false,
}) => {
  const module = useShellSelector((state) => state.tenantState.tenant.module);

  const chips = useShellSelector((state) => state.query.query.chips);

  const depth = (chips ?? []).filter((c) => c.value && c.type !== 'bu').length;
  const sheet = useShellSelector((state) => state.sheets.current);

  // Create the content for the last visible sheet.
  const content =
    sheet && depth > 0 ? (
      <SheetContentFactory
        {...sheet.props}
        dashboardV2Templates={dashboardV2Templates}
        isLoading={isLoading}
        module={module}
        type={sheet.type}
      />
    ) : undefined;

  return (
    <div css={styles.base}>
      <SheetsPortrait length={depth} content={content} />
      <StatusBarContainer />
      <AlertDialogContainer />
    </div>
  );
};

const styles = {
  base: css({
    boxSizing: 'border-box',
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    paddingLeft: 4,
    paddingRight: 4,
  }),
};
