/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { InstanceCancelReason } from '@se/data/forms/types.ts';
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/Spinner.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import { useTenantLocale } from '@seeeverything/ui.primitives/src/hooks/useTenantLocale.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { useCallback, useMemo } from 'react';

export type InstanceCancelDialogProps = {
  isReasonRequired: boolean;
  cancelReason?: InstanceCancelReason;
  cancelReasons: InstanceCancelReason[];
  isLoadingCancelReasons: boolean;
  isSaving: boolean;
  notes?: string;
  showCancelReasonValidation: boolean;
  showNotesValidation: boolean;
  onCancelReasonChange: (to: InstanceCancelReason) => void;
  onNotesChange: (to: string) => void;
};

/**
 * The dialog content component for cancelling a form instance.
 */
export const InstanceCancelDialog: React.FC<InstanceCancelDialogProps> = ({
  isReasonRequired,
  cancelReason,
  cancelReasons,
  isLoadingCancelReasons,
  isSaving,
  notes,
  showCancelReasonValidation,
  showNotesValidation,
  onCancelReasonChange,
  onNotesChange,
}) => {
  const { formLabel, confirmationMessage } = useTenantLocale((locale) => ({
    formLabel: locale.label.form,
    confirmationMessage: locale.forms.instance.confirmCancellation,
  }));

  const cancelReasonSelections = useMemo(() => {
    if (!cancelReasons) return [];
    return cancelReasons.map(
      (r): ISelectionListItem<IListItemLabel, InstanceCancelReason> => ({
        id: r.id,
        content: { text: r.reason },
        value: r,
      }),
    );
  }, [cancelReasons]);

  const cancelReasonSelection = cancelReason && {
    id: cancelReason.id,
    content: { text: cancelReason.reason },
    value: cancelReason,
  };

  const handleCancelReasonChange = useCallback(
    (to: ISelectionListItem<IListItemLabel, InstanceCancelReason>) =>
      onCancelReasonChange(to.value),
    [onCancelReasonChange],
  );

  const isSpinning = isLoadingCancelReasons || isSaving;

  const elReason = isReasonRequired ? (
    <>
      <Text>{`Please provide details about why you need to withdraw this ${formLabel.toLowerCase()}. Your feedback will be reviewed to help drive improvements.`}</Text>
      {cancelReasons?.length > 0 && (
        <TextFieldDropdown
          id={`cancelReason`}
          value={cancelReasonSelection}
          selections={cancelReasonSelections}
          isLoading={isLoadingCancelReasons}
          label={'Reason (Required)'}
          onChange={handleCancelReasonChange}
          error={
            showCancelReasonValidation ? 'This field is required.' : undefined
          }
        />
      )}
      <TextField
        id={'notes'}
        label={
          cancelReasons?.length > 0
            ? 'Additional Details (Required)'
            : 'Details (Required)'
        }
        value={notes}
        onChange={onNotesChange}
        error={showNotesValidation ? 'This field is required.' : undefined}
        dataTest={'forms-cancelInstance-notes'}
        multiline={true}
        maxRows={10}
      />
    </>
  ) : (
    <Text>{confirmationMessage}</Text>
  );

  return (
    <div css={styles.base}>
      {isSpinning && (
        <div>
          <Spinner center={true} />
        </div>
      )}
      <div css={[styles.content, isSaving && styles.mask]}>
        <Text size={48} color={color.format(-0.2)} weight={FontWeight.light}>
          {'Withdraw'}
        </Text>
        {!isLoadingCancelReasons && (
          <div css={styles.contentFields}>{elReason}</div>
        )}
      </div>
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    minHeight: 120,
    padding: '25px 35px',
    position: 'relative',
  }),
  content: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  }),
  contentFields: css({
    display: 'flex',
    flexDirection: 'column',
    padding: '0 3px',
    gap: 20,
  }),
  mask: css({
    opacity: 0.2,
  }),
};
