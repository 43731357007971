import { useEffect, useState } from 'react';

/**
 * Hook to execute the supplied function once only.
 */
export const useOnce = (fn: () => void) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (init) return;
    fn();
    setInit(true);
  }, [fn, init]);
};
