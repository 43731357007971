/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ContentPlaceholder } from '@seeeverything/ui.primitives/src/components/ContentPlaceholder/ContentPlaceholder.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { memo, useState } from 'react';
import { XAxisProps, YAxisProps } from 'recharts';
import { CHART_COLORS } from '../../common/constants.ts';
import { ComposedChart } from '../ComposedChart/ComposedChart.tsx';
import { CustomLegend, ICustomLegendProps } from '../CustomLegend/index.ts';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip.tsx';
import {
  ICustomTooltipProps,
  TooltipValueFormatterItem,
} from '../CustomTooltip/types.ts';
import { TextWrapper } from '../TextWrapper/index.ts';
import { IBarProps, ILineProps } from '../types.ts';
import {
  adjustedLineDataForPercentage,
  getMaximumDataValue,
  hasDataForKey,
} from '../util.ts';
import * as types from './types.ts';

export interface IChartProps {
  barCategoryGap?: string | number;
  bars?: IBarProps[];
  data?: types.IChartValue[];
  grid?: boolean;
  height?: number;
  id: string;
  isLoading?: boolean;
  kind?: types.ChartKind;
  layout?: 'horizontal' | 'vertical';
  legend?: boolean | ICustomLegendProps;
  lines?: ILineProps[];
  title?: string;
  tooltip?: boolean | ICustomTooltipProps;
  tooltipDisplayKey?: string;
  valueFormatter?: (item: TooltipValueFormatterItem, value: string) => string;
}

/**
 * An opinionated component that wraps and configures ui.charts for use in the shell.
 */
const View: React.FC<IChartProps> = ({
  barCategoryGap = '10%',
  data = [],
  height = 300,
  isLoading = false,
  kind = 'COMPOSED',
  tooltip = true,
  tooltipDisplayKey = 'displayName',
  bars,
  id,
  legend,
  lines,
  title,
  valueFormatter,
}) => {
  const [highlight, setHighlight] = useState<string>();
  const [inactiveKeys, setInactiveDataKeys] = useState<string[]>([]);

  if (isLoading) {
    return (
      <div css={styles.loadingContainer}>
        <ContentPlaceholder kind={'CHART'} emptyText={''} hintText={''} />
      </div>
    );
  }

  const isVertical = kind === 'VERTICAL';

  const toggleActive = (dataKey: string) => {
    setInactiveDataKeys((currentInactiveKeys: string[]) => {
      if (currentInactiveKeys.includes(dataKey)) {
        // Setting back to active - remove from list.
        return currentInactiveKeys.filter((key) => key !== dataKey);
      } else {
        // Setting to inactive - add to list.
        return [...currentInactiveKeys, dataKey];
      }
    });
  };

  const isHighlighted = (dataKey: string) =>
    Boolean(
      highlight && !inactiveKeys.includes(highlight) && dataKey === highlight,
    );

  const highlightField = (dataKey: string) => {
    setHighlight(dataKey);
  };

  const removeHighlightField = () => {
    setHighlight(undefined);
  };

  const linesProps = !lines
    ? undefined
    : (lines
        .filter((line) => hasDataForKey(line.dataKey as string, data))
        .map((line) => {
          const dataKey = line.dataKey?.toString();
          const isActive = !inactiveKeys.includes(dataKey);
          const isLineHighlighted = isHighlighted(dataKey);

          const stroke = isLineHighlighted
            ? line.stroke
              ? color.create(line.stroke).darken(0.4).hex('rgba')
              : CHART_COLORS.GREY
            : line.stroke;

          return {
            dot: false,
            hide: !isActive,
            activeDot: isActive,
            type: 'monotoneX',
            isAnimationActive: true,
            ...line,
            stroke,
            strokeWidth: isLineHighlighted
              ? (line.strokeWidth ?? 3) + 2
              : (line.strokeWidth ?? 3),
          };
        }) as ILineProps[]);

  const barsProps = !bars
    ? undefined
    : bars
        .filter((bar) => hasDataForKey(bar.dataKey as string, data))
        .map((bar) => {
          const dataKey = bar.dataKey?.toString();
          const isActive = !inactiveKeys.includes(dataKey);
          const isBarHighlighted = isHighlighted(dataKey);

          const stroke = isBarHighlighted
            ? bar.stroke
              ? color.create(bar.stroke).darken(0.4).hex('rgb')
              : CHART_COLORS.GREY
            : bar.stroke;

          return {
            maxBarSize: 35,
            isAnimationActive: true,
            hide: !isActive,
            ...bar,
            strokeWidth: isBarHighlighted
              ? (bar.strokeWidth ?? 0) + 2
              : bar.strokeWidth,
            stroke,
            isHighlighted: isBarHighlighted,
          };
        });

  const shouldReverseItems = isStackedBar();

  const maxDataSize = getMaximumDataValue(data, bars, lines) || 100;

  const labelWidth = longestLabelWidth(data);

  const dataWithScaledPercentages = data.map((singleData) => {
    const linePercentageOverrides = adjustedLineDataForPercentage(
      data,
      singleData,
      maxDataSize,
      lines,
    );

    return {
      ...singleData,
      ...linePercentageOverrides,
    };
  });

  const tooltipProps = tooltip
    ? {
        content: (
          <CustomTooltip
            {...(tooltip !== true ? tooltip : undefined)}
            payloadHeaderKey={tooltipDisplayKey}
            valueFormatter={valueFormatter}
            shouldReverseItems={shouldReverseItems}
            inactiveKeys={inactiveKeys}
          />
        ),
      }
    : undefined;

  const legendProps = legend
    ? {
        content: (
          <CustomLegend
            {...(legend !== true ? legend : undefined)}
            shouldReverseItems={shouldReverseItems}
            onItemMouseEnter={highlightField}
            onItemMouseLeave={removeHighlightField}
            onItemClicked={toggleActive}
            inactiveKeys={inactiveKeys}
          />
        ),
        margin: { top: 5, bottom: 5 },
      }
    : undefined;

  const xAxisProps: XAxisProps = {
    dataKey: 'name',
    opacity: '0.75',
    tickLine: false,
    tickSize: 8,
    minTickGap: 0,
    type: 'category',
    tick: <TextWrapper axis={'xAxis'} />,
  };
  const yAxisProps: YAxisProps = isVertical
    ? {
        domain: [0, maxDataSize === 100 ? 100 : 'auto'],
        axisLine: false,
        tickLine: false,
        fontSize: '16px',
        fontWeight: 'lighter',
        opacity: '0.75',
        type: 'category',
        interval: 0,
        tick: <TextWrapper axis={'xAxis'} />,
        width: labelWidth,
      }
    : {
        domain: [0, maxDataSize === 100 ? 100 : 'auto'],
        axisLine: false,
        tickLine: false,
        fontSize: '16px',
        fontWeight: 'lighter',
        opacity: '0.75',
        tick: <TextWrapper axis={'yAxis'} showTextLabels={false} />,
      };

  const chartProps = {
    // Top margin prevents overflowing max-Y lines from being truncated.
    margin: {
      top: 10,
      right: 0,
      bottom: 0,
      left: 0,
    },
    height,
    barCategoryGap,
    layout: (isVertical ? 'vertical' : 'horizontal') as
      | 'vertical'
      | 'horizontal',
  } as const;

  if (!hasData(kind, linesProps, barsProps)) {
    return (
      <div css={styles.emptyChartContainer}>
        <ContentPlaceholder kind={'CHART'} hintText={true} />
      </div>
    );
  }

  return (
    <div css={styles.titleContainer}>
      {title && (
        <Text
          align={'center'}
          weight={400}
          size={16}
          color={CHART_COLORS.MID_GREY}
          style={styles.chartTitle}
        >
          {title}
        </Text>
      )}
      <div css={styles.outer}>
        <ComposedChart
          id={id}
          data={dataWithScaledPercentages}
          chartProps={chartProps}
          barsProps={barsProps}
          linesProps={linesProps}
          tooltipProps={tooltipProps}
          legendProps={legendProps}
          xAxisProps={xAxisProps}
          yAxisProps={yAxisProps}
        />
      </div>
    </div>
  );
};

const styles = {
  emptyChartContainer: css({
    position: 'relative',
    height: 300,
    width: '100%',
  }),
  loadingContainer: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 300,
    width: '100%',
    textAlign: 'center',
    paddingTop: 50,
  }),
  outer: css({
    position: 'absolute',
    top: 20,
    right: 0,
    bottom: 0,
    left: -60,
  }),
  titleContainer: css({
    position: 'relative',
    minHeight: 300,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  }),
  chartTitle: css({ textAlign: 'center' }),
};

const longestLabelWidth = (data: types.IChartValue[]): number => {
  const charCount =
    data.reduce(
      (acc, curr) => {
        const accLen = acc.name?.length || 0;
        const currLen = curr.name?.length || 0;

        return accLen > currLen ? acc : curr;
      },
      { name: '' },
    ).name?.length || 10;

  const labelWidth = charCount * 10;

  return labelWidth > 100 ? labelWidth : 100;
};

const isStackedBar = (bars?: IBarProps[]) => {
  if (!bars) {
    return false;
  }

  return bars.some((bar) => Boolean(bar.stackId));
};

function hasData(
  kind: types.ChartKind,
  linesProps: ILineProps[] = [],
  barsProps: IBarProps[] = [],
) {
  switch (kind) {
    case 'COMPOSED':
    case 'VERTICAL':
      return linesProps.length > 0 || barsProps.length > 0;

    default:
      return false;
  }
}

export const Chart = memo(View);
