/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ActionBar } from '@seeeverything/ui.primitives/src/components/ModalDialog/components/ActionBar/ActionBar.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { useCallback, useMemo, useState } from 'react';

export type TextInputConfirmationDialogProps = {
  title: string;
  inputLabel: string;
  inputError?: string;
  description?: string;
  onActionOK?: (userReason: string) => void;
  onActionCancel?: () => void;
};

export const TextInputConfirmationDialog: React.FC<
  TextInputConfirmationDialogProps
> = ({
  title,
  inputLabel,
  inputError,
  description,
  onActionCancel,
  onActionOK,
}) => {
  const [input, setInput] = useState('');
  const [showError, setShowError] = useState(false);

  const handleTextInputChanged = (to: string) => {
    setInput(to);
    setShowError(false);
  };

  const handleModalOk = useCallback(() => {
    if (!input || input.trim() === '') {
      setShowError(true);
      return;
    }
    onActionOK?.(input);
  }, [input, onActionOK]);

  const actionButtons = useMemo(
    () => [
      {
        id: 'Cancel',
        label: 'Cancel',
        isEnabled: true,
        onAction: onActionCancel,
      },
      { id: 'OK', label: 'OK', isEnabled: true, onAction: handleModalOk },
    ],
    [onActionCancel, handleModalOk],
  );

  return (
    <div css={styles.base}>
      <div css={styles.title}>
        <Text size={48} color={color.format(-0.2)} weight={300}>
          {title}
        </Text>
      </div>
      <div css={styles.inputOuter}>
        <TextField
          id={'confirm'}
          multiline={true}
          error={showError ? inputError : undefined}
          minRows={4}
          maxRows={4}
          label={inputLabel}
          onChange={handleTextInputChanged}
          value={input}
          style={styles.textField}
          variant={'outlined'}
          outlinedInputStyle={styles.outlinedInput}
        />
      </div>
      {description && (
        <div css={styles.description}>
          <Text
            size={15}
            block={true}
            color={color.format(-0.4)}
            weight={FontWeight.bold}
          >
            {description}
          </Text>
        </div>
      )}
      <ActionBar actions={actionButtons} />
    </div>
  );
};

const styles = {
  base: css({
    backgroundColor: 'white',
    width: '100%',
  }),
  title: css({
    margin: '20px 0 0 34px',
    marginBottom: 'auto',
  }),
  description: css({
    margin: '40px 34px',
    display: 'flex',
  }),
  inputOuter: css({
    height: 144,
  }),
  textField: css({
    padding: '5px 30px',
    width: '90%',
  }),
  outlinedInput: css({
    padding: 15,
  }),
};
