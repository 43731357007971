import * as jsYaml from 'js-yaml';
import * as R from 'ramda';
import { parser as chartParser } from './parser.chart.ts';
import { parser as chartToolsParser } from './parser.chartTools.ts';
import { parser as commentsListParser } from './parser.commentsList.ts';
import { parser as digitalContentSectionParser } from './parser.digitalContentSection.ts';
import { parser as goalsActionsParser } from './parser.goalsActions.ts';
import { parser as gridParser } from './parser.grid.ts';
import { parser as gridToolsParser } from './parser.gridTools.ts';
import { parser as headerParser } from './parser.header.ts';
import { parser as issuesInsightsParser } from './parser.issuesInsights.ts';
import { parser as sectionParser } from './parser.section.ts';

import { DashboardComponent, ParserOptions } from '../types.ts';

const parsers = [
  chartParser,
  chartToolsParser,
  commentsListParser,
  digitalContentSectionParser,
  gridParser,
  gridToolsParser,
  goalsActionsParser,
  sectionParser,
  issuesInsightsParser,
];

/**
 * This converts a template in YAML or JSON to DashboardComponents array.
 */
export function componentParsers(
  inputString: string,
  componentId?: string,
  options?: ParserOptions,
): DashboardComponent[] {
  if (!inputString) {
    return [];
  }

  const json = jsYaml.load(inputString) as unknown[];
  const components: DashboardComponent[] = [];

  const headerJson = json.filter((item: any) => item.header);
  if (headerJson.length) {
    const header = headerParser(headerJson[0], -1, options);
    if (header) {
      components.push(header);
    }
  }

  const dashboardComponents = json
    .filter((item: any) => !item.header)
    .filter(componentIdEquals(componentId))
    .map((item: any, index: number) =>
      parsers
        .map((parser) => parser(item, index))
        .reduce((a, v) => ({ ...a, ...v }), {}),
    ) as DashboardComponent[];

  components.push(...R.reject(R.isEmpty, dashboardComponents));
  return components;
}

/**
 * Inspects a JSON nested component value and compares its id property, if any,
 * with the supplied id.
 * Returns true if no id is provided & false if none is found.
 */
const componentIdEquals = (id?: string) =>
  id === undefined
    ? R.always(true)
    : (item: any) => {
        const componentKeys = (item && Object.keys(item)) || [];
        const component = componentKeys.length === 1 && item[componentKeys[0]];
        return component && component.id === id;
      };
