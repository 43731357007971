/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ContentPlaceholder } from '@seeeverything/ui.primitives/src/components/ContentPlaceholder/ContentPlaceholder.tsx';
import { useTrackInViewport } from '@seeeverything/ui.primitives/src/hooks/useTrackInViewport.ts';
import { useSegmentAnalytics } from '@seeeverything/ui.util/src/analytics/SegmentProvider.tsx';
import { propsAreEqualFastCompare } from '@seeeverything/ui.util/src/react/memoFastCompare.ts';
import { memo, useEffect, useMemo, useState } from 'react';
import { DASHBOARD_INTERSECTION_OPTIONS } from '../common/constants.ts';
import { HighlightsContainer } from '../components/IssuesAndInsights/HighlightsContainer.tsx';
import { IssuesContainer } from '../components/IssuesAndInsights/IssuesContainer.tsx';
import { LearningAreasContainer } from '../components/IssuesAndInsights/LearningAreasContainer.tsx';
import { toDateFilter, toTimespanVariable } from '../data/util.ts';
import { useDashboardsSelector } from '../redux/store.ts';

type FactoryIssuesInsightsContainerProps = {
  id: string;
  parentId?: string;
};

const View: React.FC<FactoryIssuesInsightsContainerProps> = ({
  id,
  parentId,
}) => {
  const { track } = useSegmentAnalytics();

  const [forwardedRef, inViewport] = useTrackInViewport({
    dataId: id,
    parentDataId: parentId,
    observerOptions: DASHBOARD_INTERSECTION_OPTIONS,
  });

  const [isInit, setIsInit] = useState(inViewport);

  useEffect(() => {
    if (isInit) return;
    if (!inViewport) return;
    setIsInit(true);
  }, [inViewport, isInit]);

  const template = useDashboardsSelector(
    (state) => state.dashboardsV2.template,
  );

  const issuesInsights = useDashboardsSelector(
    (state) => state.dashboardsV2.ISSUES_AND_INSIGHTS[id],
  );

  const [eventTracked, setEventTracked] = useState(false);

  useEffect(() => {
    if (issuesInsights && template && inViewport && !eventTracked) {
      track('dashboard_section_load', { sectionLabel: id });
      setEventTracked(true);
    }
  }, [id, inViewport, eventTracked, issuesInsights, template, track]);

  const dateFilter = useDashboardsSelector((state) => toDateFilter(state));
  const timespan = useMemo(() => toTimespanVariable(dateFilter), [dateFilter]);

  return (
    <div
      data-id={id}
      parent-data-id={parentId}
      ref={forwardedRef}
      css={styles.base}
    >
      {!isInit && (
        <div css={styles.loadingPlaceholder}>
          <ContentPlaceholder kind={'LIST'} emptyText={''} hintText={''} />
        </div>
      )}
      {isInit && issuesInsights?.type === 'issues' && (
        <IssuesContainer
          issueListId={id}
          entityId={template?.entityId}
          entityType={template?.kind}
          timespan={timespan}
          inViewport={inViewport}
        />
      )}
      {isInit && issuesInsights?.type === 'learning-areas' && (
        <LearningAreasContainer
          learningAreaListId={id}
          entityId={template?.entityId}
          entityType={template?.kind}
          timespan={timespan}
          inViewport={inViewport}
        />
      )}
      {isInit && issuesInsights?.type === 'highlights' && (
        <HighlightsContainer
          highlightListId={id}
          entityId={template?.entityId}
          entityType={template?.kind}
          timespan={timespan}
          inViewport={inViewport}
        />
      )}
    </div>
  );
};

const styles = {
  base: css({
    margin: '30px 30px 30px 35px',
  }),
  loadingPlaceholder: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 300,
    width: '100%',
    textAlign: 'center',
    paddingTop: 50,
  }),
};

export const FactoryIssuesInsightsContainer = memo(
  View,
  propsAreEqualFastCompare,
);
