/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { ToolButtonSet } from '@seeeverything/ui.primitives/src/components/ToolButtonSet/ToolButtonSet.tsx';
import {
  IToolButton,
  ToolButtonClickEventHandler,
  ToolButtonDropdownClickEventHandler,
} from '@seeeverything/ui.primitives/src/components/ToolButtonSet/types.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import * as R from 'ramda';

export interface ISheetToolbarProps {
  selectedToolIds?: string[];
  onClick?: ToolButtonClickEventHandler;
  onDropdownClick?: ToolButtonDropdownClickEventHandler;
  title?: string;
  leftTools?: IToolButton[];
  leftContent?: React.ReactNode;
  rightTools?: IToolButton[];
  rightContent?: React.ReactNode;
  moreItems?: ISelectionListItem[];
  dateFilters?: ISelectionListItem[];
}

/**
 * The visual display of the toolbar at the top of a sheet.
 */
export const SheetToolbar: React.FC<ISheetToolbarProps> = ({
  dateFilters,
  leftContent,
  leftTools = [],
  moreItems = [],
  onClick,
  onDropdownClick,
  rightContent,
  rightTools = [],
  selectedToolIds,
  title,
}) => {
  const leftToolsWithSelections = selectedToolIds
    ? toolsWithSelections(leftTools, selectedToolIds)
    : leftTools;
  const rightToolsWithSelections = selectedToolIds
    ? toolsWithSelections(rightTools, selectedToolIds)
    : rightTools;
  return (
    <div css={styles.base}>
      <div css={[styles.toolbar, styles.toolbarLeft]}>
        <ToolButtonSet
          border={false}
          toolButtons={leftToolsWithSelections}
          onClick={onClick}
          onDropdownClick={onDropdownClick}
        />
        {leftContent}
      </div>
      {title && (
        <Text
          weight={900}
          color={color.format(-0.4)}
          size={16}
          cursor={'default'}
          align={'center'}
        >
          {title}
        </Text>
      )}
      <div css={[styles.toolbar, styles.toolbarRight]}>
        {rightContent}
        <ToolButtonSet
          border={false}
          toolButtons={toolsWithMenu(
            rightToolsWithSelections,
            moreItems,
            dateFilters,
          )}
          onClick={onClick}
          onDropdownClick={onDropdownClick}
        />
      </div>
    </div>
  );
};

/**
 * Maps any tool buttons to be selected based on an array of selected tool IDs.
 */
const toolsWithSelections = (tools: IToolButton[], selectedIds: string[]) =>
  tools.map((tool) =>
    selectedIds.includes(tool.id) ? { ...tool, isSelected: true } : tool,
  );

const styles = {
  base: css({
    height: 50,
    minHeight: 50,
    boxSizing: 'border-box',
    borderBottom: `solid 1px ${color.format(-0.15)}`,
    backgroundColor: color.format(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 8px',
  }),
  toolbar: css({
    flex: '1 1 0',
  }),
  toolbarRight: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',
  }),
  toolbarLeft: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
  }),
};

const toolsWithMenu = (
  tools: IToolButton[],
  menuItems: ISelectionListItem[],
  dateFilterOptions?: ISelectionListItem[],
) => {
  const dateFilterMenuItems =
    dateFilterOptions && dateFilterMenu(dateFilterOptions);

  const result = R.reject(R.isNil, [
    ...tools,
    dateFilterMenuItems,
    menuItems.length ? moreMenu(menuItems) : undefined,
  ]);

  return result;
};

const ITEM_HEIGHT = 42;

const dateFilterMenu = (items: ISelectionListItem[] = []): IToolButton => ({
  id: 'DATE_FILTER_OPTIONS',
  dataTest: 'shell-sheetToolbar-dateFilterMenu',
  icon: Icons.dateRange,
  dropdown: {
    items,
    width: 450,
    height: Math.min(items.length * ITEM_HEIGHT, 294),
    isScrollable: true,
  },
});

const moreMenu = (items: ISelectionListItem[]): IToolButton => ({
  id: 'SHEET_OPTIONS',
  icon: Icons.moreVert,
  dataTest: 'shell-sheetToolbar-moreMenu',
  dropdown: {
    items,
    minWidth: 250,
  },
});
