import { clickThroughGrids } from '@seeeverything/ui.dashboards/src/data/index.ts';
import { ClickThroughGridValuesType } from '@seeeverything/ui.dashboards/src/data/types.ts';
import { parser } from '@seeeverything/ui.dashboards/src/parse/parser.grid.ts';
import {
  clearScrollToPosition,
  init,
} from '@seeeverything/ui.dashboards/src/redux/actions.ts';
import {
  designer,
  formsDesignerViewTemplatesSlice,
} from '@seeeverything/ui.forms.designer/src/redux/index.ts';
import { automatedActionConfigurationSlice } from '@seeeverything/ui.forms/src/redux/automatedActionConfiguration/index.ts';
import { serverLoad } from '@seeeverything/ui.forms/src/redux/form-instance/instance/actions.ts';
import { ReduxFormInstanceServerCreate } from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import { formBulkUploadClickThroughSlice } from '@seeeverything/ui.forms/src/redux/formBulkUploadClickThrough/index.ts';
import { formBulkUploadJobHistorySlice } from '@seeeverything/ui.forms/src/redux/formBulkUploadJobHistory/index.ts';
import { queryBuilder } from '@seeeverything/ui.shell/src/api/index.ts';
import { AppReturnHomeAction } from '@seeeverything/ui.shell/src/redux/app/types.ts';
import { auditSlice } from '@seeeverything/ui.shell/src/redux/audit/index.ts';
import {
  QueryBuilderDropdownLoadedAction,
  QueryBuilderNextAction,
} from '@seeeverything/ui.shell/src/redux/query/types.ts';
import {
  addSheet,
  clear,
} from '@seeeverything/ui.shell/src/redux/sheets/actions.ts';
import {
  SheetChangeModuleAction,
  SheetType,
  SheetsClearAction,
} from '@seeeverything/ui.shell/src/redux/sheets/types.ts';
import { sheetSchedulesSlice } from '@seeeverything/ui.shell/src/redux/sheetSchedules/index.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { decodeBase64 } from '@seeeverything/ui.util/src/str/str.base64.ts';
import { ChipType } from '@seeeverything/ui.util/src/urlDeepLink/urlDeepLink.ts';
import { asArray } from '@seeeverything/ui.util/src/value/value.ts';
import { last } from 'ramda';
import isEqual from 'react-fast-compare';
import { StateObservable, combineEpics, ofType } from 'redux-observable';
import {
  Observable,
  concatAll,
  distinctUntilChanged,
  filter,
  from,
  map,
  mergeMap,
} from 'rxjs';
import { getInitializedApp } from '../../../app.ts';
import { GlobalAppEpicDependencies, GlobalAppState } from '../../../types.ts';
import { queryPathMap } from '../../config.sheets/index.ts';
import { SheetPathValue } from './types.ts';

export const epics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalAppState,
  GlobalAppEpicDependencies
>(
  clearRememberedScrollPosition,
  clearSheetsEpic,
  showAuditSheetOnQueryChangeEpic,
  showClickThroughGridSheet,
  showDashboardSheetOnQueryChangeEpic,
  showDashboardV2ComponentSheet,
  showFormDesignerDraftsSheetEpic,
  showFormDesignerSheetEpic,
  showFormsBulkUploadClickThroughGridEpic,
  showFormsBulkUploadHistorySheetEpic,
  showFormScheduleSheetEpic,
  showFormSheetOnQueryChangeEpic,
  showGoalsActionsGridSheet,
  showSettingsAutomatedActionsEpic,
);

function showDashboardSheetOnQueryChangeEpic(
  action$: Observable<QueryBuilderNextAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.shell/query/NEXT'),
    distinctUntilChanged(areQueryChipsUnchanged),
    map(toSheetType),
    filter((sheetTypeResult) =>
      Boolean(sheetTypeResult?.type && sheetTypeResult?.hasDashboard),
    ),
    map(({ type, value }) => {
      const app = getInitializedApp();
      const templates = app.dashboardV2Templates;
      const module = state$.value.tenantState.tenant?.module;

      const entity = type === 'COACHING_DASHBOARD_PERSON' ? 'person' : 'team';
      const template = templates[module][entity];

      return [
        init(module, template.id, entity, value.toString()),
        addSheet({
          type,
          props: {
            dashboardKey: `${type.toString()}:${value}`,
            dashboardType: type,
            id: value,
          },
        }),
      ];
    }),
    concatAll(),
  );
}

function showFormSheetOnQueryChangeEpic(
  action$: Observable<QueryBuilderNextAction>,
) {
  return action$.pipe(
    ofType('ui.shell/query/NEXT'),
    distinctUntilChanged(areQueryChipsUnchanged),
    map(toSheetType),
    filter(isSheetType('FORM')),
    mergeMap(async ({ value, eventName }) => {
      // New forms are loaded by the create instance epic, this is needed for existing forms.
      const loadInstance =
        eventName !== 'NEW_FORM' ? serverLoad(value) : undefined;

      return from(
        [
          loadInstance,
          addSheet({ type: 'FORM', props: { instanceId: value } }),
        ].filter(Boolean),
      );
    }),
    concatAll(),
  );
}

function showGoalsActionsGridSheet(
  action$: Observable<QueryBuilderNextAction>,
) {
  return action$.pipe(
    ofType('ui.shell/query/NEXT'),
    distinctUntilChanged(areQueryChipsUnchanged),
    map(toSheetType),
    filter(isSheetType(['GOALS_ACTIONS_GRID'])),
    map(({ value, values }) => {
      const [
        entityType,
        entityId,
        startDate,
        endDate,
        dateRangeLabel,
        goalsActionsFilter,
      ] = value.split('|');

      const title = `${last(values)?.label}`;

      return from([
        addSheet({
          type: 'GOALS_ACTIONS_GRID',
          props: {
            entityType,
            entityId,
            startDate,
            endDate,
            dateRangeLabel,
            title,
            goalsActionsFilter,
          },
        }),
      ]);
    }),
    concatAll(),
  );
}

function showDashboardV2ComponentSheet(
  action$: Observable<QueryBuilderNextAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.shell/query/NEXT'),
    distinctUntilChanged(areQueryChipsUnchanged),
    map(toSheetType),
    filter(isSheetType(['V2_GRID', 'COMMENTS_LIST'])),
    mergeMap(async ({ value, values, type }) => {
      const app = getInitializedApp();
      const templates = app.dashboardV2Templates;

      const module = state$.value.tenantState.tenant?.module;

      const entityChip = values[values.length - 2];
      const dashboardType =
        entityChip && entityChip.type === 'people'
          ? 'COACHING_DASHBOARD_PERSON'
          : 'COACHING_DASHBOARD_TEAM';

      const id = entityChip.value;

      const componentFilter =
        value === undefined ? undefined : value.toString();
      const entity =
        dashboardType === 'COACHING_DASHBOARD_PERSON' ? 'person' : 'team';
      const template = templates?.[module]?.[entity];
      const entityPath =
        state$.value.dashboardsV2.HEADER?.dataState.data?.data.path;

      return from([
        init(module, template.id, entity, id.toString(), componentFilter),
        addSheet({
          type,
          props: {
            value,
            values,
            type,
            entity,
            id,
            dashboardKey: value,
            dashboardComponentId: value,
            dashboardType,
            entityPath,
          },
        }),
      ]);
    }),
    concatAll(),
  );
}

function showClickThroughGridSheet(
  action$: Observable<QueryBuilderNextAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.shell/query/NEXT'),
    distinctUntilChanged(areQueryChipsUnchanged),
    map(toSheetType),
    filter(isSheetType('DASHBOARD_CLICK_THROUGH_GRID')),
    mergeMap(async ({ value, values }) => {
      if (!value) {
        throw new Error('Cannot show click through grid without any values.');
      }

      const app = getInitializedApp();
      const clickThroughValues: ClickThroughGridValuesType = JSON.parse(
        decodeBase64(value),
      );

      const templates = app.dashboardV2Templates;
      const gridName = last(values).label;

      const {
        clickThroughId,
        sourceDataSetId,
        sourceGridColId,
        sourceGridId,
        sourceGridRowId,
        sourceGridType,
        clickThroughDefinitionId,
        templateEntity,
        templateEntityId,
        entityPath,
      } = clickThroughValues;

      const module = state$.value.tenantState.tenant?.module;

      const template = templates[module][templateEntity];

      const createDefaultClickThroughGrid = () =>
        sourceGridType === 'GOALS_AND_ACTIONS'
          ? clickThroughGrids.goalsAndActionsClickThroughGrid({
              sourceGridId,
              sourceGridDataSetId: sourceDataSetId,
              clickThroughDataSetId: clickThroughId,
              clickThroughColumnId: sourceGridColId,
              clickThroughRowId: sourceGridRowId,
              gridName,
              module,
              entityPath,
            })
          : clickThroughGrids.sessionsClickThroughGrid({
              sourceGridId,
              sourceGridDataSetId: sourceDataSetId,
              clickThroughDataSetId: clickThroughId,
              clickThroughColumnId: sourceGridColId,
              clickThroughRowId: sourceGridRowId,
              gridName,
              module,
              entityPath,
            });

      const createClickThroughGrid = () => {
        const clickThroughGridDefinition = JSON.parse(template.definition).find(
          (item: any) =>
            item['click-through-grid']?.id === clickThroughDefinitionId,
        );

        const result = {
          ...parser(clickThroughGridDefinition),
          id: sourceGridId,
          clickThrough: {
            columnId: sourceGridColId,
            rowId: sourceGridRowId,
            dataSetId: sourceDataSetId,
          },
          entityPath,
          name: gridName,
          title: gridName,
        };

        return result;
      };

      const clickThroughGrid = clickThroughDefinitionId
        ? createClickThroughGrid()
        : createDefaultClickThroughGrid();

      return from([
        init(
          module,
          template.id,
          templateEntity,
          templateEntityId.toString(),
          sourceGridId,
          clickThroughGrid,
        ),
        addSheet({
          type: 'DASHBOARD_CLICK_THROUGH_GRID',
          props: {
            id: sourceGridId,
            dashboardKey: `CLICK_THROUGH_GRID:${sourceGridId}`,
            dashboardType: 'CLICK_THROUGH_GRID',
            gridName,
            entityPath,
          },
        }),
      ]);
    }),
    concatAll(),
  );
}

function showAuditSheetOnQueryChangeEpic(
  action$: Observable<QueryBuilderNextAction>,
) {
  return action$.pipe(
    ofType('ui.shell/query/NEXT'),
    distinctUntilChanged(areQueryChipsUnchanged),
    map(toSheetType),
    filter(isSheetType('FORM_AUDIT_LOG')),
    mergeMap(async ({ values }) => {
      const instanceId = lastPathValue('forms', values).toString();

      return from([
        auditSlice.load({ instanceId }),
        addSheet({ type: 'FORM_AUDIT_LOG', props: {} }),
      ]);
    }),
    concatAll(),
  );
}

function showFormScheduleSheetEpic(
  action$: Observable<QueryBuilderNextAction>,
) {
  return action$.pipe(
    ofType('ui.shell/query/NEXT'),
    distinctUntilChanged(areQueryChipsUnchanged),
    map(toSheetType),
    filter(isSheetType('SETTINGS_SCHEDULES')),
    map(() =>
      from([
        sheetSchedulesSlice.selectScheduleType({
          type: 'INSTANCE_TIME_SCHEDULES',
        }),
        addSheet({ type: 'SETTINGS_SCHEDULES', props: {} }),
      ]),
    ),
    concatAll(),
  );
}

function showSettingsAutomatedActionsEpic(
  action$: Observable<QueryBuilderNextAction>,
) {
  return action$.pipe(
    ofType('ui.shell/query/NEXT'),
    distinctUntilChanged(areQueryChipsUnchanged),
    map(toSheetType),
    filter(isSheetType('SETTINGS_AUTOMATED_ACTIONS')),
    map(() =>
      from([
        automatedActionConfigurationSlice.reset(),
        addSheet({ type: 'SETTINGS_AUTOMATED_ACTIONS', props: {} }),
      ]),
    ),
    concatAll(),
  );
}

function showFormsBulkUploadHistorySheetEpic(
  action$: Observable<QueryBuilderNextAction>,
) {
  return action$.pipe(
    ofType('ui.shell/query/NEXT'),
    distinctUntilChanged(areQueryChipsUnchanged),
    map(toSheetType),
    filter(isSheetType('FORMS_BULK_UPLOAD_HISTORY')),
    map(() =>
      from([
        formBulkUploadJobHistorySlice.loadJobs({ loadNextPage: false }),
        addSheet({ type: 'FORMS_BULK_UPLOAD_HISTORY', props: {} }),
      ]),
    ),
    concatAll(),
  );
}

function showFormsBulkUploadClickThroughGridEpic(
  action$: Observable<QueryBuilderNextAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.shell/query/NEXT'),
    distinctUntilChanged(areQueryChipsUnchanged),
    map(toSheetType),
    filter(isSheetType('BULK_UPLOAD_CLICK_THROUGH_GRID')),
    map(() => {
      const jobChip = last(state$.value.query.query.chips);

      const jobId = jobChip.value;
      const jobLabel = jobChip.label;
      const clickThroughType =
        jobChip.type === 'bulkUploadSucceeded' ? 'Succeeded' : 'Failed';
      return from([
        formBulkUploadClickThroughSlice.loadClickThrough({
          jobId,
          type: clickThroughType,
          loadNextPage: false,
        }),
        addSheet({
          type: 'BULK_UPLOAD_CLICK_THROUGH_GRID',
          props: { jobLabel, clickThroughType },
        }),
      ]);
    }),
    concatAll(),
  );
}

function showFormDesignerDraftsSheetEpic(
  action$: Observable<QueryBuilderNextAction>,
) {
  return action$.pipe(
    ofType('ui.shell/query/NEXT'),
    distinctUntilChanged(areQueryChipsUnchanged),
    map(toSheetType),
    filter(isSheetType('FORMS_DESIGNER_LIST')),
    map(() =>
      from([
        formsDesignerViewTemplatesSlice.load(),
        addSheet({ type: 'FORMS_DESIGNER_LIST', props: {} }),
      ]),
    ),
    concatAll(),
  );
}

function showFormDesignerSheetEpic(
  action$: Observable<QueryBuilderNextAction>,
) {
  return action$.pipe(
    ofType('ui.shell/query/NEXT'),
    distinctUntilChanged(areQueryChipsUnchanged),
    map(toSheetType),
    filter(isSheetType('FORMS_DESIGNER')),
    mergeMap(async ({ values }) => {
      const templateId = lastPathValue('formsDesignerTemplate', values);

      return from([
        designer.load(templateId.toString()),
        addSheet({ type: 'FORMS_DESIGNER', props: {} }),
      ]);
    }),
    concatAll(),
  );
}

function clearSheetsEpic(action$: Observable<QueryBuilderNextAction>) {
  return action$.pipe(
    ofType('ui.shell/query/NEXT'),
    distinctUntilChanged(areQueryChipsUnchanged),
    filter(
      ({ payload }) =>
        payload.query.chips.length === 0 ||
        (payload.query.chips.length < 2 &&
          payload.query.chips[0].type === 'bu'),
    ),
    map(clear),
  );
}

function clearRememberedScrollPosition(
  action$: Observable<
    | AppReturnHomeAction
    | QueryBuilderDropdownLoadedAction
    | SheetChangeModuleAction
    | SheetsClearAction
    | ReduxFormInstanceServerCreate
  >,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType(
      'ui.forms/instance/SERVER_CREATE',
      'ui.shell/app/RETURN_HOME',
      'ui.shell/query/DROPDOWN_LOADED',
      'ui.shell/sheets/CHANGE_MODULE',
      'ui.shell/sheets/CLEAR',
    ),
    filter((action) =>
      action.type === 'ui.shell/query/DROPDOWN_LOADED'
        ? state$.value.query.focus !== 'DROPDOWN'
        : true,
    ),
    map(() => clearScrollToPosition()),
  );
}

/**
 * HELPERS
 */
const areQueryChipsUnchanged = (
  prev: QueryBuilderNextAction,
  next: QueryBuilderNextAction,
) => {
  /**
   * Bypass query checking if the payload specifies a force refresh.
   */
  if (next.payload.forceRefresh) {
    return false;
  }

  const lastChip = last(prev.payload.query.chips);
  const prevLength = prev.payload.query.chips.length;
  const newLength = next.payload.query.chips.length;

  /**
   * Works around the following issue:
   * - Open a dashboard sheet
   * - Go to the query builder, select a new (and additional) chip, and click to get the input value
   * - Dismiss the newly added chip, resulting in a dashboard reloading (for no reason).
   *
   * This will inspect the value on the chip - and unless a value was selected - this will always be undefined,
   * thus the query does not need to be re-run.
   */
  if (
    lastChip &&
    lastChip.value === undefined &&
    prevLength - 1 === newLength
  ) {
    return true;
  }

  // Sheets are reset by updatedModuleEpic.
  const toChips = (action: QueryBuilderNextAction) =>
    action.payload.query.chips.map(({ type, value }) => ({ type, value }));

  return isEqual(toChips(prev), toChips(next));
};

type SheetTypeResult = {
  value?: string;
  values: SheetPathValue[];
  type: SheetType;
  hasDashboard: boolean;
  eventName?: string;
};
function toSheetType(
  action: QueryBuilderNextAction,
): SheetTypeResult | undefined {
  const { query, eventName } = action.payload;
  const lastChip = last(query.chips);
  const lastChipValue = lastChip?.value;

  const values = query.chips.map(({ type, value, label }) => ({
    type,
    value: value?.toString(),
    label,
  }));

  const isMatch = (pattern: RegExp) => queryBuilder.isPathMatch(pattern, query);
  for (const key in queryPathMap) {
    if (isMatch(queryPathMap[key].matchPattern)) {
      const queryPath = queryPathMap[key];
      return {
        type: queryPath.path,
        value: lastChipValue,
        values,
        hasDashboard: queryPath.hasDashboard || false,
        eventName,
      };
    }
  }

  // No match.
  return undefined;
}

const isSheetType =
  (sheetTypes: SheetType | SheetType[]) => (e?: SheetTypeResult) => {
    const sheetType = e?.type;
    if (!sheetType) return false;

    const sheetTypesAsArray = asArray(sheetTypes);
    return sheetTypesAsArray.includes(sheetType);
  };

// Finds the last path element of specified type with a defined value, and returns that value.
function lastPathValue(type: ChipType, values: SheetPathValue[]) {
  const match = values
    .reverse()
    .find((x) => x.type === type && x.value !== undefined);
  return match ? match.value : undefined;
}
