/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { Transition } from '@seeeverything/ui.primitives/src/components/Transition/index.ts';
import React from 'react';
import { DesignerSection } from '../DesignerSection/DesignerSection.tsx';
import { Switch } from '../Switch/Switch.tsx';
import {
  DesignerChangeEventHandler,
  DesignerEventOccurredHandler,
  IDesignerCommonProps,
  IFormDesignerValidationError,
} from '../types.ts';
import { getValidationError } from '../util.ts';

export interface IDesignerAttendanceProps extends IDesignerCommonProps {
  isEnabled?: boolean;
  mandatoryFollowUp?: boolean;
  mandatoryFollowUpHintText?: string;
  onChange?: DesignerChangeEventHandler;
  onEventOccurred?: DesignerEventOccurredHandler;
  showBoundaryLines?: boolean;
  showBullet?: boolean;
  style?: SerializedStyles;
  title?: string;
  trackAttendanceHintText?: string;
  validationError?: IFormDesignerValidationError;
}

export const DesignerAttendance: React.FC<IDesignerAttendanceProps> = ({
  isEnabled = true,
  mandatoryFollowUp = false,
  mandatoryFollowUpHintText = 'Mandatory follow-up if absent or away?',
  onChange,
  onEventOccurred,
  showBoundaryLines,
  showBullet = true,
  style,
  title = 'Attendance',
  trackAttendanceHintText = 'Marks attendance as enabled.',
  validationError,
}) => (
  <div css={(styles.outer, style)}>
    <DesignerSection
      id={'attendance-title'}
      canDelete={false}
      showBoundaryLines={showBoundaryLines}
      title={title}
      showBullet={showBullet}
      onChange={onChange}
      onEventOccurred={onEventOccurred}
      error={getValidationError(validationError?.fields, 'title')}
    />
    <div css={styles.base}>
      <div css={styles.trackAttendanceCheckBox}>
        <Switch
          id={'attendance-enabled'}
          isEnabled={isEnabled}
          showBoundaryLines={showBoundaryLines}
          descriptionToEnable={trackAttendanceHintText}
          descriptionToDisable={trackAttendanceHintText}
          editingLabel={'Track Attendance'}
          onChange={onChange}
        />
      </div>
      <Transition.Slide
        in={isEnabled}
        direction={'left'}
        unmountOnExit={true}
        mountOnEnter={true}
      >
        <div css={styles.trackAttendanceCheckBox}>
          <Switch
            id={'attendance-mandatoryFollowUp'}
            isEnabled={isEnabled && mandatoryFollowUp}
            showBullet={false}
            showBoundaryLines={showBoundaryLines}
            descriptionToEnable={mandatoryFollowUpHintText}
            descriptionToDisable={mandatoryFollowUpHintText}
            editingLabel={'Mandatory Follow-Up'}
            onChange={onChange}
          />
        </div>
      </Transition.Slide>
    </div>
  </div>
);

const styles = {
  outer: css({
    position: 'relative',
  }),
  base: css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
  }),
  trackAttendanceCheckBox: css({
    flex: '1 1 auto',
    minWidth: 286,
  }),
};
