/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { DesignerSection } from '../DesignerSection/DesignerSection.tsx';
import { Switch } from '../Switch/Switch.tsx';
import {
  DesignerChangeEventHandler,
  DesignerEventOccurredHandler,
  IDesignerCommonProps,
  IFormDesignerValidationError,
} from '../types.ts';
import { getValidationError } from '../util.ts';

export interface DesignerActionPlanProps extends IDesignerCommonProps {
  designerHintText?: string;
  isEnabled?: boolean;
  onChange?: DesignerChangeEventHandler;
  onEventOccurred?: DesignerEventOccurredHandler;
  showBoundaryLines?: boolean;
  showBullet?: boolean;
  style?: SerializedStyles;
  title?: string;
  validationError?: IFormDesignerValidationError;
}

export const DesignerActionPlan: React.FC<DesignerActionPlanProps> = ({
  designerHintText = 'Marks the action plan as enabled.',
  isEnabled,
  onChange,
  onEventOccurred,
  showBoundaryLines,
  showBullet = true,
  style,
  title,
  validationError,
}) => (
  <div css={[styles.outer, style]}>
    <DesignerSection
      id={'action-plan-title'}
      canDelete={false}
      showBoundaryLines={showBoundaryLines}
      title={title}
      showBullet={showBullet}
      onChange={onChange}
      onEventOccurred={onEventOccurred}
      error={getValidationError(validationError?.fields, 'title')}
    />
    <Switch
      id={'action-plan-enabled'}
      isEnabled={isEnabled}
      showBoundaryLines={showBoundaryLines}
      descriptionToEnable={designerHintText}
      descriptionToDisable={designerHintText}
      editingLabel={'Enabled'}
      onChange={onChange}
      showBullet={showBullet}
    />
  </div>
);

const styles = {
  outer: css({
    position: 'relative',
  }),
};
