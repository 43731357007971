/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DigitalContentCommentsContainer } from '@seeeverything/ui.forms/src/components/DigitalContentComments/DigitalContentCommentsContainer.tsx';
import { InputAdornment } from '@seeeverything/ui.primitives/src/components/InputAdornment/InputAdornment.tsx';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import { useTrackInViewport } from '@seeeverything/ui.primitives/src/hooks/useTrackInViewport.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DASHBOARD_INTERSECTION_OPTIONS } from '../common/constants.ts';
import { IContentPackSelectionListItem } from '../data/types.ts';
import { dateRangeFromFilter, toDateFilter } from '../data/util.ts';
import {
  digitalContentFilterChanged,
  digitalContentLoad,
  loadDigitalContentPacksPage,
} from '../redux/actions.ts';
import {
  useDashboardsDispatch,
  useDashboardsSelector,
} from '../redux/index.ts';

export interface IFactoryDigitalContentProps {
  id: string;
  parentId?: string;
}

export const FactoryDigitalContentContainer: React.FC<
  IFactoryDigitalContentProps
> = ({ id, parentId }) => {
  const dispatch = useDashboardsDispatch();
  const [hasLoaded, setHasLoaded] = useState(false);

  const [forwardedRef, inViewport] = useTrackInViewport({
    dataId: id,
    parentDataId: parentId,
    observerOptions: DASHBOARD_INTERSECTION_OPTIONS,
  });

  const dateFilter = useDashboardsSelector((state) => toDateFilter(state));

  const dateRange = useMemo(
    () =>
      dateFilter
        ? dateRangeFromFilter(
            dateFilter.selection,
            dateFilter.financialYearStartMonth,
            dateFilter.selectedCustomDates,
          )
        : undefined,
    [dateFilter],
  );

  const digitalContentPackDropdownItems = useDashboardsSelector(
    (state) => state.dashboardsV2.dropdowns.digitalContentPacks.items,
  );
  const digitalContentPackDropdownItemsLoading = useDashboardsSelector(
    (state) => state.dashboardsV2.dropdowns.digitalContentPacks.isLoading,
  );

  const isLoaded = useDashboardsSelector(
    (state) => state.dashboardsV2.DIGITAL_CONTENT?.[id]?.dataState?.isLoaded,
  );

  const isLoading = useDashboardsSelector(
    (state) => state.dashboardsV2.DIGITAL_CONTENT?.[id]?.dataState?.isLoading,
  );

  const forceReload = useDashboardsSelector(
    (state) => state.dashboardsV2.DIGITAL_CONTENT?.[id]?.dataState?.forceReload,
  );

  const packSelectorLabel = useDashboardsSelector(
    (state) => state.dashboardsV2.DIGITAL_CONTENT?.[id]?.packSelector?.label,
  );

  const selectedContentPack = useDashboardsSelector(
    (state) =>
      state.dashboardsV2.DIGITAL_CONTENT?.[id]?.packSelector
        .selectedContentPack,
  );

  useEffect(() => {
    if (forceReload) setHasLoaded(false);
  }, [forceReload]);

  useEffect(() => {
    dispatch(
      loadDigitalContentPacksPage(dateRange?.startDate, dateRange?.endDate),
    );
  }, [dispatch, dateRange?.startDate, dateRange?.endDate]);

  useEffect(() => {
    if (hasLoaded) return;
    if (!inViewport) return;

    dispatch(digitalContentLoad(id));
    setHasLoaded(true);
  }, [dispatch, hasLoaded, id, inViewport]);

  const isContentPackSelected = Boolean(selectedContentPack);

  const showSpinner = Boolean(!isLoaded || isLoading);

  const handleDropdownChange = useCallback(
    (to: IContentPackSelectionListItem) =>
      dispatch(digitalContentFilterChanged(id, to)),
    [dispatch, id],
  );

  return (
    <div ref={forwardedRef} parent-data-id={parentId} css={styles.base}>
      <div css={styles.contentPackDropDown}>
        <TextFieldDropdown
          id={'contentPacks'}
          label={packSelectorLabel}
          isEnabled={!isLoading || isContentPackSelected}
          isLoading={digitalContentPackDropdownItemsLoading}
          InputProps={
            showSpinner && !isContentPackSelected
              ? {
                  endAdornment: (
                    <InputAdornment position={'end'}>
                      <Spinner center={true} />
                    </InputAdornment>
                  ),
                }
              : undefined
          }
          selections={digitalContentPackDropdownItems}
          value={selectedContentPack}
          onChange={handleDropdownChange}
          variant={'outlined'}
        />
      </div>
      <div css={styles.digitalContentCommentSection}>
        {!isLoading && !isContentPackSelected ? (
          <Text style={styles.noCommentsMessage}>{'No item selected.'}</Text>
        ) : (
          <DigitalContentCommentsContainer />
        )}
      </div>
    </div>
  );
};

const styles = {
  contentPackDropDown: css({
    textTransform: 'uppercase',
    marginBottom: 25,
  }),
  base: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    minHeight: 200,
    margin: '3px 30px 0px 35px',
  }),
  noCommentsMessage: css({
    textAlign: 'center',
    flex: '1 1 auto',
    fontStyle: 'italic',
    fontWeight: 300,
    color: color.format(-0.2),
  }),
  digitalContentCommentSection: css({
    justifyContent: 'center',
    display: 'flex',
    marginBottom: 25,
  }),
};
