import { useGraphQL } from '@seeeverything/ui.util/src/graphql/GraphQLProvider.tsx';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { ascend, prop, sortWith } from 'ramda';
import { useMemo } from 'react';
import { DashboardTimespanVariable } from '../../dashboards/query/types.ts';
import { formsQuery } from '../query/index.ts';
import { CoachingSummarySectionIssue } from '../types.ts';

type UseQueryDashboardCoachingSummaryIssuesArgs = {
  cacheId: string;
  entityId: string;
  entityType: 'Person' | 'Team';
  timespan: DashboardTimespanVariable;
  isQueryEnabled: boolean;
};

export const useQueryDashboardCoachingSummaryIssues = (
  args: UseQueryDashboardCoachingSummaryIssuesArgs,
) => {
  const client = useGraphQL();

  const pageQueryResponse = useInfiniteQuery({
    queryKey: [
      {
        key: args.cacheId,
        entityId: args.entityId,
        entityType: args.entityType,
        timespan: args.timespan,
      },
    ],
    queryFn: async ({ queryKey }) => {
      const { entityId, entityType, timespan } = queryKey[0];

      const response = await formsQuery.getDashboardCoachingSummaryIssues(
        client,
        {
          entityId,
          entityType,
          timespan,
          fetchAllPages: true,
        },
      );

      if (!response.isSuccess)
        throw new Error(
          `Error while fetching coaching summary issues for ${entityType} with id ${entityId}`,
        );

      return {
        pageInfo: response.data.pageInfo,
        issues: response.data.issues,
      };
    },
    getNextPageParam: (latestPage) =>
      latestPage ? latestPage.pageInfo.currentPage + 1 : 1,
    enabled: args.isQueryEnabled,
    placeholderData: keepPreviousData,
    initialPageParam: 1,
  });

  const issues = useMemo(() => {
    if (!pageQueryResponse.data) return [];

    return pageQueryResponse.data.pages
      .filter(Boolean)
      .flatMap((page) => page.issues);
  }, [pageQueryResponse.data]);

  const totalIssues =
    pageQueryResponse.data?.pages[0]?.pageInfo.totalCount ?? 0;

  const sortIssues = sortWith<CoachingSummarySectionIssue>([
    ascend(prop('label')),
  ]);

  return {
    issues: sortIssues(issues),
    totalIssues,
    hasNextPageIssues: Boolean(
      pageQueryResponse.data?.pages?.[pageQueryResponse.data.pages.length - 1]
        ?.pageInfo.hasNextPage,
    ),
    isLoadingIssues: pageQueryResponse.isLoading,
    isFetchingIssues: pageQueryResponse.isFetching,
    isFetchingIssuesNextPage: pageQueryResponse.isFetchingNextPage,
    fetchNextIssuesPage: pageQueryResponse.fetchNextPage,
    isErrorIssues: pageQueryResponse.isError,
    refetchIssues: pageQueryResponse.refetch,
  };
};
