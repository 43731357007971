/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DashboardTimespanVariable } from '@se/data/dashboards/query/types.ts';
import { useQueryDashboardCoachingSummaryIssues } from '@se/data/forms/hooks/useQueryDashboardCoachingSummaryIssues.ts';
import { CoachingSummaryError } from '@seeeverything/ui.forms/src/components/FormLineCoachingSummary/components/CoachingSummaryError.tsx';
import { CoachingSummaryIssueGroup } from '@seeeverything/ui.forms/src/components/FormLineCoachingSummary/components/issues/CoachingSummaryIssueGroup.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { SkeletonDetailedItemsList } from '@seeeverything/ui.primitives/src/components/SkeletonDetailedItemsList/SkeletonDetailedItemsList.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useMeasure } from '@seeeverything/ui.primitives/src/hooks/useMeasure.ts';
import { useTenantModule } from '@seeeverything/ui.primitives/src/hooks/useTenantModule.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { groupBy } from 'ramda';
import { useCallback, useEffect, useState } from 'react';

export type IssuesContainerProps = {
  issueListId: string;
  entityId: string;
  entityType: 'person' | 'team';
  inViewport: boolean;
  timespan: DashboardTimespanVariable;
};

export const IssuesContainer: React.FC<IssuesContainerProps> = ({
  issueListId,
  entityId,
  entityType,
  inViewport,
  timespan,
}) => {
  const module = useTenantModule();

  const [isQueryEnabled, setIsQueryEnabled] = useState(inViewport);
  useEffect(() => {
    if (inViewport && !isQueryEnabled) setIsQueryEnabled(true);
  }, [inViewport, isQueryEnabled]);

  const {
    issues,
    isLoadingIssues,
    isFetchingIssues,
    isErrorIssues,
    refetchIssues,
  } = useQueryDashboardCoachingSummaryIssues({
    cacheId: `${module}-${issueListId}-${entityId}`,
    entityId,
    entityType: entityType === 'person' ? 'Person' : 'Team',
    timespan,
    isQueryEnabled,
  });

  const [measureRef, issuesSize] = useMeasure({
    remeasureDependency: isLoadingIssues || isFetchingIssues,
  });

  const [showLess, setShowLess] = useState<boolean>(false);
  const [userShowMore, setUserShowMore] = useState<boolean>(false);

  useEffect(() => {
    if (issues?.length === 0) {
      setShowLess(false);
      return undefined;
    }
    if (!issuesSize) return undefined;

    if (!userShowMore && issuesSize.height < 199) setShowLess(false);
    if (!userShowMore && issuesSize.height >= 199) setShowLess(true);
  }, [issues?.length, issuesSize, userShowMore]);

  const handleShowMore = useCallback(() => {
    setShowLess(false);
    setUserShowMore(true);
  }, []);

  const issueGroups = Object.entries(
    groupBy((issue) => `${issue.label}-${issue.issueCoachingRequired}`, issues),
  );

  const elIssues = !isErrorIssues &&
    isQueryEnabled &&
    !isLoadingIssues &&
    issues.length > 0 && (
      <div ref={measureRef} css={styles.groups}>
        {issueGroups.map(([label, group]) => (
          <CoachingSummaryIssueGroup
            key={label}
            label={group[0].label}
            issues={group}
            sectionExpanded={false}
            issueListId={issueListId}
            onAccordionChange={handleShowMore}
            size={'small'}
          />
        ))}
      </div>
    );

  return (
    <div
      css={[
        styles.base,
        showLess ? styles.showLess : undefined,
        isFetchingIssues ? styles.mask : undefined,
      ]}
    >
      {isLoadingIssues === true && (
        <SkeletonDetailedItemsList
          itemStyle={styles.skeletonList}
          numberOfSkeletonItems={3}
        />
      )}
      {isErrorIssues && (
        <CoachingSummaryError type={'issues'} onRetryLoad={refetchIssues} />
      )}
      {elIssues}
      {!isErrorIssues &&
        isQueryEnabled &&
        !isLoadingIssues &&
        issues.length === 0 && (
          <Text color={color.format(-0.65)} size={14}>
            {'There are no recent issues to show.'}
          </Text>
        )}
      {showLess && (
        <div css={styles.expand} onClick={handleShowMore}>
          <Text color={COLORS.BLUE} cursor={'pointer'} size={14}>
            {'Show more'}
          </Text>
          <Icons.arrowDropDown size={40} cursor={'pointer'} />
        </div>
      )}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    position: 'relative',
    overflow: 'hidden',
  }),
  showLess: css({
    maxHeight: 240,
    cursor: 'pointer',
  }),
  expand: css({
    position: 'absolute',
    inset: 'auto 0 0 0',
    height: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderTop: 'solid 6px #E5E5E5',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fafafa',
    },
  }),
  groups: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  mask: css({
    opacity: 0.6,
  }),
  skeletonList: css({
    margin: 8,
  }),
};
