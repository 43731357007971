/** @jsxImportSource @emotion/react */
import { ReduxFormInstanceInterfaceConfirmRevertSignoff } from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import { RevertSignoffConfirmationDialog } from '@seeeverything/ui.shell/src/components/TextInputConfirmationDialog/RevertSignoffConfirmationDialog.tsx';
import { showModalDialog } from '@seeeverything/ui.shell/src/redux/modalDialog/actions.ts';
import { combineEpics, ofType } from 'redux-observable';
import { map, Observable } from 'rxjs';
import {
  GlobalAppActionType,
  GlobalAppEpicDependencies,
  GlobalAppState,
} from '../../../types.ts';

export const epics = combineEpics<
  GlobalAppActionType,
  GlobalAppActionType,
  GlobalAppState,
  GlobalAppEpicDependencies
>(handleConfirmRevert);

/**
 * Handles confirming reverting of signoff (reason capture).
 */
function handleConfirmRevert(
  action$: Observable<ReduxFormInstanceInterfaceConfirmRevertSignoff>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/INTERFACE_CONFIRM_REVERT_SIGNOFF'),
    map((action) =>
      showModalDialog({
        content: (
          <RevertSignoffConfirmationDialog
            instanceId={action.payload.instanceId}
            personId={action.payload.personId}
            signature={action.payload.signature}
          />
        ),
        width: 700,
      }),
    ),
  );
}
