/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { formsQuery } from '@se/data/forms/query/index.ts';
import {
  ActionBar,
  IActionBarButton,
} from '@seeeverything/ui.primitives/src/components/ModalDialog/index.ts';
import { ScrollContainer } from '@seeeverything/ui.primitives/src/components/Scroll/ScrollContainer.tsx';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/Spinner.tsx';
import { Transition } from '@seeeverything/ui.primitives/src/components/Transition/index.ts';
import { useMomentTenantTimezone } from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useGraphQL } from '@seeeverything/ui.util/src/graphql/GraphQLProvider.tsx';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { editGoalScheduleSlice } from '../../../redux/editGoalSchedule/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';
import { GoalScheduleFieldsContainer } from './GoalScheduleFieldsContainer.tsx';
import { GoalScheduleStatusBarContainer } from './GoalScheduleStatusBarContainer.tsx';

export const GoalScheduleEditPanelContainer: React.FC = () => {
  const dispatch = useFormsDispatch();
  const client = useGraphQL();

  const momentTenantTimezone = useMomentTenantTimezone();

  const hasChanges = useFormsSelector(
    (state) => state.editGoalSchedule.hasDraftChanged,
  );

  const isSaving = useFormsSelector((state) => state.editGoalSchedule.isSaving);

  const draftSaved = useFormsSelector((state) => state.editGoalSchedule.saved);

  const globalError = useFormsSelector(
    (state) => state.editGoalSchedule.errors.globalError,
  );

  const original = useFormsSelector((state) => state.editGoalSchedule.original);

  const status = useMemo(() => {
    if (isSaving) return 'SAVING';
    if (globalError) return 'ERROR';

    return 'INFO';
  }, [globalError, isSaving]);

  const statusMessage = useMemo(() => {
    if (status === 'ERROR') return globalError;
    if (status === 'SAVING') return 'Saving...';
    if (draftSaved) return 'Save Successful.';
    if (!original) return;

    if (original.status === 'Started')
      return 'Schedule has started. Only the end date and status can be changed.';

    if (original.status !== 'Inactive') return;

    const hasStartDateElapsed =
      momentTenantTimezone(original.startDate) < momentTenantTimezone();
    const hasEndDateElapsed = original.endDate
      ? momentTenantTimezone() > momentTenantTimezone(original.endDate)
      : true;

    if (hasStartDateElapsed && hasEndDateElapsed)
      return 'Schedule date has passed. The schedule is inactive.';

    return 'The schedule has been marked as inactive and can be reactivated.';
  }, [status, globalError, draftSaved, original, momentTenantTimezone]);

  const { data: formGoalCategoryData, isLoading: loadingFormGoalCategories } =
    useQuery({
      placeholderData: keepPreviousData,
      queryKey: [{ key: 'goalSchedules.formGoalCategories' }],
      queryFn: async () => {
        const response = await formsQuery.getFormGoalCategories(client, {
          fetchAllPages: true,
        });

        return response.isSuccess ? response.data : null;
      },
    });

  const dismissAction = useMemo<IActionBarButton>(
    () => ({
      id: 'CANCEL',
      label: 'Cancel',
      isEnabled: !isSaving,
      backgroundAlways: false,
      onAction: () => dispatch(editGoalScheduleSlice.dismiss()),
    }),
    [dispatch, isSaving],
  );

  const saveAction = useMemo<IActionBarButton>(
    () => ({
      id: 'SAVE',
      label: 'Save',
      isEnabled: hasChanges && !isSaving,
      onAction: () =>
        dispatch(
          editGoalScheduleSlice.save({
            hasGoalCategories: Boolean(
              formGoalCategoryData?.formGoalCategories.length,
            ),
          }),
        ),
    }),
    [
      hasChanges,
      isSaving,
      dispatch,
      formGoalCategoryData?.formGoalCategories.length,
    ],
  );

  return (
    <div css={styles.base}>
      <Transition.Collapse in={Boolean(statusMessage)}>
        <GoalScheduleStatusBarContainer
          status={status}
          message={statusMessage}
        />
      </Transition.Collapse>
      <div css={styles.savingContainer}>
        <Transition.Fade in={isSaving} mountOnEnter={true} unmountOnExit={true}>
          <div css={styles.savingOverlay}>
            <Spinner />
          </div>
        </Transition.Fade>
        <ScrollContainer>
          <div css={styles.fieldsContainer}>
            <GoalScheduleFieldsContainer
              loadingGoalCategories={loadingFormGoalCategories}
              goalCategories={formGoalCategoryData?.formGoalCategories ?? []}
            />
          </div>
        </ScrollContainer>
      </div>
      <ActionBar
        actions={[dismissAction, saveAction]}
        style={styles.footerActions}
      />
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: 'white',
  }),
  fieldsContainer: css({
    padding: '5px 30px 5px 30px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: 'white',
  }),
  footerActions: css({
    borderTop: 'none',
    backgroundColor: 'rgb(237, 237, 237)',
    padding: '5px 15px 5px 15px',
  }),
  savingOverlay: css({
    position: 'absolute',
    inset: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: color.format(0.8),
    zIndex: 1,
  }),
  savingContainer: css({
    position: 'relative',
    flex: '1 1 auto',
  }),
};
