/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormInstanceStatus } from '@se/data/forms/types.ts';
import { Form } from '@seeeverything/ui.forms/src/components/Form/index.ts';
import {
  FormStatusBar,
  FormStatusBarType,
} from '@seeeverything/ui.forms/src/components/FormStatusBar/FormStatusBar.tsx';
import {
  FormLineById,
  FormPerson,
  FormTeam,
} from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import { FormScore } from '@seeeverything/ui.forms/src/redux/form-score/types.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/index.ts';
import { TextButton } from '@seeeverything/ui.primitives/src/components/TextButton/TextButton.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { TenantLocale } from '@seeeverything/ui.util/src/redux/tenant/index.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { ISheetIndexItem } from '../SheetIndex/types.ts';
import { SheetLayout } from '../SheetLayout/index.ts';
import { StatusBar } from '../StatusBar/StatusBar.tsx';
import { SheetFormScore } from './SheetFormScore.tsx';

export interface ISheetFormProps {
  canUpdateInstance: boolean;
  indexItems: ISheetIndexItem[];
  instanceId: string;
  instanceStatus: FormInstanceStatus;
  instanceSubject?: FormPerson | FormTeam;
  instanceReviewer?: FormPerson;
  isSpinning: boolean;
  lines: FormLineById;
  locale: TenantLocale;
  moreItems: ISelectionListItem[];
  onAddActionClicked: () => void;
  onAwaitingReviewClick: () => void;
  onRevertSignOff: () => void;
  score: FormScore;
  scoreIsVisible: boolean;
  showAddActionButton: boolean;
  statusBarType?: FormStatusBarType;
  title?: string;
}

export const SheetForm: React.FC<ISheetFormProps> = ({
  canUpdateInstance,
  indexItems,
  instanceId,
  instanceStatus,
  instanceSubject,
  instanceReviewer,
  isSpinning,
  lines,
  locale,
  moreItems,
  onAddActionClicked,
  onAwaitingReviewClick,
  onRevertSignOff,
  score,
  scoreIsVisible,
  showAddActionButton,
  statusBarType,
  title,
}) => {
  const elFormStatusBar = statusBarType && (
    <FormStatusBar
      formLabel={locale.label.form}
      key={'form-status-bar'}
      onAwaitingReviewClick={onAwaitingReviewClick}
      onRevert={onRevertSignOff}
      reviewer={instanceReviewer}
      subject={instanceSubject}
      type={statusBarType}
    />
  );

  const elToolbarRightContent = (
    <div css={styles.rightContent}>
      {showAddActionButton && !isSpinning && (
        <TextButton
          color={'white'}
          icon={Icons.addCircle}
          iconSize={22}
          iconStyle={styles.addActionButtonIcon}
          onClick={onAddActionClicked}
          size={15}
          buttonStyle={styles.addActionButton}
          textStyle={styles.addActionButtonText}
          tooltip={`Add a new ${locale.label.action} to this ${locale.label.form}.`}
          weight={FontWeight.light}
        >
          {locale.label.action}
        </TextButton>
      )}
      {score?.isBreakdownViewable && (
        <SheetFormScore
          answeredQuestions={score.answeredQuestions}
          currentScorePoints={score.currentScorePoints}
          currentWeightPoints={score.currentWeightPoints}
          failMaximumScore={score.failMaximumScore}
          instanceStatus={instanceStatus}
          instantFailAnswerCount={score.instantFailAnswerCount}
          passRate={score.passRate}
          percentageScore={score.percentageScore}
          result={score.result}
          totalScorableQuestions={score.totalScorableQuestions}
          isVisible={scoreIsVisible}
        />
      )}
    </div>
  );

  return (
    <div css={styles.base}>
      <SheetLayout
        header={elFormStatusBar}
        headerCollapsedHeight={50}
        headerExpandedHeight={50}
        indexItems={indexItems}
        isSpinning={isSpinning}
        moreItems={moreItems}
        rightContent={elToolbarRightContent}
        rootId={'form-container'}
        scrollSmooth={true}
        title={title}
      >
        <Form
          canUpdateInstance={canUpdateInstance}
          instanceId={instanceId}
          isSpinning={isSpinning}
          lines={lines}
        />
        <StatusBar position={'fixed'} />
      </SheetLayout>
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    width: '100%',
  }),
  rightContent: css({
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  }),
  addActionButton: css({
    padding: '3px 16px',
    background: COLORS.BLUE,
    '&:hover': {
      background: color.create(COLORS.BLUE).brighten(0.2).css(),
    },
  }),
  addActionButtonText: css({
    textTransform: 'capitalize',
  }),
  addActionButtonIcon: { fill: 'white' },
};
