import { z } from 'zod';

const issuesAndInsights = z.object({
  type: z.enum(['issues', 'learning-areas', 'highlights']),
});

export const dashboardIssuesAndInsightsSchema = {
  issuesAndInsights,
};

export type DashboardIssuesAndInsightsSchemaTypes = {
  IssuesAndInsights: z.infer<typeof issuesAndInsights>;
};
