/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Z_INDEX } from '../../common/constants.ts';
import { Paper } from '../Paper/Paper.tsx';
import { ScrollContainer } from '../Scroll/ScrollContainer.tsx';

export type OverlayPosition = 'top' | 'right' | 'bottom' | 'left';

export type OverlayProps = {
  overlay: React.ReactNode;
  isVisible?: boolean;
  position?: OverlayPosition;
  size?: string | number;
  isScrollable?: boolean;
  children?: React.ReactNode;
};

/**
 * A component that renders one child over another
 */
export const Overlay: React.FC<OverlayProps> = ({
  position = 'right',
  size = '50%',
  isVisible = true,
  isScrollable = false,
  overlay,
  children,
}) => {
  const computedStyles = {
    overlay: css({
      position: 'absolute',
      ...calculatePosition(position, isVisible),
      ...calculateSize(size, position),
      transition: 'all 0.6s',
    }),
  };

  const elScrollable = isScrollable ? (
    <ScrollContainer>{overlay}</ScrollContainer>
  ) : (
    overlay
  );

  return (
    <div css={styles.base}>
      {children}
      <Paper style={computedStyles.overlay} elevation={Z_INDEX.OVERLAY_PANEL}>
        {elScrollable}
      </Paper>
    </div>
  );
};

function calculatePosition(
  position: OverlayPosition,
  isOverlayVisible: boolean,
) {
  switch (position) {
    case 'top':
      return {
        top: isOverlayVisible ? 0 : '-100%',
        left: 0,
      };
    case 'right':
      return {
        top: 0,
        right: isOverlayVisible ? 0 : '-100%',
      };
    case 'bottom':
      return {
        bottom: isOverlayVisible ? 0 : '-100%',
        left: 0,
      };
    case 'left':
      return {
        top: 0,
        left: isOverlayVisible ? 0 : '-100%',
      };
  }
}

function calculateSize(size: string | number, position: OverlayPosition) {
  switch (position) {
    case 'top':
    case 'bottom':
      return {
        height: size,
        width: '100%',
      };

    case 'left':
    case 'right':
      return {
        height: '100%',
        width: size,
        minWidth: 500,
      };
  }
}

const styles = {
  base: css({
    width: '100%',
    height: '100%',
    position: 'relative',
  }),
};
