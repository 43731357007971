/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { Transition } from '@seeeverything/ui.primitives/src/components/Transition/index.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useMemo } from 'react';
import { useFormsSelector } from '../../../redux/store.ts';

const getTheme = (status: 'ERROR' | 'SAVING' | 'INFO') => {
  switch (status) {
    case 'ERROR':
      return {
        icon: Icons.errorOutline,
        iconColor: 'red',
        bg: 'rgba(255, 0, 0, 0.1)',
        border: 'rgba(255, 0, 0, 0.05)',
      };

    case 'SAVING':
      return {
        icon: Icons.clock,
        iconColor: color.format(-0.8),
        bg: color.create(COLORS.BLUE).alpha(0.1).css(),
        border: `solid 1px ${color.create(COLORS.BLUE).alpha(0.1).css()}`,
      };

    case 'INFO':
    default:
      return {
        icon: Icons.verifiedUser,
        iconColor: '#37BA54',
        bg: color.create(COLORS.BLUE).alpha(0.1).css(),
        border: `solid 1px ${color.create(COLORS.BLUE).alpha(0.1).css()}`,
      };
  }
};

export const InstanceScheduleStatusBarContainer = () => {
  const isSaving = useFormsSelector(
    (state) => state.editInstanceSchedule.isSaving,
  );

  const globalError = useFormsSelector(
    (state) => state.editInstanceSchedule.errors?.globalError,
  );

  const draftSaved = useFormsSelector(
    (state) => state.editInstanceSchedule.saved,
  );

  const original = useFormsSelector(
    (state) => state.editInstanceSchedule.original,
  );

  const status = useMemo(() => {
    if (isSaving) return 'SAVING';
    if (globalError) return 'ERROR';
    return 'INFO';
  }, [globalError, isSaving]);

  const message = useMemo(() => {
    if (isSaving) return 'Saving...';
    if (globalError) return globalError;

    if (draftSaved) return 'Save Successful.';
    if (!original) return undefined;
    if (original.status === 'Inactive')
      return 'This schedule is Inactive and can no longer be updated.';
    if (original.status === 'Ended')
      return 'This schedule has ended and can no longer be updated.';
    if (original.status === 'NotStarted')
      return 'This schedule has not started and can be updated.';
    if (original.status === 'Started')
      return 'This schedule has started running. Some details cannot be changed.';
  }, [isSaving, globalError, draftSaved, original]);

  const theme = getTheme(status);

  const computedStyles = {
    base: {
      backgroundColor: theme.bg,
      border: theme.border,
    },
  };

  return (
    <Transition.Collapse in={Boolean(message)}>
      <div css={[styles.base, computedStyles.base]}>
        <theme.icon
          size={20}
          fill={theme.iconColor}
          style={styles.statusIcon}
        />
        <Text
          color={color.format(-0.8)}
          style={styles.statusText}
          ellipsis={true}
        >
          {message}
        </Text>
      </div>
    </Transition.Collapse>
  );
};

const styles = {
  base: css({
    display: 'flex',
    alignItems: 'center',
    padding: 15,
  }),
  statusIcon: {
    width: 25,
    alignSelf: 'center',
  },
  statusText: css({
    paddingLeft: 15,
  }),
};
