/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryFormTemplates } from '@se/data/forms/hooks/useQueryFormTemplates.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { IDataGridColumn } from '@seeeverything/ui.primitives/src/components/DataGrid/types.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { InputAdornment } from '@seeeverything/ui.primitives/src/components/InputAdornment/InputAdornment.tsx';
import { SkeletonDetailedItemsList } from '@seeeverything/ui.primitives/src/components/SkeletonDetailedItemsList/SkeletonDetailedItemsList.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { isFuzzyMatch } from '@seeeverything/ui.util/src/str/str.fuzzy.ts';
import { ascend, sortWith } from 'ramda';
import { useCallback, useMemo, useState } from 'react';
import { FormTemplateColumnFilter } from '../../../types/types.ts';

export type DataGridFormTemplateFilterProps = {
  column: IDataGridColumn;
  gridId: string;
  filters: FormTemplateColumnFilter[];
  onColumnFilterSelection: (
    gridId: string,
    columnId: string,
    filter: FormTemplateColumnFilter,
    to: boolean,
  ) => void;
  onColumnFilterClearAll: (gridId: string, columnId: string) => void;
};

export const DataGridFormTemplateFilter: React.FC<
  DataGridFormTemplateFilterProps
> = ({
  column,
  gridId,
  filters,
  onColumnFilterClearAll,
  onColumnFilterSelection,
}) => {
  const canFilter =
    column.isFilterable === true && column.type === 'FORM_TEMPLATE';

  const sortFilters = sortWith<FormTemplateColumnFilter>([
    ascend((template) => template.value.name ?? ''),
  ]);

  const [search, setSearch] = useState<string>();
  const [initialFilters] = useState<FormTemplateColumnFilter[]>(
    sortFilters(filters),
  );

  const { templateSelections, templatesLoading } = useQueryFormTemplates(true);

  const displayList = useMemo(() => {
    if (templatesLoading) return;

    const list: FormTemplateColumnFilter[] = [];

    if (!search) list.push(...initialFilters);

    list.push(
      ...templateSelections
        .filter(
          (template) =>
            !list.some(
              (initialTemplate) => initialTemplate.value.id === template.id,
            ) && isFuzzyMatch(search, template.content.text),
        )
        .map(
          (template): FormTemplateColumnFilter => ({
            type: 'FORM_TEMPLATE',
            value: template.value,
          }),
        ),
    );
    return list;
  }, [initialFilters, search, templateSelections, templatesLoading]);

  const handleClearColumnFilters = useCallback(() => {
    setSearch(undefined);
    onColumnFilterClearAll(gridId, column.id);
  }, [column.id, gridId, onColumnFilterClearAll]);

  const handleSearchChange = useCallback((to: string) => {
    setSearch(to);
  }, []);

  const handleColumnFilterSelection = useCallback(
    (template: FormTemplateColumnFilter, isSelected: boolean) => {
      const exists = filters.some(
        (filter) => filter.value.id === template.value.id,
      );

      if (exists && isSelected) return;
      if (!exists && !isSelected) return;

      onColumnFilterSelection(gridId, column.id, template, isSelected);
    },
    [column.id, filters, gridId, onColumnFilterSelection],
  );

  const elFiltersList = useMemo(
    () =>
      !templatesLoading && (
        <>
          {!displayList?.length && (
            <Text css={styles.emptyText} color={color.format(-0.6)}>
              {search
                ? 'There are no results available. Update or remove your search to get more results.'
                : 'No filters available.'}
            </Text>
          )}
          {displayList?.map((template) => {
            const isSelected = filters.some(
              ({ value }) => value.id === template.value.id,
            );
            const showTick = isSelected;
            const tickColor = isSelected
              ? COLORS.GREEN_TICK
              : color.format(-0.2);
            return (
              <Button
                key={`Filter-${template.value.id}`}
                onClick={() =>
                  handleColumnFilterSelection(template, !isSelected)
                }
              >
                <div css={styles.itemButton}>
                  <Icons.filterList />
                  <Text
                    color={
                      template.value.status === 'Archived'
                        ? color.format(-0.4)
                        : color.format(-0.6)
                    }
                    cursor={'inherit'}
                    selectable={false}
                    ellipsis={true}
                    italic={template.value.status === 'Archived'}
                    tooltip={template.value.name}
                    size={15}
                  >
                    {template.value.name}
                  </Text>
                  {showTick && (
                    <div css={styles.tick}>
                      <Icons.tickDone fill={tickColor} />
                    </div>
                  )}
                </div>
              </Button>
            );
          })}
        </>
      ),
    [
      displayList,
      filters,
      handleColumnFilterSelection,
      search,
      templatesLoading,
    ],
  );

  if (!canFilter) return;

  return (
    <div css={styles.group}>
      <div css={styles.title}>
        <Text
          size={13}
          selectable={false}
          uppercase={true}
          weight={900}
          color={color.format(-0.4)}
        >
          {'Filters'}
        </Text>
        {Boolean(filters.length) && (
          <div css={styles.clearSelections}>
            <Button onClick={handleClearColumnFilters} style={styles.button}>
              <div css={styles.clearButton}>
                <Icons.clear size={13} />
                <Text
                  color={COLORS.BLUE}
                  uppercase={true}
                  cursor={'pointer'}
                  size={12}
                  weight={900}
                  align={'right'}
                >
                  {'Clear Filters'}
                </Text>
              </div>
            </Button>
          </div>
        )}
      </div>
      <div css={styles.searchBox}>
        <TextField
          id={`${column.id}-search`}
          variant={'outlined'}
          InputProps={{
            autoFocus: true,
            startAdornment: (
              <InputAdornment position={'start'}>
                <Icons.search fill={color.format(-0.18)} />
              </InputAdornment>
            ),
          }}
          onChange={handleSearchChange}
          placeholder={'Search...'}
          value={search}
        />
      </div>
      <div css={styles.filterScrollContainer}>
        {elFiltersList}
        {templatesLoading && <SkeletonDetailedItemsList />}
      </div>
    </div>
  );
};

const styles = {
  title: css({
    display: 'flex',
    flexDirection: 'row',
    padding: '5px 10px',
    backgroundColor: color.format(-0.1),
    borderBottom: `solid 1px ${color.format(-0.05)}`,
    alignItems: 'center',
  }),
  group: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  button: css({
    margin: '-5px -10px -5px -5px',
    padding: '5px 10px 5px 5px',
  }),
  itemButton: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    padding: '5px 10px',
    minHeight: 28,
  }),
  searchBox: css({
    padding: '5px 10px',
  }),
  filterScrollContainer: css({
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 416,
    overflowY: 'auto',
  }),
  tick: css({
    marginLeft: 'auto',
  }),
  clearSelections: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    marginLeft: 'auto',
  }),
  clearButton: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center',
    justifyContent: 'flex-end',
  }),
  emptyText: css({
    padding: 10,
  }),
};
