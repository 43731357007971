import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { dashboardIssuesAndInsightsSchema } from '../schema/dashboardIssuesAndInsightsSchema.ts';
import { IDashboardIssuesAndInsights } from '../types.ts';

/**
 * Attempts to parse values to construct a issues and insights section
 */
export function parser(
  item: any,
  index: number,
): IDashboardIssuesAndInsights | undefined {
  const issuesAndInsights = item['issues-and-insights'];
  if (!issuesAndInsights) return;

  const validatedParse =
    dashboardIssuesAndInsightsSchema.issuesAndInsights.safeParse(
      issuesAndInsights,
    );
  if (!validatedParse.success) {
    log.error(
      new Error(
        `Issues and Insights failed schema validation: ${validatedParse.error}`,
      ),
    );
    return;
  }

  return {
    componentType: 'ISSUES_AND_INSIGHTS',
    id: `IssuesInsights.${issuesAndInsights['type']}`,
    index,
    type: issuesAndInsights['type'],
    dataState: {
      dataSetId: '',
      isLoaded: false,
      isLoading: false,
      forceReload: false,
      data: {},
    },
  };
}
