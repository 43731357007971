/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { InputAdornment } from '@seeeverything/ui.primitives/src/components/InputAdornment/InputAdornment.tsx';
import {
  ISelectionListItem,
  SelectionListClickEventHandler,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { SelectionListHierarchyViewport } from '@seeeverything/ui.primitives/src/components/SelectionListHierarchyViewport/SelectionListHierarchyViewport.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { CheckboxGroupSkeleton } from './CheckboxGroupSkeleton.tsx';

export type RuleOptionsSelectListProps = {
  filter: string;
  isLoading: boolean;
  items: ISelectionListItem[];
  onTeamFilterChanged: (to: string) => void;
  onTeamSelected: SelectionListClickEventHandler;
};

const EMPTY_RESULT_MESSAGE =
  'Your filter returned no results. Update or remove your filter to get more results.';

export const RuleOptionsSelectList: React.FC<RuleOptionsSelectListProps> = ({
  isLoading,
  items,
  filter,
  onTeamSelected,
  onTeamFilterChanged,
}) => (
  <div css={styles.base}>
    <div css={styles.filter}>
      <TextField
        id={'entityFilter'}
        placeholder={'Filter'}
        onChange={onTeamFilterChanged}
        isEnabled={true}
        value={filter}
        variant={'outlined'}
        InputProps={{
          autoFocus: true,
          startAdornment: (
            <InputAdornment position={'start'}>
              <Icons.search fill={color.format(-0.18)} />
            </InputAdornment>
          ),
        }}
      />
    </div>
    <div css={styles.entityCheckList}>
      {!isLoading && items.length === 0 && (
        <Text
          weight={400}
          size={16}
          color={color.format(-0.5)}
          italic={true}
          css={styles.noResultsLabel}
        >
          {EMPTY_RESULT_MESSAGE}
        </Text>
      )}
      {isLoading && <CheckboxGroupSkeleton />}
      {!isLoading && items.length > 0 && (
        <SelectionListHierarchyViewport
          column={{ items, isScrollable: false }}
          isScrollable={false}
          isStateful={false}
          onClick={onTeamSelected}
          keys$={null}
        />
      )}
    </div>
  </div>
);

const styles = {
  base: css({
    display: 'flex',
    backgroundColor: 'white',
    border: `solid 1px ${color.format(-0.18)}`,
    borderRadius: 3,
    flexDirection: 'column',
    width: 330,
  }),
  entityCheckList: css({
    maxHeight: '275px',
    overflowY: 'scroll',
    padding: '0px 20px',
  }),
  filter: css({
    padding: '7px 14px',
  }),
  noResultsLabel: css({
    fontSize: '12px',
    fontWeight: 300,
    width: '100%',
    height: 50,
    display: 'flex',
    textAlign: 'center',
    minHeight: 250,
  }),
};
