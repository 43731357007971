/** @jsxImportSource @emotion/react */
import { formsQuery } from '@se/data/forms/query/index.ts';
import {
  dateRangeFromFilter,
  toDateFilter,
  toTimespanVariable,
} from '@seeeverything/ui.dashboards/src/data/util.ts';
import { DashboardDateFilterSelection } from '@seeeverything/ui.dashboards/src/types.ts';
import { CsvDialogContainer } from '@seeeverything/ui.shell/src/components/CsvDialog/index.ts';
import {
  csvExportFiltersLoaded,
  exportDone,
  exportReadyStatusCheck,
  exportReadyStatusCheckCompleted,
  exportReadyStatusCheckFailed,
  loadCsvExportFilters,
} from '@seeeverything/ui.shell/src/redux/csvExport/actions.ts';
import {
  CsvExportConfirmedAction,
  CsvExportFilter,
  CsvExportLoadAction,
  CsvExportReadyStatusCheckAction,
  CsvExportReadyStatusCheckCompletedAction,
  CsvExportReadyStatusCheckFailedAction,
  CsvExportSheetArgs,
} from '@seeeverything/ui.shell/src/redux/csvExport/types.ts';
import {
  hideModalDialog,
  showModalDialog,
} from '@seeeverything/ui.shell/src/redux/modalDialog/actions.ts';
import { showStatusBar } from '@seeeverything/ui.shell/src/redux/sheets/actions.ts';
import { SheetToolbarDropdownClickAction } from '@seeeverything/ui.shell/src/redux/sheets/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { storageApi } from '@seeeverything/ui.util/src/storage/api.ts';
import { delay } from '@seeeverything/ui.util/src/timer/timer.ts';
import gql from 'graphql-tag';
import moment from 'moment';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import {
  concatAll,
  filter,
  from,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import {
  GlobalAppActionType,
  GlobalAppEpicDependencies,
  GlobalAppState,
} from '../../../types.ts';

export const epics = combineEpics<
  GlobalAppActionType,
  GlobalAppActionType,
  GlobalAppState,
  GlobalAppEpicDependencies
>(
  csvExportDownloadFileEpic,
  csvExportEpic,
  csvExportFailedEpic,
  csvExportReadyCheckEpic,
  loadTemplatesAndCategoriesForExportEpic,
  showStatusBarOnDialogExportClickedEpic,
  toolbarExportClickedWithFiltersEpic,
);

const EXPORT_DATA_SETS: { [key: string]: string } = {
  DASHBOARD_EXPORT_SUMMARY: 'csv.summary',
  DASHBOARD_EXPORT_DETAILS: 'csv.details',
  DASHBOARD_EXPORT_GOALS: 'csv.goals-and-actions',
  DASHBOARD_EXPORT_ACTIONS: 'csv.actions',
};

function loadTemplatesAndCategoriesForExportEpic(
  action$: Observable<CsvExportLoadAction>,
  state$: StateObservable<GlobalAppState>,
  { client }: GlobalAppEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.shell/csvExport/LOAD'),
    switchMap(async () => {
      try {
        const error = of(
          showStatusBar(
            'ERROR',
            'Unable to load the form categories for a CSV Extract. Please try again.',
          ),
        );

        const templatesResponse = await formsQuery.getFormTemplates(client, {
          showAll: true,
          module: state$.value.tenantState.tenant.module,
          fetchAllPages: true,
        });
        if (templatesResponse.isSuccess !== true) return error;

        const categoriesResponse = await formsQuery.getFormTemplateCategories(
          client,
          {
            showAll: true,
            module: state$.value.tenantState.tenant.module,
            fetchAllPages: true,
          },
        );
        if (categoriesResponse.isSuccess !== true) return error;

        const filteredCategories =
          categoriesResponse.data.formTemplateCategories.filter((category) =>
            templatesResponse.data.formTemplates.some(
              (template) => template.category.id === category.id,
            ),
          );

        return of(
          csvExportFiltersLoaded(
            templatesResponse.data.formTemplates.map(
              (template): CsvExportFilter => ({
                id: template.id,
                name: template.name,
                parentCategoryId: template.category?.id,
                parentCategoryName: template.category?.name,
                status: template.status,
                updatedAt: template.updatedAt,
              }),
            ),
            filteredCategories,
          ),
        );
      } catch (err) {
        log.error(
          `GraphQL error occurred while trying to get templates and categories for CSV Export.`,
          err,
        );
        return of(
          showStatusBar(
            'ERROR',
            'Unable to load the form categories for a CSV Extract. Please try again.',
          ),
        );
      }
    }),
    concatAll(),
  );
}

function toolbarExportClickedWithFiltersEpic(
  action$: Observable<SheetToolbarDropdownClickAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_DROPDOWN_CLICK'),
    filter(
      ({ payload }) =>
        payload.itemId === 'DASHBOARD_EXPORT_SUMMARY' ||
        payload.itemId === 'DASHBOARD_EXPORT_DETAILS' ||
        payload.itemId === 'DASHBOARD_EXPORT_GOALS' ||
        payload.itemId === 'DASHBOARD_EXPORT_ACTIONS',
    ),
    map((action) => {
      const { selectedCustomDates, financialYearStartMonth } =
        state$.value.dashboardsV2.settings;

      const dateFilter = toDateFilter(state$.value);

      const sheetArgs: CsvExportSheetArgs = {
        entityId: action.payload.sheet.props.id,
        sheetType: action.payload.sheet.type,
      };

      const dataSetId = EXPORT_DATA_SETS[action.payload.itemId];

      const {
        startDate = moment().startOf('day').toISOString(),
        endDate = moment().endOf('day').toISOString(),
      } = dateRangeFromFilter(
        dateFilter.selection,
        financialYearStartMonth,
        selectedCustomDates,
      );

      return from([
        showModalDialog({
          content: <CsvDialogContainer />,
          width: 1400,
        }),
        loadCsvExportFilters(startDate, endDate, dataSetId, sheetArgs),
      ]);
    }),
    concatAll(),
  );
}

function showStatusBarOnDialogExportClickedEpic(
  action$: Observable<CsvExportConfirmedAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.shell/csvExport/CONFIRMED'),
    filter(() => Boolean(state$.value.csvExport.forSheet)),
    map(() =>
      from([
        showStatusBar(
          'INFO',
          'The CSV Extract is currently being generated. Once ready, it will automatically be downloaded by your browser. Note this may take several minutes.',
        ),
        hideModalDialog(),
      ]),
    ),
    concatAll(),
  );
}

function csvExportEpic(
  action$: Observable<CsvExportConfirmedAction>,
  state$: StateObservable<GlobalAppState>,
  { client }: GlobalAppEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.shell/csvExport/CONFIRMED'),
    filter(() => Boolean(state$.value.csvExport.forSheet)),
    mergeMap(async () => {
      const {
        startDate,
        endDate,
        forSheet,
        dataSetId,
        categories,
        selectedFilters,
        templates,
      } = state$.value.csvExport;
      const { entityId, sheetType } = forSheet;
      const entityType: 'Team' | 'Person' =
        sheetType === 'COACHING_DASHBOARD_TEAM' ? 'Team' : 'Person';
      const financialYearStartMonth =
        state$.value.dashboardsV2.settings.financialYearStartMonth;
      const templateId = state$.value.dashboardsV2.template.id;

      // Date filter for the custom range defined by the dialog to be used in the export.
      const exportDateFilter: DashboardDateFilterSelection = {
        financialYearStartMonth,
        selection: {
          kind: 'CUSTOM_RANGE',
          id: 'CUSTOM_EXPORT_RANGE',
          isDefault: false,
          toLabel: () => '',
        },
        selectedCustomDates: {
          endDateISO: endDate,
          startDateISO: startDate,
        },
      };

      // If the 'All' label is selected in the filters this trumps any additional filtering done by the user.
      const isAllSelected = selectedFilters.includes('ALL');
      const selectedFormTemplateIds = isAllSelected
        ? undefined
        : templates
            .filter((template) => selectedFilters.includes(template.id))
            .map((template) => template.id);
      const selectedFormCategoryIds = isAllSelected
        ? undefined
        : categories
            .filter((category) => selectedFilters.includes(category.id))
            .map((category) => category.id);

      if (!isAllSelected) {
        const selectedArchivedTemplates = getSelectedArchivedTemplateIds(
          selectedFilters,
          templates,
        );
        selectedFormTemplateIds.push(...selectedArchivedTemplates);
      }

      try {
        const response = await client.query<{
          dashboards: { csvExport: { exportJobId: string } };
        }>({
          query: gql`
            query CsvExport(
              $entityId: ID!
              $entityType: DashboardPersonOrTeam!
              $templateId: ID!
              $dataSetId: ID!
              $timespan: DashboardTimespanFilterInput
              $formCategoryIds: [ID!]
              $formTemplateIds: [ID!]
            ) {
              dashboards {
                csvExport(
                  entityId: $entityId
                  entityType: $entityType
                  templateId: $templateId
                  dataSetId: $dataSetId
                  timespan: $timespan
                  formCategoryIds: $formCategoryIds
                  formTemplateIds: $formTemplateIds
                ) {
                  exportJobId
                }
              }
            }
          `,
          variables: {
            entityId,
            entityType,
            templateId,
            dataSetId,
            formTemplateIds: selectedFormTemplateIds,
            formCategoryIds: selectedFormCategoryIds,
            timespan: toTimespanVariable(exportDateFilter),
          },
          fetchPolicy: 'network-only',
        });

        const exportJobId = response.data.dashboards.csvExport.exportJobId;

        const eventData = {
          endDate,
          dataSetId,
          selectedFilters,
          startDate,
          entityId,
          entityType,
        };

        return exportReadyStatusCheck(
          exportJobId,
          0,
          storageApi.module.getSessionStorage(),
          eventData,
        );
      } catch (error) {
        log.error(
          `Error occurred trying to export a Csv extract (${dataSetId}) for ${entityId} (${entityType})`,
          error,
        );
        return showStatusBar(
          'ERROR',
          'Unable to complete the CSV Extract. Please try again.',
        );
      }
    }),
  );
}

function csvExportReadyCheckEpic(
  action$: Observable<CsvExportReadyStatusCheckAction>,
  _: StateObservable<GlobalAppState>,
  { client }: GlobalAppEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.shell/csvExport/EXPORT_READY_STATUS_CHECK'),
    mergeMap(async (action) => {
      const module = action.payload.module;
      const exportJobId = action.payload.exportJobId;
      const retryCounter = action.payload.retryCounter + 1;
      try {
        const response = await client.query<{
          dashboards: {
            csvExportStatus: {
              status: 'Pending' | 'Completed' | 'Failed';
              downloadUrl?: string;
            };
          };
        }>({
          query: gql`
            query CsvExportStatus($exportJobId: ID!) {
              dashboards {
                csvExportStatus(exportJobId: $exportJobId) {
                  status
                  downloadUrl
                }
              }
            }
          `,
          variables: { exportJobId, module },
          fetchPolicy: 'network-only',
        });

        const status = response.data.dashboards.csvExportStatus.status;
        if (status === 'Pending') {
          // Shorter delay for the first few retries, then longer delay for subsequent retries.
          const delayTimeMs = retryCounter >= 10 ? 4000 : 1000;
          await delay(delayTimeMs);

          return exportReadyStatusCheck(
            exportJobId,
            retryCounter,
            module,
            action.payload._eventData,
          );
        }

        const downloadUrl =
          response.data.dashboards.csvExportStatus.downloadUrl;

        if (status === 'Failed')
          return exportReadyStatusCheckFailed(
            exportJobId,
            retryCounter,
            'FAILED_RESPONSE_CODE',
            action.payload._eventData,
          );

        if (!downloadUrl)
          return exportReadyStatusCheckFailed(
            exportJobId,
            retryCounter,
            'NO_DOWNLOAD_URL',
            action.payload._eventData,
          );

        return exportReadyStatusCheckCompleted(
          exportJobId,
          retryCounter,
          downloadUrl,
          action.payload._eventData,
        );
      } catch (error) {
        log.error(
          `Error occurred trying to poll status of Csv extract (${exportJobId})`,
          error,
        );
        return exportReadyStatusCheckFailed(
          exportJobId,
          retryCounter,
          'EXCEPTION',
          action.payload._eventData,
          error.message,
        );
      }
    }),
  );
}

function csvExportDownloadFileEpic(
  action$: Observable<CsvExportReadyStatusCheckCompletedAction>,
) {
  return action$.pipe(
    ofType('ui.shell/csvExport/EXPORT_READY_STATUS_CHECK_COMPLETED'),
    map((action) => {
      window.location.assign(action.payload.downloadUrl);
      return exportDone();
    }),
  );
}

function csvExportFailedEpic(
  action$: Observable<CsvExportReadyStatusCheckFailedAction>,
) {
  return action$.pipe(
    ofType('ui.shell/csvExport/EXPORT_READY_STATUS_CHECK_FAILED'),
    map(() =>
      showStatusBar(
        'ERROR',
        'Unable to complete the CSV Extract. Please try again.',
      ),
    ),
  );
}

const getSelectedArchivedTemplateIds = (
  selectedFilterIds: string[],
  templates: CsvExportFilter[],
) => {
  //If 'ARCHIVED' label selected, return all archived templates
  if (selectedFilterIds.includes('ARCHIVED')) {
    const allArchivedTemplates = templates
      .filter((template) => template.status === 'Archived')
      .map((template) => template.id);

    return allArchivedTemplates;
  }

  const selectedArchivedTemplates = [] as string[];

  const selectedArchivedCategories = selectedFilterIds.filter((filterId) =>
    filterId.startsWith('ARCHIVED-'),
  );

  selectedArchivedCategories.forEach((category) => {
    const categoryId = category.replace('ARCHIVED-', '');

    const selectedArchivedTemplatesForCategory = templates
      .filter(
        (template) =>
          template.status === 'Archived' &&
          template.parentCategoryId === categoryId,
      )
      .map((template) => template.id);

    selectedArchivedTemplates.push(...selectedArchivedTemplatesForCategory);
  });

  return selectedArchivedTemplates;
};
