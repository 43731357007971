import { goalsActionsGridSlice } from '@seeeverything/ui.forms/src/redux/goalsActionsGrid/index.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { value } from '@seeeverything/ui.util/src/value/index.ts';
import * as R from 'ramda';
import { StateObservable, combineEpics, ofType } from 'redux-observable';
import {
  Observable,
  Subject,
  distinctUntilChanged,
  filter,
  from,
  map,
  mergeMap,
  takeUntil,
} from 'rxjs';
import {
  IDropdown,
  IQuerySelection,
} from '../../api/api.queryBuilder/types.ts';
import { ShellAction, ShellGlobalState } from '../types.ts';
import {
  dropdownLoaded,
  hideDropdown,
  requestDropdown,
  showDropdown,
} from './actions.dropdown.ts';
import * as actions from './actions.ts';
import {
  QueryBuilderAction,
  QueryBuilderAutocompleteDropdownAction,
  QueryBuilderBlurAction,
  QueryBuilderDropdownInlineFilterClickedAction,
  QueryBuilderDropdownLoadedAction,
  QueryBuilderRequestQueryDropdownAction,
  QueryBuilderShowQueryDropdownAction,
  QueryDropdownState,
} from './types.ts';

const cancelLoad$ = new Subject<void>();

export const epics = combineEpics<ShellAction, ShellAction, ShellGlobalState>(
  autoCompleteEpic,
  focusInputWhenDropdownComponentChangedEpic,
  focusInputWhenDropdownLoadedEpic,
  hideOnBlurEpic,
  loadDataEpic,
  loadDropdownChangesOnInlineFilterClick,
  requestedDropdownEpic,
  updateGoalsActionsGridQueryPathOnFilterChangedEpic,
);

/**
 * When a request for a drop-down is made, ask the business-logic for
 * details about the dropdown to show (based on the current query state).
 */
function requestedDropdownEpic(
  action$: Observable<QueryBuilderRequestQueryDropdownAction>,
  state$: StateObservable<ShellGlobalState>,
) {
  return action$.pipe(
    ofType('ui.shell/query/DROPDOWN_REQUEST'),
    filter(() => Boolean(state$.value.query.focus)),
    map((action) => {
      const { data, query, selection } = action.payload;
      const response = data.change({
        action: 'DROPDOWN',
        query,
        selection,
      });
      return response && !response.isCancelled ? response.dropdown : undefined;
    }),
    filter((dropdown) =>
      isDropdownChanged(state$.value.query.dropdown, dropdown),
    ),
    map((dropdown) => (dropdown ? showDropdown(dropdown) : hideDropdown())),
  );
}

/**
 * When an inline filter is clicked (e.g. Include Inactive People), reload the dropdown.
 */
function loadDropdownChangesOnInlineFilterClick(
  action$: Observable<QueryBuilderDropdownInlineFilterClickedAction>,
) {
  return action$.pipe(
    ofType('ui.shell/query/DROPDOWN_INLINE_FILTER_CLICKED'),
    map((action) =>
      requestDropdown(
        action.payload.data,
        action.payload.query,
        action.payload.selection,
      ),
    ),
  );
}

/**
 * When a dropdown is shown, ensure it's data is loaded.
 */
function loadDataEpic(
  action$: Observable<QueryBuilderShowQueryDropdownAction>,
) {
  return action$.pipe(
    ofType('ui.shell/query/DROPDOWN_SHOW'),
    filter((action) => value.isPromise(action.payload.dropdown.props)),
    mergeMap((action) => {
      cancelLoad$.next(null); // Cancel any prior load operation.
      return from(action.payload.dropdown.props).pipe(
        takeUntil(cancelLoad$),
        map((data) => dropdownLoaded(data)),
      );
    }),
  );
}

/**
 * Hide dropdown if QB is blurred and a drop-down is present.
 */
function hideOnBlurEpic(
  action$: Observable<QueryBuilderBlurAction>,
  state$: StateObservable<ShellGlobalState>,
) {
  return action$.pipe(
    ofType('ui.shell/query/BLUR'),
    filter(() => Boolean(state$.value.query.dropdown)),
    map(() => hideDropdown()),
  );
}

/**
 * When a visible dropdown changes it's [component] type
 * ensure focus is returned to the INPUT.
 */
function focusInputWhenDropdownComponentChangedEpic(
  action$: Observable<QueryBuilderShowQueryDropdownAction>,
  state$: StateObservable<ShellGlobalState>,
) {
  return action$.pipe(
    ofType('ui.shell/query/DROPDOWN_SHOW'),
    distinctUntilChanged((prev, next) =>
      isDropdownComponentUnchanged(
        prev.payload.dropdown,
        next.payload.dropdown,
      ),
    ),
    filter(() => state$.value.query.focus !== 'INPUT'),
    map(() =>
      // The dropdown has changed type,
      // ensure focus is returned to the text Input.
      actions.focus('INPUT'),
    ),
  );
}

/**
 * When a dropdown with async props is loaded,
 * ensure the dropdown with loaded items is focused.
 */
function focusInputWhenDropdownLoadedEpic(
  action$: Observable<QueryBuilderDropdownLoadedAction>,
  state$: StateObservable<ShellGlobalState>,
) {
  return action$.pipe(
    ofType('ui.shell/query/DROPDOWN_LOADED'),
    filter(() => state$.value.query.focus !== 'DROPDOWN'),
    map(() => actions.focus('DROPDOWN')),
  );
}

/**
 * When an auto-complete for a dropdown is requested,
 * retrieve the data from the business logic and
 * load as a new query.
 */
function autoCompleteEpic(
  action$: Observable<QueryBuilderAutocompleteDropdownAction>,
) {
  return action$.pipe(
    ofType('ui.shell/query/DROPDOWN_AUTOCOMPLETE'),
    mergeMap((action) => {
      const result: QueryBuilderAction[] = [];
      const { query, editingChip } = action.payload;

      if (query) {
        // Setup the selection if a chip is being edited.
        const chipIndex = editingChip ? editingChip.index : -1;
        const chip = query.chips[chipIndex];
        const selection =
          chip &&
          ({
            type: 'EDITING',
            chipIndex,
            chip,
          } as IQuerySelection);
        const isEditing = selection?.type === 'EDITING';

        // Add the next query.
        result.push(actions.next('CODE', query, selection));

        // Focus the text-input if not editing.
        if (!isEditing) {
          result.push(actions.focus('INPUT'));
        }
      }

      return from(R.reject(R.isNil, result));
    }),
  );
}

function updateGoalsActionsGridQueryPathOnFilterChangedEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<ShellGlobalState>,
) {
  return action$.pipe(
    filter(goalsActionsGridSlice.updateFilter.match),
    filter(() =>
      state$.value.query.query.chips.some((c) => c.type === 'goalsActionsGrid'),
    ),
    map((action) => {
      const chips = state$.value.query.query.chips.map((c) => {
        if (c.type !== 'goalsActionsGrid') return c;

        const [entityType, entityId, startDate, endDate, dateRangeLabel] =
          c.value.split('|');

        return {
          ...c,
          value: [
            entityType,
            entityId,
            startDate,
            endDate,
            dateRangeLabel,
            action.payload.to,
          ].join('|'),
        };
      });

      return actions.next('CODE', { chips, filter: '' });
    }),
  );
}

const isDropdownComponentUnchanged = (
  prev: QueryDropdownState,
  next: QueryDropdownState,
) => prev?.component === next?.component;

function isDropdownChanged(prev?: IDropdown, next?: IDropdown) {
  if ((prev && !next) || (!prev && next)) {
    return true;
  }

  return Boolean(prev && next && !R.equals(prev, next));
}
