/** @jsxImportSource @emotion/react */
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LabelButton } from '@seeeverything/ui.primitives/src/components/LabelButton/LabelButton.tsx';
import { Text } from '../Text/Text.tsx';

export type AlertDialogProps = {
  title: string;
  bodyText: string;
  onConfirmClick: () => void;
  onCancelClick: () => void;
  isVisible: boolean;
};

export const AlertDialog: React.FC<AlertDialogProps> = ({
  bodyText,
  isVisible,
  onConfirmClick,
  onCancelClick,
  title,
}) => (
  <Dialog open={isVisible}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Text>{bodyText}</Text>
    </DialogContent>
    <DialogActions>
      <LabelButton
        label={'Cancel'}
        onClick={onCancelClick}
        backgroundAlways={true}
      />
      <LabelButton
        label={'OK'}
        onClick={onConfirmClick}
        backgroundAlways={true}
      />
    </DialogActions>
  </Dialog>
);
