/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { Checkbox } from '@seeeverything/ui.primitives/src/components/Checkbox/Checkbox.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import React from 'react';
import { Bullet } from '../Bullet/index.ts';
import { DesignerChangeEventHandler } from '../types.ts';
import { handleChangeEvent } from '../util.ts';

export type SwitchProps = {
  descriptionToDisable: string;
  descriptionToEnable: string;
  editingLabel: string;
  id: string;
  isEnabled?: boolean;
  onChange?: DesignerChangeEventHandler;
  showBoundaryLines?: boolean;
  showBullet?: boolean;
  style?: SerializedStyles;
};

export const Switch: React.FC<SwitchProps> = ({
  descriptionToDisable,
  descriptionToEnable,
  editingLabel,
  id,
  isEnabled = true,
  onChange,
  showBoundaryLines,
  showBullet = true,
  style,
}) => {
  const computedStyles = {
    base: css({
      border: showBoundaryLines
        ? `dashed 1px ${color.format(-0.2)}`
        : 'solid 1px rgba(0, 0, 0, 0)',
      borderRadius: showBoundaryLines ? 8 : undefined,
    }),
  };

  return (
    <div css={[styles.outer, style]}>
      {showBullet && <Bullet />}
      <div css={[styles.base, computedStyles.base]}>
        <div css={styles.content}>
          <div css={styles.editingContent}>
            <Checkbox
              label={editingLabel}
              isChecked={isEnabled === true}
              isEnabled={true}
              helperText={
                isEnabled ? descriptionToDisable : descriptionToEnable
              }
              style={styles.checkbox}
              onCheck={handleChangeEvent(id, 'SWITCH', onChange)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  outer: css({
    position: 'relative',
  }),
  base: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 60,
    padding: '8px 20px 6px 40px',
    marginBottom: 5,
  }),
  content: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  }),
  editingContent: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  checkbox: css({
    margin: '0 -3px',
  }),
};
