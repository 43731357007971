/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { DesignerSection } from '../DesignerSection/DesignerSection.tsx';
import { Switch } from '../Switch/Switch.tsx';
import { IDesignerCommonProps } from '../types.ts';
import { getValidationError } from '../util.ts';

export interface IDesignerFileUploadProps extends IDesignerCommonProps {
  title?: string;
  isEnabled?: boolean;
}

export class DesignerFileUpload extends React.Component<
  IDesignerFileUploadProps,
  object
> {
  public render() {
    const {
      title,
      isEnabled,
      showBoundaryLines,
      showBullet = true,
      style,
      onChange,
      onEventOccurred,
      validationError,
    } = this.props;

    return (
      <div css={[styles.outer, style]}>
        <DesignerSection
          id={'file-upload-title'}
          canDelete={false}
          showBoundaryLines={showBoundaryLines}
          title={title}
          showBullet={showBullet}
          onChange={onChange}
          onEventOccurred={onEventOccurred}
          error={getValidationError(validationError?.fields, 'title')}
        />
        <Switch
          id={'file-upload-enabled'}
          isEnabled={isEnabled}
          showBoundaryLines={showBoundaryLines}
          descriptionToEnable={'Enables document upload.'}
          descriptionToDisable={'Enables document upload.'}
          editingLabel={'Enabled'}
          onChange={onChange}
          showBullet={showBullet}
        />
      </div>
    );
  }
}

const styles = {
  outer: css({
    position: 'relative',
  }),
};
