import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';

type GridLabelArgs = {
  actionLabel: string;
  dateRangeLabel: string;
  module: ModuleType;
};
export const goalsActionsGridLabel = ({
  actionLabel,
  dateRangeLabel,
  module,
}: GridLabelArgs) => {
  const actionsPart = str.titleCase(str.plural(actionLabel));
  return module === 'coaching'
    ? `Goals and ${actionsPart} - ${dateRangeLabel}`
    : `${actionsPart} - ${dateRangeLabel}`;
};
