/** @jsxImportSource @emotion/react */
import {
  COLUMNS,
  COLUMNS_NO_TYPE,
  GoalsActionsGridContainer,
} from '@seeeverything/ui.forms/src/components/GoalsActionsGrid/GoalsActionsGridContainer.tsx';
import { goalsActionsGridSlice } from '@seeeverything/ui.forms/src/redux/goalsActionsGrid/index.ts';
import { goalsActionsGridLabel } from '@seeeverything/ui.forms/src/util/goalsActions.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { useOnce } from '@seeeverything/ui.primitives/src/hooks/useOnce.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { useCallback, useMemo } from 'react';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import { SheetLayout } from '../SheetLayout/index.ts';

export type SheetGoalsActionsGridContainerProps = {
  entityId: string;
  entityType: 'PERSON' | 'TEAM';
  startDate: string;
  endDate: string;
  dateRangeLabel: string;
  initialFilter?: 'Open' | 'All';
};

export const SheetGoalsActionsGridContainer: React.FC<
  SheetGoalsActionsGridContainerProps
> = ({
  entityId,
  entityType,
  startDate,
  endDate,
  dateRangeLabel,
  initialFilter,
}) => {
  const dispatch = useShellDispatch();

  useOnce(() => {
    if (initialFilter)
      dispatch(goalsActionsGridSlice.updateFilter({ to: initialFilter }));
  });

  const module = useShellSelector((state) => state.tenantState.tenant.module);

  const columns = useMemo(
    () => (module !== 'coaching' ? COLUMNS_NO_TYPE : COLUMNS),
    [module],
  );
  const actionLabel = useShellSelector((state) =>
    str.titleCase(state.tenantState.tenant.locale.label.action),
  );

  const dueByDirection = useShellSelector(
    (state) => state.formGoalActionsGrid.orderDueByDirection,
  );

  const issueActionLabel = useShellSelector((state) =>
    str.titleCase(state.tenantState.tenant.locale.label.issueAction),
  );

  const orderBy = useShellSelector(
    (state) => state.formGoalActionsGrid.orderBy,
  );

  const filter = useShellSelector((state) => state.formGoalActionsGrid.filter);

  const downloadSpreadsheet = useCallback(
    () =>
      dispatch(
        goalsActionsGridSlice.downloadGrid({
          columnHeaderLabels: columns.map(({ label }) => label),
          queryArgs: {
            key: 'goalsAndActions',
            actionLabel,
            dueByDirection,
            endDate,
            entityId,
            entityType,
            issueActionLabel,
            module,
            orderBy,
            sheetType: 'FULL_SHEET',
            showCompleted: filter === 'All',
            spreadsheetDownload: true,
            startDate,
          },
          title: goalsActionsGridLabel({
            module,
            dateRangeLabel,
            actionLabel,
          }),
        }),
      ),
    [
      actionLabel,
      columns,
      dateRangeLabel,
      dispatch,
      dueByDirection,
      endDate,
      entityId,
      entityType,
      filter,
      issueActionLabel,
      module,
      orderBy,
      startDate,
    ],
  );

  const chips = useShellSelector((state) => state.query.query.chips);
  const title = useMemo(() => {
    if (!chips?.length) return;
    return chips[chips.length - 1]?.label;
  }, [chips]);

  const goalsAndActionsLabel =
    module === 'coaching'
      ? `goals and ${str.plural(actionLabel).toLowerCase()}`
      : str.plural(actionLabel).toLowerCase();

  return (
    <SheetLayout
      rightTools={[
        {
          id: 'Show Open',
          icon: Icons.list,
          label: 'Show Open',
          onClick: () =>
            dispatch(goalsActionsGridSlice.updateFilter({ to: 'Open' })),
          tooltip: `Excludes completed ${goalsAndActionsLabel}.`,
          isSelected: filter === 'Open',
        },
        {
          id: 'Show All',
          icon: Icons.list,
          label: 'Show All',
          onClick: () =>
            dispatch(goalsActionsGridSlice.updateFilter({ to: 'All' })),
          tooltip: `Includes completed ${goalsAndActionsLabel}.`,
          isSelected: filter === 'All',
        },
        {
          id: 'DOWNLOAD_GRID',
          icon: Icons.cloudDownload,
          onClick: downloadSpreadsheet,
          tooltip: 'Download spreadsheet',
        },
      ]}
      title={title}
    >
      <GoalsActionsGridContainer
        entityId={entityId}
        entityType={entityType}
        startDate={startDate}
        endDate={endDate}
        dateRangeLabel={dateRangeLabel}
        inViewport={true}
        sheetType={'FULL_SHEET'}
      />
    </SheetLayout>
  );
};
