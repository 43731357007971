/** @jsxImportSource @emotion/react */
import { Accordion } from '@seeeverything/ui.primitives/src/components/Accordion/Accordion.tsx';
import { AccordionDetails } from '@seeeverything/ui.primitives/src/components/Accordion/AccordionDetails.tsx';
import { AccordionSummary } from '@seeeverything/ui.primitives/src/components/Accordion/AccordionSummary.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useLocalStorage } from '@seeeverything/ui.primitives/src/hooks/useLocalStorage.ts';
import { StorageKey } from '@seeeverything/ui.util/src/storage/api.ts';

export const DebugSection: React.FC<{
  children: React.ReactNode;
  id: string;
  title: string;
}> = ({ children, id, title }) => {
  const storageKey = [StorageKey.Debug, id].join('/');
  const [showing, setShowing] = useLocalStorage(storageKey, true);

  return (
    <Accordion
      id={id}
      isExpanded={showing}
      hideDividers={true}
      onChange={(e) => setShowing(e.isExpanded)}
    >
      <AccordionSummary>
        <Text selectable={false}>{title}</Text>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
