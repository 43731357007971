/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';
import { Text } from '../Text/Text.tsx';
import { TextLink } from '../TextLink/TextLink.tsx';
import { IDataGridProps } from './DataGrid.tsx';

export interface IDataGridClickThroughProps {
  clickThroughColumnIds?: string[];
  onClickThroughColumnClick?: (rowId: string, columnId: string) => void;
}

export const DataGridClickThroughBehavior = (
  DataGrid: React.FC<IDataGridProps>,
): React.FC<IDataGridClickThroughProps & IDataGridProps> =>
  function DataGridClickThrough({
    columns,
    clickThroughColumnIds,
    onClickThroughColumnClick,
    ...rest
  }) {
    return (
      <DataGrid
        {...rest}
        columns={columns}
        renderCellContents={({ columnIndex, row }) => {
          const column = columns[columnIndex];
          const isClickThrough =
            row.canClickThrough !== false &&
            clickThroughColumnIds?.some((id) => id === column.id) &&
            row.data[columnIndex];
          const TextComponent = isClickThrough ? TextLink : Text;
          const handleClick = () =>
            isClickThrough &&
            onClickThroughColumnClick?.(row.id.toString(), column.id);

          const computedStyle = css({
            width: '100%',
            color: isClickThrough ? COLORS.BLUE : color.format(-0.5),
            textAlign: column.align,
            ':hover': {
              textDecoration: isClickThrough ? 'underline' : undefined,
            },
          });

          return (
            <TextComponent
              size={14}
              ellipsis={true}
              onClick={handleClick}
              style={css(computedStyle)}
            >
              {row.data[columnIndex]}
            </TextComponent>
          );
        }}
      />
    );
  };
