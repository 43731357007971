import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import { IDashboardHeader } from '../types.ts';
import {
  DashboardV2ReduxAction,
  IDashboardComponentsState,
  IDashboardUserSettings,
  IDashboardV2ComponentState,
} from './types.ts';

const DEFAULT_COMPONENTS: IDashboardComponentsState = {
  CHART: {},
  CHART_TOOLS: {},
  CLICK_THROUGH_GRID: {},
  COMMENTS_LIST: {},
  DIGITAL_CONTENT: {},
  GOALS_AND_ACTIONS: {},
  GRID: {},
  GRID_TOOLS: {},
  SECTION: {},
  ISSUES_AND_INSIGHTS: {},
};

const DEFAULT_STATE: IDashboardV2ComponentState = {
  ...DEFAULT_COMPONENTS,
  dropdowns: { digitalContentPacks: { isLoading: false, items: [] } },
  settings: {
    selectedGridTools: {
      coaching: {},
      compliance: {},
      cadence: {},
    },
    selectedChartTools: {
      coaching: {},
      compliance: {},
      cadence: {},
    },
  },
  scrollToPosition: '',
  cachedComponents: {},
};

export function reducer(
  state: IDashboardV2ComponentState = DEFAULT_STATE,
  action: DashboardV2ReduxAction,
): IDashboardV2ComponentState {
  switch (action.type) {
    case 'ui.dashboards/dashboard/INITIALIZE': {
      return {
        ...DEFAULT_STATE,
        HEADER: state.HEADER,
        settings: state.settings,
        CLICK_THROUGH_GRID: action.payload.clickThroughGrid
          ? {
              [action.payload.clickThroughGrid.id]:
                action.payload.clickThroughGrid,
            }
          : {},
        scrollToPosition: state.scrollToPosition,
        cachedComponents: state.cachedComponents,
      };
    }

    case 'ui.dashboards/dashboard/ERROR':
      return {
        ...state,
        error: action.payload.error,
      };

    case 'ui.dashboards/dashboard/COMPONENT/INITIALIZE': {
      const { componentType, id, component, cacheWithTemplateId } =
        action.payload;
      if (componentType === 'HEADER') {
        const headerComponent = component as IDashboardHeader;

        const headerState = { ...state.HEADER, ...headerComponent };

        const cacheUpdate = cacheWithTemplateId
          ? {
              [cacheWithTemplateId]: {
                ...state.cachedComponents[cacheWithTemplateId],
                HEADER: headerState,
              },
            }
          : {};

        return {
          ...state,
          HEADER: headerState,
          cachedComponents: {
            ...state.cachedComponents,
            ...cacheUpdate,
          },
          settings: {
            ...state.settings,
            selectedDateFilterId: getDefaultDateFilterId(
              headerComponent,
              state.settings,
            ),
          },
        };
      }

      const componentState = {
        [id]: {
          ...component,
          dataState: {
            ...component.dataState,
            isLoaded: false,
            isLoading: false,
          },
        },
      };

      const cacheUpdate =
        cacheWithTemplateId && state.cachedComponents[cacheWithTemplateId]
          ? {
              [cacheWithTemplateId]: {
                ...state.cachedComponents[cacheWithTemplateId],
                [componentType]: {
                  ...state.cachedComponents[cacheWithTemplateId][componentType],
                  ...componentState,
                },
              },
            }
          : {};

      return {
        ...state,
        [componentType]: {
          ...state[componentType],
          ...componentState,
        },
        cachedComponents: {
          ...state.cachedComponents,
          ...cacheUpdate,
        },
      };
    }

    case 'ui.dashboards/dashboard/TEMPLATE_LOADED': {
      return {
        ...state,
        template: action.payload.template,
      };
    }

    case 'ui.dashboards/dashboard/CACHE/CACHE_TEMPLATE': {
      const { template } = action.payload;
      return {
        ...state,
        cachedComponents: {
          ...state.cachedComponents,
          [template.id]: {
            template,
            ...DEFAULT_COMPONENTS,
          },
        },
      };
    }

    case 'ui.dashboards/dashboard/CACHE/LOAD_COMPONENTS': {
      const { templateId, entityId, entity, module } = action.payload;
      const cache = state.cachedComponents[templateId];

      let nextState: IDashboardV2ComponentState = {
        ...state,
        ...cache,
        template:
          cache.template.kind === 'team'
            ? { ...cache.template, entityId, entity }
            : { ...cache.template, entityId },
        settings: {
          ...state.settings,
          selectedDateFilterId: getDefaultDateFilterId(
            cache.HEADER,
            state.settings,
          ),
        },
      };

      nextState = initializeGridTools(nextState, module);
      nextState = initializeChartTools(nextState, module);

      return nextState;
    }

    case 'ui.dashboards/dashboard/COMPONENT/ERROR': {
      if (action.payload.componentType === 'HEADER') return state;

      const { id, componentType, error } = action.payload;

      if (!state[componentType][id]) return state;

      return {
        ...state,
        [componentType]: {
          ...state[componentType],
          [id]: {
            ...state[componentType][id],
            dataState: {
              ...state[componentType][id]?.dataState,
              isLoading: false,
              error,
            },
          },
        },
      };
    }

    case 'ui.dashboards/dashboard/DIGITAL_CONTENT/FILTER_CHANGED': {
      const { id, selectedContentPack } = action.payload;

      if (!selectedContentPack) {
        return {
          ...state,
          DIGITAL_CONTENT: {
            ...state.DIGITAL_CONTENT,
            [id]: {
              ...state.DIGITAL_CONTENT[id],
              dataState: {
                ...state.DIGITAL_CONTENT[id].dataState,
                isLoaded: true,
                isLoading: false,
              },
            },
          },
        };
      }

      return {
        ...state,
        DIGITAL_CONTENT: {
          ...state.DIGITAL_CONTENT,
          [id]: {
            ...state.DIGITAL_CONTENT[id],
            packSelector: {
              ...state.DIGITAL_CONTENT[id]?.packSelector,
              selectedContentPack,
            },
            dataState: {
              ...state.DIGITAL_CONTENT[id].dataState,
              isLoading: true,
            },
          },
        },
      };
    }
    case 'ui.dashboards/dashboard/DIGITAL_CONTENT/LOAD': {
      const id = action.payload.id;
      return {
        ...state,
        DIGITAL_CONTENT: {
          ...state.DIGITAL_CONTENT,
          [id]: {
            ...state.DIGITAL_CONTENT[id],
            dataState: {
              ...state.DIGITAL_CONTENT[id].dataState,
              forceReload: false,
            },
          },
        },
      };
    }

    case 'ui.dashboards/dashboard/COMPONENT/LOAD': {
      const { id, componentType, loadMore } = action.payload;

      if (componentType === 'HEADER') return state;
      if (componentType === 'GRID') return state;
      if (componentType === 'CLICK_THROUGH_GRID') return state;

      const componentTypeState = state[componentType];
      if (!componentTypeState) return state;

      const componentState = componentTypeState[id];
      if (!componentState) return state;

      return {
        ...state,
        [componentType]: {
          ...componentTypeState,
          [id]: {
            ...componentState,
            dataState: {
              ...componentState.dataState,
              isLoading: !loadMore,
              forceReload: false,
            },
            isLoadingPage: loadMore,
          },
        },
      };
    }

    case 'ui.dashboards/dashboard/COMPONENT/LOADED': {
      if (action.payload.componentType === 'HEADER') {
        return state;
      }

      if (
        !state[action.payload.componentType]?.[action.payload.id]?.dataState
      ) {
        return state;
      }

      const currentData =
        state[action.payload.componentType][action.payload.id].dataState.data;
      const nextData =
        action.payload.shouldAppendData && currentData && action.payload.data
          ? {
              ...currentData,
              ...action.payload.data,
              data: [...currentData.data, ...action.payload.data.data],
            }
          : action.payload.data;

      return {
        ...state,
        [action.payload.componentType]: {
          ...state[action.payload.componentType],
          [action.payload.id]: {
            ...state[action.payload.componentType][action.payload.id],
            dataState: {
              ...state[action.payload.componentType][action.payload.id]
                .dataState,
              isLoading: false,
              isLoaded: true,
              error: undefined,
              data: nextData,
            },
            isLoadingPage: false,
          },
        },
      };
    }

    case 'ui.dashboards/dashboard/HEADER/LOAD':
      if (!state.HEADER) {
        return state;
      }

      return {
        ...state,
        HEADER: {
          ...state.HEADER,
          dataState: {
            ...state.HEADER.dataState,
            isLoading: true,
          },
        },
      };

    case 'ui.dashboards/dashboard/HEADER/LOADED':
      if (!state.HEADER) {
        return state;
      }

      return {
        ...state,
        HEADER: {
          ...state.HEADER,
          dataState: {
            ...state.HEADER.dataState,
            data: action.payload.data,
            isLoading: false,
            isLoaded: true,
            error: undefined,
          },
        },
      };

    case 'ui.dashboards/dashboard/GRID_TOOLS/INITIALIZE':
      return initializeGridTools(state, action.payload.module);

    case 'ui.dashboards/dashboard/GRID_TOOLS/TOGGLE': {
      const { toolId, buttonId, module } = action.payload;
      return gridToggle(state, toolId, buttonId, module, true);
    }

    case 'ui.dashboards/dashboard/CHART_TOOLS/INITIALIZE':
      return initializeChartTools(state, action.payload.module);

    case 'ui.dashboards/dashboard/CHART_TOOLS/TOGGLE': {
      const { toolId, buttonId, module } = action.payload;
      return chartToggle(state, toolId, buttonId, module, true);
    }

    case 'ui.dashboards/dashboard/HEADER/CLEAR_DATE_FILTER': {
      return {
        ...state,
        settings: {
          ...state.settings,
          selectedDateFilterId: undefined,
          selectedCustomDates: undefined,
        },
      };
    }

    case 'ui.dashboards/dashboard/HEADER/SET_DATE_FILTER': {
      const dateFilterId = action.payload.itemId;
      return {
        ...state,
        ...forceReloadAllData(state),
        settings: {
          ...state.settings,
          selectedDateFilterId: dateFilterId,
          selectedCustomDates:
            dateFilterId !== 'CUSTOM_RANGE'
              ? undefined
              : state.settings.selectedCustomDates,
        },
      };
    }

    case 'ui.dashboards/dashboard/HEADER/SET_CUSTOM_DATE_RANGE':
      return {
        ...state,
        ...forceReloadAllData(state),
        settings: {
          ...state.settings,
          selectedCustomDates: {
            startDateISO: action.payload.startDateISO,
            endDateISO: action.payload.endDateISO,
          },
        },
      };

    case 'ui.dashboards/dashboard/HEADER/SET_FINANCIAL_YEAR_START_MONTH':
      return {
        ...state,
        settings: {
          ...state.settings,
          financialYearStartMonth: action.payload.financialYearStartMonth,
        },
      };

    case 'ui.dashboards/dashboard/GRID/CLICK_THROUGH_GRID_INITIALIZED':
      return {
        ...state,
        CLICK_THROUGH_GRID: {
          [action.payload.clickThroughGrid.id]:
            state.CLICK_THROUGH_GRID[action.payload.clickThroughGrid.id],
        },
        GRID: {},
        CHART: {},
        CHART_TOOLS: {},
        GRID_TOOLS: {},
        SECTION: {},
        COMMENTS_LIST: {},
      };

    case 'ui.dashboards/dashboard/SCROLL/REMEMBER_POSITION':
      return {
        ...state,
        scrollToPosition: action.payload.componentId,
      };

    case 'ui.dashboards/dashboard/SCROLL/CLEAR_REMEMBERED_POSITION':
      return {
        ...state,
        scrollToPosition: '',
      };

    case 'ui.dashboards/dashboard/DIGITAL_CONTENT/LOAD_CONTENT_PACKS_PAGE': {
      return {
        ...state,
        dropdowns: { digitalContentPacks: { isLoading: true, items: [] } },
      };
    }

    case 'ui.dashboards/dashboard/DIGITAL_CONTENT/LOADED_CONTENT_PACKS_PAGE': {
      return {
        ...state,
        dropdowns: {
          digitalContentPacks: {
            isLoading: false,
            items: action.payload.items,
          },
        },
      };
    }

    default:
      return state;
  }
}

const forceReloadAllData = (state: IDashboardV2ComponentState) => {
  const { CHART, HEADER, DIGITAL_CONTENT } = state;

  const reloadChart = (
    chartKey: string,
  ): IDashboardComponentsState['CHART'] => ({
    [chartKey]: {
      ...CHART[chartKey],
      dataState: {
        ...CHART[chartKey].dataState,
        error: undefined,
        isLoaded: false,
        isLoading: false,
        forceReload: true,
      },
    },
  });

  const reloadDigitalContent = (
    key: string,
  ): IDashboardComponentsState['DIGITAL_CONTENT'] => ({
    [key]: {
      ...DIGITAL_CONTENT[key],
      dataState: {
        ...DIGITAL_CONTENT[key].dataState,
        error: undefined,
        isLoaded: false,
        isLoading: false,
        forceReload: true,
      },
    },
  });

  const newCharts =
    CHART &&
    Object.keys(CHART).reduce(
      (acc, chartKey) => ({ ...acc, ...reloadChart(chartKey) }),
      {},
    );

  const newDigitalContent =
    DIGITAL_CONTENT &&
    Object.keys(DIGITAL_CONTENT).reduce(
      (acc, contentKey) => ({ ...acc, ...reloadDigitalContent(contentKey) }),
      {},
    );

  const newHeader: IDashboardHeader = HEADER && {
    ...HEADER,
    dataState: {
      ...HEADER.dataState,
      isLoaded: false,
      isLoading: true,
      error: undefined,
    },
  };

  return {
    ...state,
    CHART: newCharts,
    DIGITAL_CONTENT: newDigitalContent,
    HEADER: newHeader,
  };
};

const gridToggle = (
  state: IDashboardV2ComponentState,
  toolId: string,
  buttonId: string,
  module: ModuleType,
  retainSelection: boolean,
): IDashboardV2ComponentState => {
  if (!state.GRID_TOOLS) return state;

  const selectedButton = state.GRID_TOOLS[toolId].toolButtons.find(
    (button) => button.id === buttonId,
  );
  if (!selectedButton) return state;

  const settings = retainSelection
    ? {
        ...state.settings,
        selectedGridTools: {
          ...state.settings.selectedGridTools,
          [module]: {
            ...state.settings.selectedGridTools[module],
            [state.template.entityId]: {
              ...state.settings.selectedGridTools[module][
                state.template.entityId
              ],
              [toolId]: buttonId,
            },
          },
        },
      }
    : state.settings;

  return {
    ...state,
    settings,
    GRID_TOOLS: {
      ...state.GRID_TOOLS,
      [toolId]: {
        ...state.GRID_TOOLS[toolId],
        toolButtons: state.GRID_TOOLS[toolId].toolButtons.map((button) => ({
          ...button,
          isSelected: button.id === buttonId,
        })),
      },
    },
    GRID: {
      ...state.GRID,
      ...Object.entries(state.GRID).reduce(
        (acc, [gridId, grid]) => ({
          ...acc,
          [gridId]:
            grid.group !== selectedButton.selectionGroup
              ? grid
              : {
                  ...grid,
                  isHidden: grid.id !== selectedButton.toolId,
                },
        }),
        {},
      ),
    },
  };
};

const chartToggle = (
  state: IDashboardV2ComponentState,
  toolId: string,
  buttonId: string,
  module: ModuleType,
  retainSelection: boolean,
): IDashboardV2ComponentState => {
  if (!state.CHART_TOOLS) return state;

  const selectedButton = state.CHART_TOOLS[toolId].toolButtons.find(
    (button) => button.id === buttonId,
  );
  if (!selectedButton) return state;

  const settings = retainSelection
    ? {
        ...state.settings,
        selectedChartTools: {
          ...state.settings.selectedChartTools,
          [module]: {
            ...state.settings.selectedChartTools[module],
            [state.template.entityId]: {
              ...state.settings.selectedChartTools[module][
                state.template.entityId
              ],
              [toolId]: buttonId,
            },
          },
        },
      }
    : state.settings;

  return {
    ...state,
    settings,
    CHART_TOOLS: {
      ...state.CHART_TOOLS,
      [toolId]: {
        ...state.CHART_TOOLS[toolId],
        toolButtons: state.CHART_TOOLS[toolId].toolButtons.map((button) => ({
          ...button,
          isSelected: button.id === buttonId,
        })),
      },
    },
    CHART: {
      ...state.CHART,
      ...Object.entries(state.CHART).reduce(
        (acc, [chartId, chart]) => ({
          ...acc,
          [chartId]:
            chart.group !== selectedButton.selectionGroup
              ? chart
              : {
                  ...chart,
                  isHidden: chart.id !== selectedButton.toolId,
                },
        }),
        {},
      ),
    },
  };
};

const initializeChartTools = (
  state: IDashboardV2ComponentState,
  module: ModuleType,
): IDashboardV2ComponentState => {
  if (!state.CHART_TOOLS) return state;

  const entityId = state.template?.entityId;

  return Object.entries(
    state['CHART_TOOLS'],
  ).reduce<IDashboardV2ComponentState>((acc, [toolId, tool]) => {
    const defaultButton = tool.toolButtons.find(
      (button) => button.isSelected === true,
    );

    if (!defaultButton) return acc;

    const userSelectedToolId =
      state.settings.selectedChartTools[module][entityId]?.[toolId];

    const initialButtonId = userSelectedToolId ?? defaultButton.id;

    return {
      ...acc,
      ...chartToggle(acc, toolId, initialButtonId, module, false),
    };
  }, state);
};

const initializeGridTools = (
  state: IDashboardV2ComponentState,
  module: ModuleType,
): IDashboardV2ComponentState => {
  if (!state.GRID_TOOLS) return state;

  const entityId = state.template?.entityId;
  const team =
    state.template.kind === 'team' ? state.template?.entity : undefined;

  return Object.entries(state.GRID_TOOLS).reduce<IDashboardV2ComponentState>(
    (acc, [toolId, tool]) => {
      const defaultButton = tool.toolButtons.find(
        (button) => button.isSelected === true,
      );

      if (!defaultButton) return acc;

      const userSelectedToolId =
        state.settings.selectedGridTools[module][entityId]?.[toolId];

      const fallbackButton =
        team?.hasChildTeams === false && defaultButton.fallbackToolId
          ? tool.toolButtons.find(
              (button) => defaultButton.fallbackToolId === button.id,
            )
          : undefined;

      const initialButtonId =
        userSelectedToolId ?? fallbackButton?.id ?? defaultButton.id;

      return {
        ...acc,
        ...gridToggle(acc, toolId, initialButtonId, module, false),
      };
    },
    state,
  );
};

const getDefaultDateFilterId = (
  header?: IDashboardHeader,
  settings?: IDashboardUserSettings,
) => {
  if (!header) return undefined;

  const isDateFilterSelected = header.dateFilters?.some(
    (headerDateFilter) =>
      headerDateFilter.id === settings?.selectedDateFilterId,
  );
  return isDateFilterSelected
    ? settings?.selectedDateFilterId
    : header.defaultDateFilter?.id;
};
