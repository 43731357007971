/** @jsxImportSource @emotion/react */
import { css, Interpolation, SerializedStyles, Theme } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { Icons } from '../Icon/Icons.tsx';

export type IconWrapperProps = {
  icon?: string;
  children: React.ReactNode;
  style?: Interpolation<Theme>;
  iconStyle?: SerializedStyles;
  contentStyle?: SerializedStyles;
};

export const IconWrapper: React.FC<IconWrapperProps> = ({
  icon,
  children,
  style,
  iconStyle,
  contentStyle,
}) => {
  const Icon = Icons[icon];
  return (
    <div css={[styles.base, style]}>
      {Icon && (
        <div css={[styles.icon, iconStyle]}>
          <Icon fill={color.format(-0.3)} />
        </div>
      )}
      <div css={[styles.contentOuter, contentStyle]}>{children}</div>
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 auto',
    alignItems: 'flex-start',
    marginBottom: 6,
    gap: 10,
  }),
  icon: css({
    paddingTop: 20,
  }),
  contentOuter: css({
    display: 'flex',
    flex: '1 1 auto',
  }),
};
