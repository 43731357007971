/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Skeleton } from '../../Skeleton/Skeleton.tsx';

/**
 * Loading Skeleton for a <TreeView />.
 */
export const TreeViewSkeleton: React.FC = () => (
  <>
    <Skeleton animation={'wave'} height={24} style={css({ marginLeft: 0 })} />
    <Skeleton animation={'wave'} height={24} style={css({ marginLeft: 26 })} />
    <Skeleton animation={'wave'} height={24} style={css({ marginLeft: 52 })} />
    <Skeleton animation={'wave'} height={24} style={css({ marginLeft: 52 })} />
    <Skeleton animation={'wave'} height={24} style={css({ marginLeft: 26 })} />
    <Skeleton animation={'wave'} height={24} style={css({ marginLeft: 52 })} />
  </>
);
