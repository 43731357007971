/** @jsxImportSource @emotion/react */
import { appealSlice } from '@seeeverything/ui.forms/src/redux/formInstanceAppeal/index.ts';
import { AppealConfirmationDialog } from '@seeeverything/ui.shell/src/components/TextInputConfirmationDialog/AppealConfirmationDialog.tsx';
import { showModalDialog } from '@seeeverything/ui.shell/src/redux/modalDialog/actions.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { combineEpics } from 'redux-observable';
import { filter, map, Observable } from 'rxjs';
import {
  GlobalAppActionType,
  GlobalAppEpicDependencies,
  GlobalAppState,
} from '../../../types.ts';

export const epics = combineEpics<
  GlobalAppActionType,
  GlobalAppActionType,
  GlobalAppState,
  GlobalAppEpicDependencies
>(handleValidateAppealSuccess);

/**
 * Handles post-validation of the form after a user-initiated appeal of a form.
 */
function handleValidateAppealSuccess(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(appealSlice.confirmAppeal.match),
    map((action) =>
      showModalDialog({
        content: (
          <AppealConfirmationDialog
            instance={action.payload.instance}
            personId={action.payload.personId}
            signature={action.payload.signature}
          />
        ),
        width: 700,
      }),
    ),
  );
}
