/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FONTS } from '@seeeverything/ui.primitives/src/common/constants.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { IIcon } from '@seeeverything/ui.primitives/src/components/Icon/types.ts';
import { InputField } from '@seeeverything/ui.primitives/src/components/InputField/InputField.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import React from 'react';
import {
  Subject,
  delay,
  distinctUntilKeyChanged,
  filter,
  takeUntil,
} from 'rxjs';
import {
  ChipKey,
  IEntityDictionary,
} from '../../../../api/api.queryBuilder/types.ts';
import { getEntity } from '../../common/entity.ts';
import { ChipChangeEventHandler } from './types.ts';

export interface IQueryChipProps {
  entityDictionary: IEntityDictionary;
  chipKey: ChipKey;

  type: string;
  value?: string;
  label?: string;
  icon?: IIcon;

  // States.
  canEdit?: boolean;
  isEditing?: boolean;
  isSelected?: boolean;
  isTransparent?: boolean;
  isActive?: boolean; // Controls whether the chip will respond to meta events (enter, etc.).

  onChange: ChipChangeEventHandler;
  onSelect: (key: ChipKey) => void;
  onStartEditing: (key: ChipKey) => void;
  onStopEditing: (key: ChipKey) => void;
  onDelete: (key: ChipKey) => void;
  onSelectPrev: (key: ChipKey) => void;
  onSelectNext: (key: ChipKey) => void;
  onShowDropdown: (key: ChipKey) => void;
}

const FONT_SIZE = 14;
const BG_COLOR = '#4A90E2';

const DROPDOWN_ICON_FILL = color.create(COLORS.BLUE).alpha(0.5).css();
const DROPDOWN_ICON_FILL_TRANSPARENT = color.format(0.5);

/**
 * A Search Box Chip
 */
export class QueryChip extends React.Component<IQueryChipProps, object> {
  public static defaultProps: Partial<IQueryChipProps> = {
    canEdit: true,
  };

  private unmounted$ = new Subject<void>();
  private props$ = new Subject<IQueryChipProps>();

  private chip: HTMLElement;
  private chipRef = (component: HTMLDivElement) => {
    this.chip = component;
  };

  public UNSAFE_componentWillMount() {
    const props$ = this.props$.pipe(takeUntil(this.unmounted$));

    // Fire start-editing events on load if required.
    if (this.props.isEditing) {
      this.handleStartEditing();
    }

    // Ensure chip is selected.
    props$
      .pipe(
        distinctUntilKeyChanged('isSelected'),
        filter((props) => props.isSelected === true),
        delay(0),
      )
      .subscribe(() => this.selectChip());

    // Fire editing stopped when chip loses selection.
    props$
      .pipe(
        distinctUntilKeyChanged('isEditing'),
        filter(() => this.props.isEditing === true), // Is currently editing.
        filter((props) => props.isEditing === false), // Stopping editing.
      )
      .subscribe(() => {
        const { onStopEditing, chipKey } = this.props;
        onStopEditing(chipKey);
      });

    // Initialize.
    this.props$.next(this.props);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IQueryChipProps) {
    this.props$.next(nextProps);
  }

  public componentWillUnmount() {
    this.unmounted$.next(null);
  }

  public getPosition() {
    if (!this.chip) return;

    const boundingClient = this.chip.getBoundingClientRect();

    return {
      left: Math.round(boundingClient.left),
      top: Math.round(boundingClient.top),
      right: Math.round(boundingClient.right),
      bottom: Math.round(boundingClient.bottom),
    };
  }

  private get entity() {
    const { entityDictionary, type, value } = this.props;
    return getEntity({ entities: entityDictionary, type, value });
  }

  public render() {
    const { type = '', isTransparent = false, canEdit } = this.props;

    const isEditing = isTransparent ? false : this.props.isEditing;
    const isSelected = isTransparent ? false : this.props.isSelected;
    const isNotPeopleOrTeamType = type !== 'people' && type !== 'team';

    const Icon = this.props.icon || this.entity?.icon;

    const backgroundColor = isTransparent
      ? color.create(COLORS.WHITE).alpha(0.24).css()
      : isSelected
        ? color.create(BG_COLOR).alpha(0.3).css()
        : color.create(BG_COLOR).alpha(0.1).css();

    const computedStyles = {
      main: css({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        borderRadius: 2,
        height: 24,
        ':focus': {
          outline: 'none',
        },
        marginTop: 1,
        marginBottom: 1,
        backgroundColor,
        border: isTransparent
          ? 'solid 1px rgba(255, 255, 255, 0.33)'
          : `solid 1px ${color.create(BG_COLOR).alpha(0.11).css()}`,
        paddingRight: isEditing ? 5 : 0,
        maxWidth: isNotPeopleOrTeamType ? 270 : 'none',
      }),
    };

    const fill = canEdit
      ? isTransparent
        ? DROPDOWN_ICON_FILL_TRANSPARENT
        : DROPDOWN_ICON_FILL
      : 'rgba(0, 0, 0, 0)';

    return (
      <div
        ref={this.chipRef}
        css={computedStyles.main}
        onClick={this.handleMouseDown}
        onKeyDown={this.handleChipKeyDown}
        tabIndex={0}
      >
        <div css={styles.mainInner}>
          <div css={styles.iconOuter}>
            {Icon && (
              <Icon size={18} fill={isTransparent ? 'white' : COLORS.BLUE} />
            )}
          </div>
        </div>
        {isEditing ? (
          <>
            <Text
              color={isTransparent ? COLORS.WHITE : 'black'}
              weight={400}
              size={FONT_SIZE}
              style={styles.labelTextStyle}
            >
              {this.entity?.label}
            </Text>
            <Text
              color={isTransparent ? COLORS.WHITE : COLORS.BLUE}
              weight={400}
              size={FONT_SIZE}
              marginRight={5}
              style={styles.labelTextStyle}
            >
              {':'}
            </Text>
            <InputField
              value={this.props.label || ''}
              onChange={this.handleEditorValueChange}
              style={styles.valueInput}
            />
          </>
        ) : (
          <>
            <Text
              color={isTransparent ? COLORS.WHITE : COLORS.BLACK}
              weight={400}
              size={FONT_SIZE}
              style={css(styles.labelTextStyle, styles.valueTextStyle)}
            >
              {this.props.label || this.entity?.label}
            </Text>
            {canEdit ? (
              <Icons.arrowDropDown
                fill={fill}
                size={20}
                cursor={isTransparent ? 'default' : 'pointer'}
              />
            ) : (
              <div key={'dropdownIcon'} css={styles.iconOuter} />
            )}
          </>
        )}
      </div>
    );
  }

  private selectChip = () => {
    if (this.chip) {
      this.chip.focus();
    }
  };

  private handleEditorValueChange = (to: string) => {
    const { onChange, value, chipKey } = this.props;
    onChange({
      key: chipKey,
      value,
      label: to,
    });
  };

  private handleChipKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    const { chipKey, isEditing, onSelectNext, onSelectPrev, onDelete } =
      this.props;
    if ((e.key === 'Backspace' || e.key === 'Delete') && !isEditing) {
      e.preventDefault();
      onDelete(chipKey);
    }
    if (!isEditing && (e.key === 'Enter' || e.key === 'ArrowDown')) {
      e.preventDefault();
      this.handleShowDropdown();
    }

    if (isEditing) {
      if (e.key === 'ArrowDown') {
        e.preventDefault(); // Prevent moving edit caret to beginning.
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault(); // Prevent moving edit caret to end.
      }
    }

    if (!isEditing) {
      if (e.key === 'ArrowLeft') onSelectPrev(chipKey);
      if (e.key === 'ArrowRight') onSelectNext(chipKey);
    }
  };

  private handleMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    const { isEditing, onSelect } = this.props;
    if (!isEditing) {
      e.preventDefault();
      e.stopPropagation();
      onSelect(this.props.chipKey);
    }

    this.handleShowDropdown();
  };

  private handleShowDropdown = () => {
    const { onShowDropdown, chipKey, canEdit } = this.props;
    onShowDropdown(chipKey);

    if (canEdit) {
      this.handleStartEditing();
    }
  };
  private handleStartEditing = () => {
    const { onStartEditing, chipKey, canEdit } = this.props;
    if (canEdit) onStartEditing(chipKey);
  };
}

const styles = {
  mainInner: css({
    display: 'flex',
  }),
  labelTextStyle: css({
    userSelect: 'none',
    cursor: 'default',
    whiteSpace: 'nowrap',
  }),
  valueTextStyle: css({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 0,
    cursor: 'default',
  }),
  valueInput: css({
    outline: 'none',
    background: 'none',
    border: 'none',
    fontFamily: FONTS.Roboto.family,
    fontSize: FONT_SIZE,
  }),
  iconOuter: css({
    padding: '3px 5px',
    display: 'flex',
    cursor: 'default',
  }),
};
