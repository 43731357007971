/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import InputBase from '@mui/material/InputBase';
import { useState } from 'react';

export type InputFieldProps = {
  onChange: (to: string) => void;
  style?: SerializedStyles;
  value?: string;
};

/**
 * A text input, built with InputBase.
 */
export const InputField: React.FC<InputFieldProps> = ({
  onChange,
  style,
  value: propsValue = '',
}) => {
  const [value, setValue] = useState(propsValue);
  const [size, setSize] = useState(propsValue.length || 1);

  return (
    <InputBase
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        setSize(e.target.value?.length || 1);
        onChange(e.target.value);
      }}
      autoFocus={true}
      inputProps={{ size }}
      sx={style}
    />
  );
};
