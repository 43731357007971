/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DashboardTimespanVariable } from '@se/data/dashboards/query/types.ts';
import { useQueryDashboardCoachingSummaryInsights } from '@se/data/forms/hooks/useQueryDashboardCoachingSummaryInsights.ts';
import { CoachingSummaryError } from '@seeeverything/ui.forms/src/components/FormLineCoachingSummary/components/CoachingSummaryError.tsx';
import { CoachingSummaryLearningAreaGroup } from '@seeeverything/ui.forms/src/components/FormLineCoachingSummary/components/learningAreas/CoachingSummaryLearningAreaGroup.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { SkeletonDetailedItemsList } from '@seeeverything/ui.primitives/src/components/SkeletonDetailedItemsList/SkeletonDetailedItemsList.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useMeasure } from '@seeeverything/ui.primitives/src/hooks/useMeasure.ts';
import { useTenantModule } from '@seeeverything/ui.primitives/src/hooks/useTenantModule.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { groupBy, prop } from 'ramda';
import { useCallback, useEffect, useState } from 'react';

export type LearningAreasContainerProps = {
  learningAreaListId: string;
  entityId: string;
  entityType: 'person' | 'team';
  inViewport: boolean;
  timespan: DashboardTimespanVariable;
};

export const LearningAreasContainer: React.FC<LearningAreasContainerProps> = ({
  learningAreaListId,
  entityId,
  entityType,
  inViewport,
  timespan,
}) => {
  const module = useTenantModule();

  const [isQueryEnabled, setIsQueryEnabled] = useState(inViewport);
  useEffect(() => {
    if (inViewport && !isQueryEnabled) setIsQueryEnabled(true);
  }, [inViewport, isQueryEnabled]);

  const {
    insights: learningAreas,
    isLoadingInsights: isLoadingLearningAreas,
    isFetchingInsights: isFetchingLearningAreas,
    isErrorInsights: isErrorLearningAreas,
    refetchInsights: refetchLearningAreas,
  } = useQueryDashboardCoachingSummaryInsights({
    cacheId: `${module}-${learningAreaListId}-${entityId}`,
    entityId,
    entityType: entityType === 'person' ? 'Person' : 'Team',
    sectionType: 'LearningAreasIdentified',
    timespan,
    isQueryEnabled,
  });

  const [measureRef, learningAreasSize] = useMeasure({
    remeasureDependency: isLoadingLearningAreas || isFetchingLearningAreas,
  });

  const [showLess, setShowLess] = useState<boolean>(false);
  const [userShowMore, setUserShowMore] = useState<boolean>(false);

  useEffect(() => {
    if (learningAreas?.length === 0) {
      setShowLess(false);
      return undefined;
    }
    if (!learningAreasSize) return undefined;

    if (!userShowMore && learningAreasSize.height < 199) setShowLess(false);
    if (!userShowMore && learningAreasSize.height >= 199) setShowLess(true);
  }, [learningAreas?.length, learningAreasSize, userShowMore]);

  const handleShowMore = useCallback(() => {
    setShowLess(false);
    setUserShowMore(true);
  }, []);

  const learningAreaGroups = Object.entries(
    groupBy(prop('label'), learningAreas),
  );

  const elLearningAreas = !isErrorLearningAreas &&
    isQueryEnabled &&
    !isLoadingLearningAreas &&
    learningAreas.length > 0 && (
      <div ref={measureRef} css={styles.groups}>
        {learningAreaGroups.map(([label, group]) => (
          <CoachingSummaryLearningAreaGroup
            key={label}
            label={group[0].label}
            learningAreas={group}
            sectionExpanded={false}
            learningAreaListId={learningAreaListId}
            onAccordionChange={handleShowMore}
            size={'small'}
          />
        ))}
      </div>
    );

  return (
    <div
      css={[
        styles.base,
        showLess ? styles.showLess : undefined,
        isFetchingLearningAreas ? styles.mask : undefined,
      ]}
    >
      {isLoadingLearningAreas === true && (
        <SkeletonDetailedItemsList
          itemStyle={styles.skeletonList}
          numberOfSkeletonItems={3}
        />
      )}
      {isErrorLearningAreas && (
        <CoachingSummaryError
          type={'learning areas'}
          onRetryLoad={refetchLearningAreas}
        />
      )}
      {elLearningAreas}
      {!isErrorLearningAreas &&
        isQueryEnabled &&
        !isLoadingLearningAreas &&
        learningAreas.length === 0 && (
          <Text color={color.format(-0.65)} size={14}>
            {'There are no recent learning areas to show.'}
          </Text>
        )}
      {showLess && (
        <div css={styles.expand} onClick={handleShowMore}>
          <Text color={COLORS.BLUE} cursor={'pointer'} size={14}>
            {'Show more'}
          </Text>
          <Icons.arrowDropDown size={40} cursor={'pointer'} />
        </div>
      )}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    position: 'relative',
    overflow: 'hidden',
  }),
  showLess: css({
    maxHeight: 240,
    cursor: 'pointer',
  }),
  expand: css({
    position: 'absolute',
    inset: 'auto 0 0 0',
    height: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderTop: 'solid 6px #E5E5E5',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fafafa',
    },
  }),
  groups: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  mask: css({
    opacity: 0.6,
  }),
  skeletonList: css({
    margin: 8,
  }),
};
