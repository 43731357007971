/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { TreeItem as MaterialTreeItem } from '@mui/x-tree-view/TreeItem';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';

export type TreeItem = {
  id: string;
  label: React.ReactNode;
  children?: TreeItem[];
};

export type TreeViewItemProps = {
  item: TreeItem;
};

/**
 * Component that recursively creates itself for display in a <TreeView />.
 */
export const TreeViewItem: React.FC<TreeViewItemProps> = ({ item }) => (
  <MaterialTreeItem
    itemId={item.id}
    label={item.label}
    sx={css({
      '&[aria-selected=true] > .MuiTreeItem-groupTransition':
        styles.selectedGroup,
      '.MuiTreeItem-groupTransition': styles.group,
      '.MuiTreeItem-content .MuiTreeItem-label': [styles.content, styles.label],
      '.MuiTreeItem-content.Mui-selected.Mui-focused': styles.selected,
      '.MuiTreeItem-content.Mui-selected': styles.selected,
      '.MuiTreeItem-content': styles.content,
      '.MuiTreeItem-label': styles.label,
      '.Mui-selected': styles.selected,
    })}
  >
    {item.children?.map((child) => (
      <TreeViewItem key={child.id} item={child} />
    ))}
  </MaterialTreeItem>
);

const styles = {
  selectedGroup: css({
    marginLeft: 16,
    borderLeft: `solid 1px ${COLORS.BLUE}`,
  }),
  group: css({
    marginLeft: 16,
    borderLeft: `solid 1px ${color.format(-0.1)}`,
  }),
  content: css({
    color: 'inherit',
    backgroundColor: 'unset',
  }),
  label: css({
    color: 'inherit',
    borderRadius: 2,
    fontSize: 13,
    backgroundColor: 'inherit',
    overflow: 'visible',
    whiteSpace: 'nowrap',
    fill: 'white',
  }),
  selected: css({
    backgroundColor: COLORS.BLUE,
    border: 'solid 1px white',
    color: 'white',
    '&:hover': {
      backgroundColor: COLORS.BLUE,
    },
  }),
};
