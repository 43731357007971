import { useGraphQL } from '@seeeverything/ui.util/src/graphql/GraphQLProvider.tsx';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { ascend, prop, sortWith } from 'ramda';
import { useMemo } from 'react';
import { DashboardTimespanVariable } from '../../dashboards/query/types.ts';
import { formsQuery } from '../query/index.ts';
import {
  CoachingSummaryInsightSectionTypeFilter,
  CoachingSummarySectionInsight,
} from '../types.ts';

type UseQueryDashboardCoachingSummaryInsightsArgs = {
  cacheId: string;
  entityId: string;
  entityType: 'Person' | 'Team';
  sectionType: CoachingSummaryInsightSectionTypeFilter;
  timespan: DashboardTimespanVariable;
  isQueryEnabled: boolean;
};

export const useQueryDashboardCoachingSummaryInsights = (
  args: UseQueryDashboardCoachingSummaryInsightsArgs,
) => {
  const client = useGraphQL();

  const pageQueryResponse = useInfiniteQuery({
    queryKey: [
      {
        key: args.cacheId,
        entityId: args.entityId,
        entityType: args.entityType,
        sectionType: args.sectionType,
        timespan: args.timespan,
      },
    ],
    queryFn: async ({ queryKey }) => {
      const { entityId, entityType, sectionType, timespan } = queryKey[0];

      const response = await formsQuery.getDashboardCoachingSummaryInsights(
        client,
        {
          entityId,
          entityType,
          sectionType,
          timespan,
          fetchAllPages: true,
        },
      );

      if (!response.isSuccess)
        throw new Error(
          `Error while fetching coaching summary ${args.sectionType} for ${entityType} with id ${entityId}`,
        );

      return {
        pageInfo: response.data.pageInfo,
        insights: response.data.insights,
      };
    },
    getNextPageParam: (latestPage) =>
      latestPage ? latestPage.pageInfo.currentPage + 1 : 1,
    enabled: args.isQueryEnabled,
    placeholderData: keepPreviousData,
    initialPageParam: 1,
  });

  const insights = useMemo(() => {
    if (!pageQueryResponse.data) return [];

    return pageQueryResponse.data.pages
      .filter(Boolean)
      .flatMap((page) => page.insights);
  }, [pageQueryResponse.data]);

  const totalInsights =
    pageQueryResponse.data?.pages[0]?.pageInfo.totalCount ?? 0;

  const sortInsights = sortWith<CoachingSummarySectionInsight>([
    ascend(prop('label')),
  ]);

  return {
    insights: sortInsights(insights),
    totalInsights,
    hasNextPageInsights: Boolean(
      pageQueryResponse.data?.pages?.[pageQueryResponse.data.pages.length - 1]
        ?.pageInfo.hasNextPage,
    ),
    isLoadingInsights: pageQueryResponse.isLoading,
    isFetchingInsights: pageQueryResponse.isFetching,
    isFetchingInsightsNextPage: pageQueryResponse.isFetchingNextPage,
    fetchNextInsightsPage: pageQueryResponse.fetchNextPage,
    isErrorInsights: pageQueryResponse.isError,
    refetchInsights: pageQueryResponse.refetch,
  };
};
