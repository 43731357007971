/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { IDataGridProps } from './DataGrid.tsx';

export const DataGridIsLoadingBehavior = (
  DataGrid: React.FC<IDataGridProps>,
): React.FC<IDataGridProps> =>
  function DataGridIsLoading({ isLoading, ...rest }) {
    const [hasLoadedOnce, setHasLoadedOnce] = useState(false);

    useEffect(() => {
      if (hasLoadedOnce) return;
      if (!isLoading) setHasLoadedOnce(true);
    }, [hasLoadedOnce, isLoading]);

    return (
      <div>
        <div css={isLoading ? styles.mask : undefined}>
          <DataGrid {...rest} isLoading={isLoading} />
        </div>
      </div>
    );
  };

const styles = {
  mask: css({
    opacity: 0.6,
  }),
};
