/** @jsxImportSource @emotion/react */
import { orgHierarchyQuery } from '@se/data/orgHierarchy/query/index.ts';
import { Person } from '@se/data/orgHierarchy/types.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { LoadMore } from '@seeeverything/ui.primitives/src/components/LoadMore/LoadMore.tsx';
import {
  ISelectionListColumn,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { QueryRouter } from '@seeeverything/ui.shell/src/api/api.queryBuilder/QueryRouter.ts';
import { IQueryRequest } from '@seeeverything/ui.shell/src/api/api.queryBuilder/types.ts';
import { dropdownLoaded } from '@seeeverything/ui.shell/src/redux/query/actions.dropdown.ts';
import { filter } from 'rxjs';
import { getInitializedApp } from '../../../../app.ts';
import { client } from '../../../../common/index.ts';
import { getBusinessEntities } from '../../entities.ts';
import {
  MAX_HEIGHT,
  calculateDropdownHeight,
  lastChipFromQuery,
} from '../helpers.tsx';

export const router = new QueryRouter();

/**
 * DROPDOWN
 */
router
  .dropdown('people', /\/people[^/]*/, 'People dropdown')
  .pipe(
    filter(({ req }) =>
      Boolean(
        !req.isHandled &&
          ((req.context && req.context.id === 'people') ||
            (req.selection &&
              req.selection.type === 'EDITING' &&
              req.selection.chip.type === 'people')),
      ),
    ),
  )
  .subscribe(({ req, res }) => {
    const chip = req.selection?.chip;
    const searchFilter = chip?.label || '';
    res.dropdown({
      component: 'LIST',
      width: 400,
      height: 50,
      props: peopleFromServer(req, searchFilter), // Promise.
      inlineFilters: [
        {
          id: 'SHOW_INACTIVE',
          label: 'Include Inactive People',
          selectedLabel: 'Showing Inactive People',
        },
      ],
    });
  });

/**
 * AUTOCOMPLETE
 */
router
  .autocomplete('people', /\/people[^/]*$/, 'People autocomplete')
  .pipe(
    filter(({ req }) =>
      Boolean(
        !req.isHandled &&
          req.context.value &&
          req.selection &&
          req.selection.type === 'EDITING' &&
          req.selection.chip.type === 'people',
      ),
    ),
  )
  .subscribe(({ req, res }) => {
    const { query, context, selection } = req;
    const { id, label } = context;
    if (id && selection) {
      const chips = query.chips.slice(0, selection.chipIndex); // Remove anything beyond this current chip.
      res.query({
        chips: [
          ...chips,
          {
            type: 'people',
            value: id,
            label: label.text,
          },
        ],
        filter: '',
      });
    } else {
      // No ID for a person supplied, cancel the operation.
      res.cancel();
    }
  });

const loadMore =
  (
    request: IQueryRequest,
    searchFilter: string,
    existingItems: ISelectionListItem[],
    nextPageNum: number,
  ) =>
  async () => {
    const app = getInitializedApp();
    const next = await peopleFromServer(
      request,
      searchFilter,
      existingItems,
      nextPageNum,
    );

    app.store.dispatch(dropdownLoaded(next));
  };

async function peopleFromServer(
  request: IQueryRequest,
  searchFilter: string,
  existingItems: ISelectionListItem[] = [],
  nextPageNum = 1,
) {
  const app = getInitializedApp();
  const appState = app.store.getState();
  const locale = appState.tenantState.tenant.locale;

  const queryState = appState.query;

  const chip = lastChipFromQuery(request);

  const applyTeamFilter = Boolean(chip && chip.type === 'team');

  const isShowAllFilterActive =
    queryState.activeInlineFilters?.includes('SHOW_INACTIVE');

  const serverDataByTeam = async () => {
    const teamId = chip.value;
    const response = await orgHierarchyQuery.getTeamPersonMemberships(client, {
      teamId,
      pageNumber: nextPageNum,
      name: searchFilter,
      includeInactive: isShowAllFilterActive,
    });

    return response.isSuccess === true
      ? {
          currentPage: response.data.pageInfo.currentPage,
          hasNextPage: response.data.pageInfo.hasNextPage,
          data: response.data.people,
        }
      : {
          currentPage: 1,
          hasNextPage: false,
          data: new Array<Person>(),
          error: response.errorReason,
        };
  };

  const serverData = async () => {
    const response = await orgHierarchyQuery.getPeople(client, {
      name: searchFilter,
      pageNumber: nextPageNum,
      includeInactive: isShowAllFilterActive,
    });

    return response.isSuccess === true
      ? {
          currentPage: response.data.pageInfo.currentPage,
          hasNextPage: response.data.pageInfo.hasNextPage,
          data: response.data.people,
        }
      : {
          currentPage: 1,
          hasNextPage: false,
          data: new Array<Person>(),
          error: response.errorReason,
        };
  };

  const serverResponse = applyTeamFilter
    ? await serverDataByTeam()
    : await serverData();

  const peopleConfig = getBusinessEntities(locale).people;
  const icon = peopleConfig ? peopleConfig.icon : Icons.personOutline;
  const items = serverResponse.data.map((person) => {
    const {
      email,
      firstName,
      id,
      isActive = true,
      lastName,
      positionTitle,
    } = person;

    const description = `${positionTitle}${!isActive ? ' (Inactive)' : ''}${
      email ? ` - ${email}` : ''
    }`;

    return {
      id,
      icon,
      value: id,
      content: {
        text: `${firstName} ${lastName}`.trim(),
        description,
        maxWidth: 331,
      },
      isDimmed: !isActive,
    };
  });

  const allItems = [
    ...existingItems.filter(({ id }) => id !== 'load-more'),
    ...items,
  ];

  if (serverResponse.hasNextPage) {
    allItems.push({
      id: 'load-more',
      content: (
        <LoadMore
          onInView={loadMore(
            request,
            searchFilter,
            allItems,
            serverResponse.currentPage + 1,
          )}
        />
      ),
    });
  }

  const height = calculateDropdownHeight(allItems);

  const column: ISelectionListColumn = {
    isScrollable: height > MAX_HEIGHT,
    items: allItems,
  };
  return {
    columnTotal: 1,
    column,
    height: Math.min(height, MAX_HEIGHT),
  };
}
