import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { combineEpics } from 'redux-observable';
import { GlobalAppEpicDependencies, GlobalAppState } from '../../../types.ts';
import * as actionDialog from './epic.actionDialog.tsx';
import * as audit from './epic.auditLog.ts';
import { automatedActionEpics } from './epic.automatedActions.ts';
import * as changePassword from './epic.changePassword.tsx';
import * as dashboardV2 from './epic.dashboardsV2.tsx';
import * as dataExport from './epic.export.tsx';
import * as formAppeal from './epic.formAppeal.tsx';
import * as formRevertSignoff from './epic.formConfirmRevertSignoff.tsx';
import * as formInstance from './epic.formInstance.tsx';
import * as formsBulkUpload from './epic.formsBulkUpload.tsx';
import * as goalDialog from './epic.goalDialog.tsx';
import * as issueSecondaryCauses from './epic.issueSecondaryCauses.tsx';
import * as digitalContentComments from './epic.loadDigitalContentComments.ts';
import * as logout from './epic.logout.ts';
import * as monitoring from './epic.monitoring.ts';
import * as schedules from './epic.schedules.ts';
import * as sheets from './epic.sheets.tsx';
import * as updateModule from './epic.updateModule.ts';
import * as formsDesigner from './epics.formsDesigner.tsx';

export const appRootEpic = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalAppState,
  GlobalAppEpicDependencies
>(
  actionDialog.epics,
  audit.epics,
  automatedActionEpics,
  changePassword.epics,
  dashboardV2.epics,
  dataExport.epics,
  digitalContentComments.epics,
  formAppeal.epics,
  formInstance.epics,
  formRevertSignoff.epics,
  formsBulkUpload.epics,
  formsDesigner.epics,
  goalDialog.epics,
  issueSecondaryCauses.epics,
  logout.epics,
  monitoring.epics,
  schedules.epics,
  sheets.epics,
  updateModule.epics,
);
