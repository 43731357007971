/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormAutomatedActionConfiguration } from '@se/data/forms/types.ts';
import { IGridRow } from '@seeeverything/ui.primitives/src/components/Grid/types.ts';
import { MarkdownEditor } from '@seeeverything/ui.primitives/src/components/MarkdownEditor/MarkdownEditor.tsx';
import { OutsideAlerter } from '@seeeverything/ui.primitives/src/components/OutsideAlerter/OutsideAlerter.tsx';
import { useEmitDebounce } from '@seeeverything/ui.primitives/src/hooks/useEmitDebounce.ts';
import { useCallback } from 'react';
import { automatedActionConfigurationSlice } from '../../../redux/automatedActionConfiguration/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';

export type AutomationActionDescriptionCellProps = {
  row: IGridRow<FormAutomatedActionConfiguration>;
};

export const AutomatedActionsGridDescriptionCell: React.FC<
  AutomationActionDescriptionCellProps
> = ({ row }) => {
  const dispatch = useFormsDispatch();

  const error = useFormsSelector(
    (state) => state.automatedActionConfiguration.errorRows[row.id],
  );

  const handleSaveChanges = useCallback(
    (to: string, isDone = false) => {
      if (error) return;
      dispatch(
        automatedActionConfigurationSlice.saveAutomatedAction({
          gridRowId: row.id,
          description: to,
          isDone,
        }),
      );
    },
    [dispatch, error, row.id],
  );

  const [statefulValue, setStatefulValue] = useEmitDebounce({
    value: row.value.description ?? '',
    delay: 5000,
    onDebounce: handleSaveChanges,
  });

  const handleUpdate = useCallback(
    (to: string) => {
      dispatch(
        automatedActionConfigurationSlice.clearRowError({ gridRowId: row.id }),
      );
      setStatefulValue(to);
    },
    [dispatch, row.id, setStatefulValue],
  );

  const handleCancelEditing = useCallback(async () => {
    if (error) return;

    /**
     * Jankfix: setTimeout is used to delay collapsing this row, to allow
     * another row to gain mouse click/focus (if clicking a row below the current one).
     */
    setTimeout(async () => {
      handleSaveChanges(statefulValue, true);
    }, 125);
  }, [error, handleSaveChanges, statefulValue]);

  return (
    <div css={styles.base} key={row.id}>
      <div css={styles.editor}>
        <OutsideAlerter
          onClickedOutside={handleCancelEditing}
          style={css({ width: '100%' })}
        >
          <MarkdownEditor
            id={`AutomatedActionsConfig-${row.id}`}
            alwaysFocused={true}
            error={error ? `Unable to save. ${error}` : undefined}
            includeAllToolbarControls={false}
            markdown={statefulValue}
            onChange={handleUpdate}
            disableTransition={true}
          />
        </OutsideAlerter>
      </div>
    </div>
  );
};

const styles = {
  base: css({
    flex: '1 1 auto',
    width: '100%',
    height: '100%',
    padding: '10px 0',
    position: 'relative',
  }),
  editor: css({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'stretch',
  }),
};
