/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DropdownList } from '@seeeverything/ui.primitives/src/components/DropdownList/DropdownList.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Popup } from '@seeeverything/ui.primitives/src/components/Popup/Popup.tsx';
import {
  ISelectionListItem,
  SelectionListClickEventHandler,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { Transition } from '@seeeverything/ui.primitives/src/components/Transition/index.ts';
import { useMouseOver } from '@seeeverything/ui.primitives/src/hooks/useMouseOver.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useCallback, useState } from 'react';
import { DesignerEventOccurredHandler } from '../types.ts';

const SELECTION_ITEMS: ISelectionListItem[] = [
  { id: 'section', type: 'SECTION', content: 'Add New Question' },
  {
    id: 'heading',
    icon: Icons.textFormat,
    value: 'heading',
    content: 'Heading',
  },
  {
    id: 'speechBlock',
    icon: Icons.chatBubbleOutline,
    value: 'speechBlock',
    content: 'Speech Block',
  },
  {
    id: 'textBlock',
    icon: Icons.fontDownload,
    value: 'textBlock',
    content: 'Text Block',
  },
  {
    id: 'text',
    icon: Icons.textFormat,
    value: 'text',
    content: 'Text Input',
  },
  {
    id: 'switch',
    icon: Icons.thumbsUpDown,
    value: 'switch',
    content: 'Yes/No',
  },
  {
    id: 'options',
    icon: Icons.radioButtonChecked,
    value: 'options',
    content: 'Options',
  },
];

export type AddComponentListProps = {
  beforeId?: string | number;
  dataTest?: string;
  onEventOccurred?: DesignerEventOccurredHandler;
  showBullet?: boolean;
};

export const AddComponentList: React.FC<AddComponentListProps> = ({
  beforeId,
  dataTest,
  onEventOccurred,
  showBullet,
}) => {
  const [isMouseOver, handleMouseEnter, handleMouseLeave] = useMouseOver();
  const [isListShowing, setIsListShowing] = useState(false);
  const hideList = () => setIsListShowing(false);
  const showList = () => setIsListShowing(true);

  const highlight = isMouseOver || isListShowing;

  const handleClick = useCallback(
    (insertBeforeId?: string | number): SelectionListClickEventHandler =>
      (e) => {
        if (e.item?.value)
          onEventOccurred?.(e.item.value, 'ADD', { insertBeforeId });
      },
    [onEventOccurred],
  );

  return (
    <div
      css={styles.base}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={showList}
      data-test={dataTest}
    >
      <Transition.Zoom in={highlight}>
        <div css={styles.add}>
          <div css={styles.horizontal} />
          <Text color={COLORS.BLUE} weight={600} uppercase={true}>
            {'Add a question here'}
          </Text>
          <div css={styles.horizontal} />
        </div>
      </Transition.Zoom>
      {showBullet !== false && (
        <div css={styles.plusIconOuter}>
          <Icons.plus
            fill={COLORS.BLUE}
            tooltip={'Add a new component into the template'}
          />
        </div>
      )}
      {isListShowing && (
        <Popup onHide={hideList}>
          <DropdownList
            items={SELECTION_ITEMS}
            onClick={handleClick(beforeId)}
            height={277}
          />
        </Popup>
      )}
    </div>
  );
};

const styles = {
  base: css({
    position: 'relative',
    cursor: 'pointer',
  }),
  add: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 25,
    height: 30,
    cursor: 'pointer',
  }),
  plusIconOuter: css({
    position: 'absolute',
    top: 1,
    left: -36,
  }),
  horizontal: css({
    flex: '1 1 auto',
    borderTop: `solid 2px ${COLORS.BLUE}`,
    height: 2,
  }),
};
