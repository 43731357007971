/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from '@emotion/react';
import {
  DataGrid,
  DataGridDownloadBehavior,
  DataGridDownloadBehaviorProps,
  DataGridInfiniteScrollBehavior,
  DataGridIsLoadingBehavior,
  IDataGridCellTheme,
  IDataGridCellThemeArgs,
  IDataGridHeaderGroup,
  IDataGridInfiniteScrollBehaviorProps,
  IDataGridProps,
  IRenderDataGridCellArgs,
} from '@seeeverything/ui.primitives/src/components/DataGrid/index.ts';
import { GridClickEvent } from '@seeeverything/ui.primitives/src/components/Grid/types.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useSegmentAnalytics } from '@seeeverything/ui.util/src/analytics/SegmentProvider.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useGraphQL } from '@seeeverything/ui.util/src/graphql/GraphQLProvider.tsx';
import { scrollSlice } from '@seeeverything/ui.util/src/redux/scroll/index.ts';
import { pipe } from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  PAGE_SIZE_DASHBOARD_GRID,
  PAGE_SIZE_FULL_GRID,
} from '../../common/constants.ts';
import { toDateFilter, toTimespanVariable } from '../../data/util.ts';
import { customCellRender } from '../../factory/renderers/customCell.ts';
import { toRowData } from '../../factory/renderers/dataFormatting.ts';
import {
  actions,
  dashboardGridsSlice,
  useDashboardsDispatch,
  useDashboardsSelector,
} from '../../redux/index.ts';
import { IClickThroughGridEntity } from '../../redux/types.ts';
import {
  COMPONENT_GRID,
  DashboardV2ViewEntity,
  IDashboardGridRow,
  IGridDataPayload,
} from '../../types.ts';
import { matchTargetValue } from '../../util/util.ts';
import {
  DashboardGridFilteringBehavior,
  IDashboardGridFilteringBehaviorProps,
} from './components/DashboardGridFilteringBehavior.tsx';
import { useGridQuery } from './query.ts';

const DashboardGrid = pipe(
  DashboardGridFilteringBehavior,
  DataGridIsLoadingBehavior,
  DataGridDownloadBehavior,
)(DataGrid) as React.FC<
  IDashboardGridFilteringBehaviorProps &
    DataGridDownloadBehaviorProps &
    IDataGridProps
>;

const FullSheetGrid = pipe(
  DashboardGridFilteringBehavior,
  DataGridIsLoadingBehavior,
  DataGridInfiniteScrollBehavior,
)(DataGrid) as React.FC<
  IDashboardGridFilteringBehaviorProps &
    IDataGridProps &
    IDataGridInfiniteScrollBehaviorProps
>;

export interface IDashboardGridContainerProps {
  id: string;
  baseStyle?: SerializedStyles;
  dashboardType: 'CLICK_THROUGH_GRID' | 'GRID';
  inViewport: boolean;
  parentId?: string;
  sheetType: 'DASHBOARD' | 'FULL_SHEET';
}

export const DashboardGridContainer: React.FC<IDashboardGridContainerProps> = ({
  id,
  baseStyle,
  dashboardType,
  inViewport,
  parentId,
  sheetType,
}) => {
  const dispatch = useDashboardsDispatch();
  const { track } = useSegmentAnalytics();
  const client = useGraphQL();

  const template = useDashboardsSelector(
    (state) => state.dashboardsV2.template,
  );

  const module = useDashboardsSelector(
    (state) => state.tenantState.tenant.module,
  );

  const locale = useDashboardsSelector(
    (state) => state.tenantState.tenant.locale,
  );

  const gridState = useDashboardsSelector((state) =>
    dashboardType === 'CLICK_THROUGH_GRID'
      ? state.dashboardsV2.CLICK_THROUGH_GRID[id]
      : state.dashboardsV2.GRID[id],
  );

  const gridKey = useMemo(() => {
    if (!gridState) return;
    if (!template) return;

    return dashboardGridsSlice.utils.createGridKey({
      module,
      gridId: id,
      dataSetId: gridState.clickThrough?.dataSetId,
      rowId: gridState.clickThrough?.rowId,
      columnId: gridState.clickThrough?.columnId,
      entityId: template.entityId,
    });
  }, [gridState, id, module, template]);

  const sessionGridSort = useDashboardsSelector(
    (state) => state.dashboardGrids.sort[gridKey],
  );

  const reloadFlag = useDashboardsSelector(
    (state) => state.dashboardGrids.reloadFlag[gridKey],
  );

  const columnFilters = useDashboardsSelector(
    (state) => state.dashboardGrids.columnFilters[gridKey],
  );

  const dateFilter = useDashboardsSelector((state) => toDateFilter(state));
  const timespan = useMemo(() => toTimespanVariable(dateFilter), [dateFilter]);

  const orderBy = useMemo(() => {
    if (!sessionGridSort) return [];

    return [sessionGridSort.inactiveEntity, ...sessionGridSort.orderBys]
      .filter(Boolean)
      .map((sort) => ({
        fieldName: sort.columnId,
        direction: sort.direction,
      }));
  }, [sessionGridSort]);

  const filterStatements = useMemo(
    () =>
      columnFilters
        ? Object.entries(columnFilters)
            .filter(
              ([, columnState]) => columnState.selectedFilters?.length > 0,
            )
            .map(([columnId, columnState]) => ({
              columnId,
              filters: columnState.selectedFilters.map(({ value }) => value),
            }))
        : [],
    [columnFilters],
  );

  const pageSize = useMemo(() => {
    if (sheetType === 'FULL_SHEET') return PAGE_SIZE_FULL_GRID;
    return gridState?.pageSize ?? PAGE_SIZE_DASHBOARD_GRID;
  }, [gridState?.pageSize, sheetType]);

  const includeFooter = useMemo(
    () => dashboardType !== 'CLICK_THROUGH_GRID' && gridState?.footer !== false,
    [dashboardType, gridState?.footer],
  );

  const isHidden = gridState?.isHidden ?? true;

  const [isQueryEnabled, setIsQueryEnabled] = useState(false);
  useEffect(() => {
    if (gridState && !isHidden && inViewport && !isQueryEnabled) {
      track('dashboard_section_load', { sectionLabel: id });
      setIsQueryEnabled(true);
    }
  }, [id, inViewport, isQueryEnabled, track, gridState, isHidden]);

  const { data, isFetching, isFetchingNextPage, fetchNextPage } = useGridQuery(
    client,
    {
      clickThrough: gridState?.clickThrough,
      dashboardType,
      dataSetId: gridState?.dataState.dataSetId,
      entityId: template.entityId,
      entityType: template.kind,
      filterStatements,
      gridId: id,
      includeFooter,
      isQueryEnabled,
      module,
      orderBy,
      pageSize,
      reloadFlag,
      templateId: template.id,
      timespan,
      tempFormStatusExcludeCompleted: gridState?.tempFormStatusExcludeCompleted,
    },
  );

  const isLoading = (isFetching && !isFetchingNextPage) || !isQueryEnabled;

  const [hasLoadedWhileInViewport, setHasLoadedWhileInViewport] =
    useState(false);

  useEffect(() => {
    if (hasLoadedWhileInViewport) return;
    if (!inViewport) return;
    if (isLoading) return;
    setHasLoadedWhileInViewport(true);
  }, [isLoading, hasLoadedWhileInViewport, inViewport]);

  const allData = useMemo(
    () =>
      data?.pages.reduce<IGridDataPayload>(
        (acc, page) => ({
          ...acc,
          ...page,
          data: [...acc.data, ...page.data],
          footer: acc.footer ?? page.footer,
        }),
        { data: [] },
      ),
    [data],
  );

  const loadNextPage = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const viewEntity = useCallback<DashboardV2ViewEntity>(
    (gridId, entity) =>
      dispatch(
        actions.viewEntity(gridId, entity.id, entity.type, entity.label),
      ),
    [dispatch],
  );

  const viewRowEntity = useCallback(
    (rowId: string | number) => {
      const row = allData?.data.find((r) => r.id === rowId);
      if (!row?.entity) return;

      if (row.tags?.some((tag) => tag === 'IsSensitive')) return;

      viewEntity(id, row.entity);
    },
    [viewEntity, allData?.data, id],
  );

  const handleDoubleClick = useCallback(
    (e: GridClickEvent) => viewRowEntity(e.rowId),
    [viewRowEntity],
  );
  const handleLaunchRowClicked = useCallback(
    (args: IRenderDataGridCellArgs<IDashboardGridRow>) => () =>
      viewRowEntity(args.row.id),
    [viewRowEntity],
  );

  const handleGridLinkClicked = useCallback(
    (args: IRenderDataGridCellArgs<IDashboardGridRow>) => () => {
      if (!gridState) return;

      const column = gridState.columns[args.columnIndex];
      if (!column.clickThrough) return;

      const entity = args.row.entity;
      if (!entity) return;

      const sourceGrid = {
        id,
        dataSetId: gridState.dataState.dataSetId,
        columnClicked: {
          clickThroughType: column.clickThrough?.type,
          id: column.id,
          name: column.group ? `${column.group} ${column.label}` : column.label,
        },
        name: gridState.name,
      };
      dispatch(
        actions.viewClickThroughGrid(
          column.clickThrough.gridId,
          entity as IClickThroughGridEntity,
          sourceGrid,
          column.clickThrough?.definitionGridId,
        ),
      );
    },
    [dispatch, gridState, id],
  );

  const scrollToCategory = useCallback(
    (args: IRenderDataGridCellArgs<IDashboardGridRow>) => () => {
      if (!gridState) return;
      const column = gridState.columns[args.columnIndex];
      const selected = args.row.data[args.columnIndex];

      const idToScroll = column.categoryMap?.find(
        (category) => category.value === selected,
      )?.scrollToId;
      if (!idToScroll) return;

      dispatch(
        scrollSlice.scrollToDataId({
          scrollContainerId: 'scrollPanel',
          dataId: idToScroll,
        }),
      );
    },
    [gridState, dispatch],
  );

  const cellRender = useCallback(
    (args: IRenderDataGridCellArgs<IDashboardGridRow>) =>
      customCellRender({
        args,
        viewEntity,
        launchHandler: handleLaunchRowClicked,
        linkHandler: handleGridLinkClicked,
        categoryClickedHandler: scrollToCategory,
        gridState,
      }),
    [
      scrollToCategory,
      handleGridLinkClicked,
      handleLaunchRowClicked,
      gridState,
      viewEntity,
    ],
  );

  const cellTheme = useCallback(
    ({ rowIndex }: IDataGridCellThemeArgs) => {
      if (!gridState?.tagStyles) return;
      if (!allData?.data) return;

      const row = allData.data[rowIndex];
      const tags = row?.tags || [];
      return tags.length ? gridState.tagStyles[tags[0]] : undefined;
    },
    [allData?.data, gridState?.tagStyles],
  );

  const allRowData = useMemo(() => {
    if (!gridState?.columns) return;
    if (!allData?.data) return;

    return allData.data.map((row) =>
      toRowData(gridState?.columns, row, locale),
    );
  }, [allData?.data, gridState?.columns, locale]);

  const cellThemeOverrides = useCallback(
    ({ rowIndex, columnIndex }: IDataGridCellThemeArgs): IDataGridCellTheme => {
      if (!gridState) return {};
      if (!allData?.data) return {};

      const columnDefinition = gridState.columns[columnIndex];

      const row = allData.data[rowIndex];
      if (!row) return {};

      const enrichedRow = allRowData[rowIndex];
      const contentMapping = columnDefinition.contentMap || [];
      const dataPoint = enrichedRow.enrichedData[columnIndex];
      const match = matchTargetValue(contentMapping, dataPoint);

      return match ? { bgColor: match.bgColor } : {};
    },
    [allData?.data, allRowData, gridState],
  );

  const handleLoadMore = useCallback(
    () => dispatch(actions.viewDetail(COMPONENT_GRID, id)),
    [dispatch, id],
  );

  const entityPath = useDashboardsSelector((state) =>
    dashboardType === 'CLICK_THROUGH_GRID'
      ? state.dashboardsV2.CLICK_THROUGH_GRID[id]?.entityPath
      : state.dashboardsV2.HEADER?.dataState.data?.data.path,
  );

  const hasNextPage = Boolean(allData?.pagination?.hasNextPage);
  const totalRows = allData?.pagination?.totalCount || 0;

  const handleDataGridDownloadClick = useCallback(() => {
    dispatch(
      dashboardGridsSlice.downloadToSpreadsheet({
        gridId: id,
        gridKey,
        entityPath,
      }),
    );
  }, [dispatch, entityPath, gridKey, id]);

  const sortedData = useMemo(() => {
    const unsorted = allData?.data;
    if (!unsorted?.length) return [];

    return gridState?.columns && unsorted.length
      ? unsorted.map((row) => toRowData(gridState?.columns, row, locale))
      : [];
  }, [allData?.data, gridState?.columns, locale]);

  const footerData = useMemo(() => {
    if (!gridState?.columns) return;

    const footerRow = allData?.footer?.[0];
    if (!footerRow) return;

    return toRowData(gridState?.columns, footerRow, locale, true);
  }, [allData?.footer, gridState?.columns, locale]);

  const isError = gridState?.dataState.error;

  const columns = useMemo(() => gridState?.columns ?? [], [gridState?.columns]);

  const headerGroups = useMemo(() => {
    if (!gridState) return [];

    const headerGroupState = gridState.headerGroups ?? [];
    return sheetType === 'DASHBOARD'
      ? headerGroupState
      : headerGroupState.map(
          (group): IDataGridHeaderGroup => ({
            ...group,
            linkableHeader: undefined,
          }),
        );
  }, [gridState, sheetType]);

  if (isHidden) return <div data-id={id} parent-data-id={parentId} />;
  if (isError)
    return (
      <div
        data-id={id}
        parent-data-id={parentId}
        css={[styles.base, baseStyle]}
      >
        <Text color={color.format(-0.5)}>
          {'There was a problem loading this table. Please try again.'}
        </Text>
      </div>
    );

  return (
    <div data-id={id} parent-data-id={parentId} css={[styles.base, baseStyle]}>
      {sheetType === 'DASHBOARD' ? (
        <DashboardGrid
          cellTheme={cellTheme}
          cellThemeOverrides={cellThemeOverrides}
          gridKey={gridKey}
          columns={columns}
          dashboardType={dashboardType}
          data={sortedData}
          footer={footerData}
          hasNextPage={hasNextPage}
          headerGroups={headerGroups}
          id={id}
          isVirtualized={false}
          isLoading={isLoading || !hasLoadedWhileInViewport}
          loadMoreText={
            hasNextPage
              ? `Load more (${totalRows} total rows)`
              : 'Expand full screen'
          }
          onHeaderClick={() => void 0}
          onDoubleClick={handleDoubleClick}
          onDownloadClicked={handleDataGridDownloadClick}
          onLoadMore={handleLoadMore}
          renderCellContents={cellRender}
          sheetType={sheetType}
        />
      ) : (
        <FullSheetGrid
          cellTheme={cellTheme}
          cellThemeOverrides={cellThemeOverrides}
          gridKey={gridKey}
          columns={columns}
          dashboardType={dashboardType}
          data={sortedData}
          footer={footerData}
          hasNextPage={hasNextPage}
          headerGroups={headerGroups}
          id={id}
          isLoading={isLoading}
          isLoadingPage={isFetchingNextPage}
          isScrollable={true}
          isVirtualized={true}
          loadMoreIcon={'arrowDown'}
          loadMoreText={'Load more'}
          onDoubleClick={handleDoubleClick}
          onLoadPage={loadNextPage}
          renderCellContents={cellRender}
          sheetType={sheetType}
        />
      )}
    </div>
  );
};

const styles = {
  base: css({ marginBottom: 30 }),
};
