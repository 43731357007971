import { ChipType } from '@seeeverything/ui.util/src/urlDeepLink/urlDeepLink.ts';
import { ChipKey, IChip } from '../../../../api/api.queryBuilder/types.ts';
import { IInternalState } from '../../types.ts';
import { getChipForKey } from './selectors.ts';

/**
 * UTILS
 * =============
 *
 * This file contains utility functions for the Query Builder state.
 * These functions should not generally be called from an external source - if they are,
 * they should be refactored into an action.
 *
 */

/**
 * Append a string value to the end of the current text input.
 */
export const keyGenerator = (() => {
  let key = 1;
  return () => key++;
})();

/**
 * Helper method to create a chip with metadata.
 */
export const createChip = ({
  type = '',
  value = '',
}: { type?: string; value?: string } = {}): IChip => ({
  key: keyGenerator(),
  data: {
    type: type as ChipType,
    value: value,
  },
});

/**
 * Set a chip as editing.
 */
export const updateCurrentEditingChip = (
  state: IInternalState,
  key: ChipKey,
): IInternalState => ({
  ...state,
  editingChip: getChipForKey(state, key) ? { key } : undefined,
});

/**
 * Set all chips as not editing.
 */
export const clearCurrentKeyEditingState = (
  state: IInternalState,
): IInternalState => ({
  ...state,
  editingChip: undefined,
});

/**
 * Clear chip selection.
 */
export const clearCurrentSelectionState = (
  state: IInternalState,
): IInternalState => ({
  ...state,
  selectedChip: undefined,
});

/**
 * Clear text input focus.
 */
export const clearTextInputFocusState = (
  state: IInternalState,
): IInternalState => ({
  ...state,
  textInputFocused: false,
});

export const clearInputBlurredState = (
  state: IInternalState,
): IInternalState => ({
  ...state,
  inputBlurred: false,
});

/**
 * Clear chip selection state and edit state.
 */
export const sanitizeState = (state: IInternalState): IInternalState => ({
  ...state,
  editingChip: undefined,
  selectedChip: undefined,
  textInputFocused: false,
  inputBlurred: false,
});

/**
 * Replace all the chips of the state with a new list of chips
 * @param state the Query Builder state
 * @param chips the new chips to replace the existing chips
 */
export const replaceChips = (
  state: IInternalState,
  chips: IChip[],
): IInternalState => ({
  ...state,
  chips,
});

/**
 * Remove all chips with empty(ish) values, and all chips to the right of those chips.
 * @param state the Query Builder state
 */
export const clearAllEmptyChips = (state: IInternalState): IInternalState => ({
  ...state,
  chips: state.chips
    .map(
      (chip): IChip => ({
        ...chip,
        data: {
          ...chip.data,
          value: chip.data.value
            ? chip.data.value.trim().replace(/\u200B/g, '')
            : undefined,
        },
      }),
    )
    .filter((chip) => Boolean(chip.data.value?.trim().replace(/\u200B/g, ''))),
});

/**
 * Deletes all chips after a certain point
 * @param state the Query Builder state
 * @param sliceAt the index to delete chips after
 */
export const deleteAllChipsAfter = (
  state: IInternalState,
  sliceAt: number,
): IInternalState => {
  const chips = state.chips;

  if (chips.length === 0) return state;
  if (sliceAt > chips.length - 1 || sliceAt < 0) return state;

  const newChips = chips.slice(0, sliceAt);

  return replaceChips(state, newChips);
};

/**
 * Focus the text input, and clears any chip editing state
 * @param state the Query Builder state
 */
export const focusTextInput = (state: IInternalState): IInternalState => ({
  ...state,
  selectedChip: undefined,
  editingChip: undefined,
  textInputFocused: true,
});
