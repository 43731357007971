/** @jsxImportSource @emotion/react */
import { userRevertedSignoff } from '@seeeverything/ui.forms/src/redux/form-instance/instance/actions.ts';
import { Signature } from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { hideModalDialog } from '../../redux/modalDialog/actions.ts';
import { useShellDispatch } from '../../redux/store.ts';
import { TextInputConfirmationDialog } from './TextInputConfirmationDialog.tsx';

export type RevertSignoffConfirmationDialogProps = {
  instanceId: string;
  personId: string;
  signature: Signature;
};

export const RevertSignoffConfirmationDialog: React.FC<
  RevertSignoffConfirmationDialogProps
> = ({ instanceId, personId, signature }) => {
  const dispatch = useShellDispatch();

  return (
    <TextInputConfirmationDialog
      title={'Reopen - Confirmation'}
      description={`This will remove the ${str.humanize(
        signature.type,
      )} sign-off. Are you sure you want to do this?`}
      inputLabel={'Reason (Required)'}
      inputError={'You must specify a reason for reopening.'}
      onActionOK={(reason: string) => {
        dispatch(hideModalDialog());
        dispatch(userRevertedSignoff(instanceId, personId, signature, reason));
      }}
      onActionCancel={() => dispatch(hideModalDialog())}
    />
  );
};
