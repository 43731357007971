/** @jsxImportSource @emotion/react */
import { AutomatedActionsConfiguration } from '@seeeverything/ui.forms/src/components/AutomatedActionsConfiguration/AutomatedActionsConfiguration.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { useTenantLocale } from '@seeeverything/ui.primitives/src/hooks/useTenantLocale.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useShellSelector } from '../../redux/store.ts';
import { SheetLayout } from '../SheetLayout/index.ts';

export const SheetAutomatedActions: React.FC = () => {
  const module = useShellSelector((state) => state.tenantState.tenant.module);

  const formLabel = useTenantLocale((l) => l.label.form);

  const showAll = useShellSelector(
    (state) => state.automatedActionConfiguration.showAll,
  );

  if (module === 'cadence') return;

  return (
    <SheetLayout
      overrideNoScrollPanel={true}
      title={
        module === 'coaching'
          ? 'Configure Coaching Playbook Actions'
          : 'Configure Smart Actions'
      }
      moreItems={[
        {
          id: 'Section',
          type: 'SECTION',
          content: 'Options',
        },
        {
          id: 'ShowHistorical',
          icon: Icons.history,
          content: {
            text:
              module === 'coaching'
                ? 'Include historical playbook actions?'
                : 'Include historical smart actions?',
          },
          statusIcon: Icons.tickDone,
          statusIconColor: {
            default: showAll ? COLORS.GREEN_TICK : color.format(-0.05),
            selected: showAll ? COLORS.GREEN_TICK : color.format(-0.05),
          },
        },
        {
          id: 'Export',
          icon: Icons.fileDownload,
          content: {
            text: 'Export to CSV',
            description: `Download a CSV file containing all ${module === 'coaching' ? 'Playbook Actions' : 'Smart Actions'} across all ${formLabel} types.`,
          },
        },
      ]}
    >
      <AutomatedActionsConfiguration />
    </SheetLayout>
  );
};
