/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { useTrackInViewport } from '@seeeverything/ui.primitives/src/hooks/useTrackInViewport.ts';
import { propsAreEqualFastCompare } from '@seeeverything/ui.util/src/react/memoFastCompare.ts';
import { memo, useEffect, useState } from 'react';
import { DASHBOARD_INTERSECTION_OPTIONS } from '../common/constants.ts';
import { DashboardGridContainer } from '../components/DashboardGrid/DashboardGridContainer.tsx';
import { useDashboardsSelector } from '../redux/store.ts';

export interface IFactoryGridContainerProps {
  parentId?: string;
  id: string;
}

const View: React.FC<IFactoryGridContainerProps> = ({ parentId, id }) => {
  const [forwardedRef, inViewport] = useTrackInViewport({
    dataId: id,
    parentDataId: parentId,
    observerOptions: DASHBOARD_INTERSECTION_OPTIONS,
  });
  const [isInit, setIsInit] = useState(inViewport);

  useEffect(() => {
    if (isInit) return;
    if (!inViewport) return;
    setIsInit(true);
  }, [inViewport, isInit]);

  const scrollToDataId = useDashboardsSelector(
    (state) => state.scroll.containers.scrollPanel?.scrollToDataId,
  );

  const isHidden = useDashboardsSelector(
    (state) => state.dashboardsV2.GRID[id]?.isHidden === true,
  );

  const [shownOnce, setShownOnce] = useState(!isHidden);
  useEffect(() => {
    if (shownOnce) return;
    if (!isHidden) return;
    setShownOnce(true);
  }, [isHidden, shownOnce]);

  if (isHidden && !shownOnce) return;

  return (
    <div
      id={id}
      css={
        scrollToDataId === id
          ? [styles.base, CommonStyles.ScrollPulse]
          : [styles.base]
      }
      data-id={id}
      parent-data-id={parentId}
      ref={forwardedRef}
    >
      <DashboardGridContainer
        id={id}
        inViewport={inViewport}
        sheetType={'DASHBOARD'}
        dashboardType={'GRID'}
      />
    </div>
  );
};

const styles = {
  base: css({
    margin: '3px 30px 0px 35px',
  }),
};

export const FactoryGridContainer = memo(View, propsAreEqualFastCompare);
