/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import MaterialChip from '@mui/material/Chip';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';
import { Paper } from '../Paper/Paper.tsx';
import { Text } from '../Text/Text.tsx';

export type ChipProps = {
  icon?: React.ReactElement;
  isSelected?: boolean;
  label: string;
  labelStyle?: SerializedStyles;
  onClick?: () => void;
  style?: SerializedStyles;
  tooltip?: string;
};

export const Chip: React.FC<ChipProps> = ({
  icon,
  isSelected,
  label,
  labelStyle,
  onClick,
  style,
  tooltip,
}) => {
  const computedStyles = {
    chip: css({
      '&.MuiChip-root': css(
        {
          border: `solid 1px ${color.format(-0.1)}`,
          borderRadius: 4,
          background: 'transparent',
          cursor: onClick ? 'pointer' : 'default',
          backgroundColor: isSelected ? COLORS.BLUE : color.format(-0.1),
          display: 'flex',
          flexDirection: 'row',
          padding: icon ? '0 12px 0 10px' : '0 12px',
          gap: 6,
          '&:hover': {
            background: isSelected
              ? color.create(COLORS.BLUE).brighten(0.2).css()
              : color.format(-0.2),
          },
          '&:focus': {
            backgroundColor: isSelected ? COLORS.BLUE : color.format(-0.1),
            '&:hover': {
              background: isSelected
                ? color.create(COLORS.BLUE).brighten(0.2).css()
                : color.format(-0.2),
            },
          },
          '& span': {
            padding: 0,
          },
        },
        style,
      ),
    }),
    label: css({
      color: isSelected ? 'white' : color.format(-0.8),
    }),
  };

  return (
    <Paper elevation={0}>
      <MaterialChip
        onClick={onClick}
        label={
          <Text
            tooltip={tooltip}
            cursor={'inherit'}
            style={[computedStyles.label, labelStyle]}
          >
            {label}
          </Text>
        }
        sx={computedStyles.chip}
        icon={icon}
      />
    </Paper>
  );
};
