/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  FormActionAnswerText,
  FormActionTemplateQuestionText,
} from '@se/data/forms/types.ts';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { useCallback } from 'react';
import { formActionSlice } from '../../../../redux/formAction/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../../redux/store.ts';

export interface IActionQuestionTextContainerProps {
  questionId: string;
  siblingQuestionId?: string;
  columnIndex: number;
}

export const ActionQuestionTextContainer: React.FC<
  IActionQuestionTextContainerProps
> = ({ questionId, siblingQuestionId, columnIndex }) => {
  const dispatch = useFormsDispatch();

  const answer = useFormsSelector((state) => {
    const draftAnswer = state.formActionV2.draftChanges.answers.find(
      (a): a is FormActionAnswerText =>
        a.questionId === questionId && a.type === 'Text',
    );

    const existingAnswer = state.formActionV2.existingAction?.answers?.find(
      (a): a is FormActionAnswerText =>
        a.questionId === questionId && a.type === 'Text',
    );

    return draftAnswer ?? existingAnswer;
  });

  const error = useFormsSelector(
    (state) =>
      state.formActionV2.errors.answers.find((a) => a.questionId === questionId)
        ?.error,
  );

  const siblingError = useFormsSelector((state) => {
    if (!siblingQuestionId) return;

    return Boolean(
      state.formActionV2.errors.answers.find(
        (a) => a.questionId === siblingQuestionId,
      )?.error,
    );
  });

  const question = useFormsSelector((state) =>
    state.formActionV2.actionTemplate.questions.find(
      (q): q is FormActionTemplateQuestionText =>
        q.id === questionId && q.type === 'Text',
    ),
  );

  const canEdit = useFormsSelector((state) =>
    state.formActionV2.existingAction
      ? ['Open', 'Overdue'].includes(state.formActionV2.existingAction.status)
      : true,
  );

  const updateAnswer = useCallback(
    (to: string) => {
      dispatch(formActionSlice.updateTextAnswer({ questionId, to }));
    },
    [dispatch, questionId],
  );

  return (
    <div css={siblingError && !error ? styles.baseSiblingError : styles.base}>
      {columnIndex > 0 && <div css={styles.space} />}
      <TextField
        id={questionId}
        value={answer?.value}
        label={question.label}
        multiline={true}
        maxRows={7}
        isEnabled={canEdit}
        onChange={updateAnswer}
        error={error}
      />
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'row',
  }),
  baseSiblingError: css({
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 23,
  }),
  space: css({
    width: 35,
  }),
};
