/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryFormTemplatesByCategory } from '@se/data/forms/hooks/useQueryFormTemplatesByCategory.ts';
import { serverCreate } from '@seeeverything/ui.forms/src/redux/form-instance/instance/actions.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { useCallback, useRef } from 'react';
import { chipAddedExternally } from '../../redux/query/actions.ts';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';

export const CreateNewFormInstanceButton: React.FC = () => {
  const dispatch = useShellDispatch();

  const formLabel = useShellSelector(
    (state) => state.tenantState.tenant.locale.label.form,
  );

  const { templateSelections, templatesLoading } =
    useQueryFormTemplatesByCategory({ createInstanceOnly: true });

  const handleCreateInstance = useCallback(
    (to: ISelectionListItem) => {
      const template = to.value;
      const instanceId = uuid.generate();

      dispatch(
        serverCreate({
          categoryName: template.category.name,
          instanceId,
          templateDefinitionId: template.currentDefinitionId,
          templateId: template.id,
          templateName: template.name,
        }),
      );
      dispatch(
        chipAddedExternally(
          { type: 'forms', label: template.name, value: instanceId },
          true,
          'NEW_FORM',
        ),
      );
    },
    [dispatch],
  );

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Button
      style={styles.base}
      onClick={() => inputRef.current?.focus()}
      dataTest={'shell-newFormField-newFormButton'}
    >
      <>
        <Icons.addBox fill={COLORS.BLUE} />
        <TextFieldDropdown
          id={'form-templates'}
          label={`New ${formLabel}`}
          onChange={handleCreateInstance}
          selections={templateSelections}
          openOnFocus={true}
          listBoxStyle={styles.fieldListBox}
          isLoading={templatesLoading}
          popperWidth={400}
          InputProps={{
            inputRef,
            disableUnderline: true,
            endAdornment: undefined,
            placeholder: 'Filter',
          }}
          inputRootStyle={styles.inputRoot}
          inputLabelRootStyle={styles.inputLabelRoot}
          inputLabelShrinkStyle={styles.inputLabelShrink}
          style={styles.dropdown}
        />
      </>
    </Button>
  );
};

const styles = {
  base: css({
    display: 'flex',
    cursor: 'pointer',
    paddingLeft: 8,
    borderRadius: 3,
  }),
  inputRoot: css({
    top: -23,
    left: 12,
    cursor: 'pointer',
  }),
  fieldListBox: css({
    maxHeight: '70vh',
  }),
  inputLabelRoot: css({
    color: COLORS.BLUE,
    position: 'relative',
    top: -10,
    paddingRight: 22,
    paddingLeft: 8,
  }),
  inputLabelShrink: css({
    inset: '4px auto auto 6px',
  }),
  dropdown: css({
    maxHeight: 46,
  }),
};
