/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DatePicker } from '@seeeverything/ui.primitives/src/components/DatePicker/DatePicker.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TreeItem } from '@seeeverything/ui.primitives/src/components/TreeView/components/TreeViewItem.tsx';
import { TreeView } from '@seeeverything/ui.primitives/src/components/TreeView/index.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export type CsvDialogProps = {
  endDate?: string;
  filters?: TreeItem[];
  loadingFilters?: boolean;
  onEndDateChange: (to: string) => void;
  onSelectFilter: (to: string[]) => void;
  onStartDateChange: (to: string) => void;
  selectedFilters?: string[];
  startDate?: string;
};

/**
 * Dialog content for specifying filters against a CSV export.
 */
export const CsvDialog: React.FC<CsvDialogProps> = ({
  endDate,
  filters,
  loadingFilters,
  onEndDateChange,
  onSelectFilter,
  onStartDateChange,
  selectedFilters,
  startDate,
}) => (
  <div css={styles.base}>
    <div css={styles.header}>
      <div css={styles.title}>
        <Text size={48} color={color.format(-0.2)} weight={300}>
          {'Download to CSV'}
        </Text>
      </div>
    </div>
    <div css={styles.body}>
      <div css={styles.dateRow}>
        <div css={styles.dateLeft}>
          <DatePicker
            id={'start-date'}
            label={'Start Date'}
            value={startDate}
            onChange={onStartDateChange}
            maxDate={endDate}
          />
        </div>
        <div css={styles.dateRight}>
          <DatePicker
            id={'end-date'}
            label={'End Date'}
            value={endDate}
            minDate={startDate}
            onChange={onEndDateChange}
          />
        </div>
      </div>
      {filters && (
        <>
          <Text color={color.format(-0.8)} size={15}>
            {'Categories/Templates:'}
          </Text>
          <TreeView
            isLoading={loadingFilters}
            items={filters}
            onItemSelect={onSelectFilter}
            selected={selectedFilters}
          />
          <Text color={color.format(-0.6)} weight={100} size={14} italic={true}>
            {'Use control-click to select multiple items.'}
          </Text>
        </>
      )}
    </div>
  </div>
);

const styles = {
  base: css({
    overflow: 'hidden',
    width: '100%',
    flex: '1 1 auto',
  }),
  header: css({
    height: 96,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'stretch',
  }),
  title: css({
    flex: '1 1 auto',
    margin: '20px 0 0 34px',
  }),
  body: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    padding: '0 33px 33px 33px',
  }),
  dateRow: css({
    display: 'flex',
    marginBottom: 30,
  }),
  dateLeft: css({
    width: '45%',
    marginRight: '10%',
  }),
  dateRight: css({
    width: '45%',
  }),
};
