/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Spacer } from '@seeeverything/ui.primitives/src/components/Spacer/Spacer.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import React from 'react';
import { Observable, Subject, interval, takeUntil } from 'rxjs';
import { StatusBarType, StatusPosition } from './types.ts';

interface IStatusTheme {
  fill: string;
  hoverFill: string;
  background?: string;
  hoverBackground?: string;
}

export interface IStatusBarProps {
  type?: StatusBarType;
  message?: string;
  isShowing?: boolean;
  autoHideDuration?: number;
  onClose?: (e?: string) => void;

  opacity?: number;
  hoverOpacity?: number;
  fadeInDuration?: number;
  fadeOutDuration?: number;
  position?: StatusPosition;
}

const DEFAULT_AUTO_HIDE = 5000;

export class StatusBar extends React.PureComponent<IStatusBarProps> {
  private unmounted$ = new Subject<void>();
  private timerStop$: Subject<any>;
  private timerReset$: Subject<any>;
  private timerInterval$: Observable<number>;

  private resetTimer = (autoHideDuration?: number) => {
    const { onClose } = this.props;

    this.timerReset$ = new Subject<void>();
    this.timerStop$ = new Subject<void>();
    this.timerInterval$ = new Observable<number>();

    this.timerReset$.pipe(takeUntil(this.unmounted$)).subscribe(() => {
      this.timerInterval$ = interval(autoHideDuration).pipe(
        takeUntil(this.unmounted$),
      );

      this.timerInterval$
        .pipe(takeUntil(this.timerStop$), takeUntil(this.timerReset$))
        .subscribe(() => {
          if (onClose) {
            onClose('autoHide');
          }
          this.timerStop$?.next(null);
        });
    });
  };

  public componentDidMount() {
    this.resetTimer(this.props.autoHideDuration || DEFAULT_AUTO_HIDE);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IStatusBarProps) {
    if (nextProps.autoHideDuration !== this.props.autoHideDuration) {
      this.resetTimer(nextProps.autoHideDuration || DEFAULT_AUTO_HIDE);
    }
  }

  public componentWillUnmount() {
    this.unmounted$.next(null);
  }

  public render() {
    const {
      type,
      message = 'Nothing to show...',
      isShowing,
      hoverOpacity = 0.05,
      fadeInDuration = 500,
      fadeOutDuration = 500,
      position = 'absolute',
    } = this.props;

    if (!type) {
      return <div />;
    }

    if (isShowing) {
      this.timerReset$?.next(null);
    }

    const theme = getTheme(type);

    const computedStyles = {
      outer: css({
        borderLeft: `15px solid ${theme.fill}`,
        transition: 'background-color 0.2s',
        ':hover': {
          backgroundColor: theme.hoverBackground,
          background: color.format(-hoverOpacity),
        },
      }),
      base: css({
        transition: isShowing
          ? `opacity ${fadeInDuration}ms`
          : `opacity ${fadeOutDuration}ms`,
        backgroundColor: theme.background,
        transitionTimingFunction: isShowing ? 'ease-out' : 'ease-in',
        pointerEvents: isShowing ? 'auto' : 'none',
        opacity: isShowing ? 1 : 0,
        position,
      }),
    };

    const elMessage = (
      <Text color={color.format(-0.8)} ellipsis={false}>
        {message}
      </Text>
    );

    return (
      <div
        css={[styles.base, computedStyles.base]}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div css={[styles.outer, computedStyles.outer]}>
          {elMessage}
          <Spacer paddingHorizontal={10} />
          <Icons.clearCircle
            fill={theme.fill}
            onClick={this.handleCloseClick}
          />
        </div>
      </div>
    );
  }

  private handleCloseClick = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  };

  private handleMouseEnter = () => {
    this.timerStop$?.next(null);
  };

  private handleMouseLeave = () => {
    this.timerReset$?.next(null);
  };
}

function getTheme(type: StatusBarType): IStatusTheme {
  const colors = {
    SUCCESS: {
      fill: '#94D074',
      hoverFill: '#94D074',
      background: '#FCFCFC',
      hoverBackground: '#F2F2F2',
    },
    ERROR: {
      fill: '#D15544',
      hoverFill: '#D15544',
      background: '#FCFCFC',
      hoverBackground: '#F2F2F2',
    },
    WARN: {
      fill: '#F4B640',
      hoverFill: '#F4B640',
      background: '#FCFCFC',
      hoverBackground: '#F2F2F2',
    },
    INFO: {
      fill: '#659CF9',
      hoverFill: '#4B6FB1',
      background: '#FCFCFC',
      hoverBackground: '#F2F2F2',
    },
  };

  return colors[type];
}

const styles = {
  base: css({
    position: 'absolute',
    right: 20,
    bottom: 30,
    left: 20,
    backgroundColor: 'white',
    zIndex: 14, // Shell modal dialog has a z-index of 13.
    border: 'solid 0.5px #F0F0F0',
    boxShadow: `0 2px 4px 1px ${color.format(-0.4)}`,
    borderRadius: 2,
  }),
  outer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
  }),
};
