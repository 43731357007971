/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Image } from '@seeeverything/ui.primitives/src/components/Image/index.ts';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';
import { IQueryData } from '../../api/api.queryBuilder/types.ts';
import { QueryBuilderContainer } from '../QueryBuilder/QueryBuilderContainer.tsx';
import { ErrorBoundaryShell } from './components/ErrorBoundaryShell.tsx';
import { ModuleDropdownContainer } from './components/ModuleDropdownContainer.tsx';
import { ShellSheetsContainer } from './components/ShellSheetsContainer.tsx';

export type ShellProps = {
  queryData: IQueryData;
  isEmpty?: boolean;
  isSheetsLoading?: boolean;
  isLoggingOut?: boolean;
  isErrorState?: boolean;
  dashboardV2Templates: any;
  onHomeClick?: () => void;
};

/**
 * The layout styling for the application shell.
 */
export const Shell: React.FC<ShellProps> = ({
  isEmpty,
  onHomeClick,
  isLoggingOut,
  isErrorState,
  queryData,
  dashboardV2Templates,
  isSheetsLoading,
}) => {
  const elLogo = isEmpty && (
    <Image
      style={styles.logo}
      path={'/ui.shell/images/Logo/white.png'}
      path2x={'/ui.shell/images/Logo/white@2x.png'}
      width={81}
      height={77}
    />
  );

  const elHome = onHomeClick ? (
    <Button
      onClick={onHomeClick}
      tooltip={'Return to your dashboard.'}
      dataTest={'shell-returnToDashboardButton'}
      fill={COLORS.WHITE}
      margin={'-1px 0px 1px 0px'}
      hoverAlpha={0.1}
      style={styles.button}
    >
      <div css={styles.homeIcon}>
        <Icons.home size={30} fill={COLORS.WHITE} />
      </div>
    </Button>
  ) : undefined;

  const elBody = !isLoggingOut && !isErrorState && (
    <div>
      {elLogo}
      <div css={styles.content}>
        <div css={styles.top}>
          {elHome}
          <ModuleDropdownContainer variant={'QUERY_BUILDER'} />
          <div css={styles.queryBuilderOuter}>
            <QueryBuilderContainer data={queryData} />
          </div>
        </div>
        <div css={styles.main}>
          <ShellSheetsContainer
            dashboardV2Templates={dashboardV2Templates}
            isLoading={isSheetsLoading}
          />
        </div>
      </div>
    </div>
  );

  const elLoggingOut = isLoggingOut && !isErrorState && (
    <div css={styles.logoutOuter}>
      <div css={styles.logoutInner}>
        <Spinner color={COLORS.WHITE} center={true} />
        <Text color={COLORS.WHITE} style={styles.logoutText}>
          {'Logging out...'}
        </Text>
      </div>
    </div>
  );

  return (
    <ErrorBoundaryShell isErrorOverride={isErrorState}>
      <div css={styles.base}>
        {elBody}
        {elLoggingOut}
      </div>
    </ErrorBoundaryShell>
  );
};

const styles = {
  base: css({
    position: 'absolute',
    inset: 0,
  }),
  logo: css({
    position: 'absolute',
    inset: 'auto 0 0 auto',
  }),
  content: css({
    position: 'absolute',
    inset: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  }),
  top: css({
    boxSizing: 'border-box',
    minHeight: 42,
    margin: 10,
    zIndex: 12,
    gap: 10,
    display: 'flex',
    flexDirection: 'row',
  }),
  main: css({
    boxSizing: 'border-box',
    flex: '1 1 auto',
    display: 'flex',
  }),
  logoutOuter: css({
    position: 'absolute',
    inset: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: COLORS.WHITE,
    marginBottom: '5%',
  }),
  button: css({
    maxHeight: 42,
  }),
  homeIcon: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    border: `solid 1px ${color.format(0.15)}`,
    borderRadius: 2,
    backgroundColor: color.format(0.24),
  }),
  queryBuilderOuter: css({
    flexGrow: 1,
  }),
  logoutInner: css({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: 45,
  }),
  logoutText: css({
    paddingTop: 20,
  }),
};
