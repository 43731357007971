/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Skeleton } from '@seeeverything/ui.primitives/src/components/Skeleton/Skeleton.tsx';

export const ActionSkeleton: React.FC = () => (
  <div css={styles.base}>
    <div css={styles.row}>
      <div css={styles.left}>
        <Skeleton
          height={114}
          width={'100%'}
          animation={'wave'}
          style={styles.skeleton}
        />
      </div>
      <div css={styles.right}>
        <Skeleton
          height={52}
          width={'100%'}
          animation={'wave'}
          style={styles.skeleton}
        />
        <Skeleton
          height={52}
          width={'100%'}
          animation={'wave'}
          style={styles.skeleton}
        />
      </div>
    </div>
    <div css={styles.row}>
      <div css={styles.left}>
        <Skeleton
          height={52}
          width={'100%'}
          animation={'wave'}
          style={styles.skeleton}
        />
      </div>
      <div css={styles.right}>
        <Skeleton
          height={52}
          width={'100%'}
          animation={'wave'}
          style={styles.skeleton}
        />
      </div>
    </div>
    <div css={styles.row}>
      <Skeleton
        height={135}
        width={'100%'}
        animation={'wave'}
        style={styles.skeleton}
      />
    </div>
  </div>
);

const styles = {
  base: css({
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
  }),
  row: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '20px 0 10px 0',
    gap: 20,
  }),
  left: css({
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 65%',
    width: '100%',
    gap: 10,
  }),
  right: css({
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 35%',
    width: '100%',
    gap: 10,
  }),
  skeleton: css({
    transform: 'none',
  }),
};
