/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ActionBar } from '@seeeverything/ui.primitives/src/components/ModalDialog/index.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TreeItem } from '@seeeverything/ui.primitives/src/components/TreeView/components/TreeViewItem.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import moment from 'moment';
import { useMemo } from 'react';
import * as csvExportActions from '../../redux/csvExport/actions.ts';
import { CsvExportFilter } from '../../redux/csvExport/types.ts';
import { hideModalDialog } from '../../redux/modalDialog/actions.ts';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import { CsvDialog } from './CsvDialog.tsx';

export const CsvDialogContainer: React.FC = () => {
  const dispatch = useShellDispatch();

  const endDate = useShellSelector((state) => state.csvExport.endDate);
  const startDate = useShellSelector((state) => state.csvExport.startDate);

  const selectedFilters = useShellSelector(
    (state) => state.csvExport.selectedFilters,
  );

  const templates = useShellSelector((state) => state.csvExport.templates);
  const categories = useShellSelector((state) => state.csvExport.categories);

  const isLoading = useShellSelector((state) => state.csvExport.isLoading);

  const filtersFromTemplatesAndCategories = useMemo((): TreeItem[] => {
    const categoriesWithActiveTemplates = categories.filter((category) =>
      templates.some(
        (template) =>
          template.parentCategoryId === category.id &&
          template.status !== 'Archived',
      ),
    );

    const categoriesForArchivedTemplates = [] as CsvExportFilter[];
    templates
      .filter((template) => template.status === 'Archived')
      .forEach((template) => {
        const categoryAlreadyAdded = categoriesForArchivedTemplates.some(
          (category) => category.id === template.parentCategoryId,
        );

        if (!categoryAlreadyAdded) {
          categoriesForArchivedTemplates.push({
            id: template.parentCategoryId,
            name: template.parentCategoryName,
          });
        }
      });

    const categoriesInTree = categoriesWithActiveTemplates.map(
      (category): TreeItem => ({
        id: category.id,
        label: (
          <Text
            size={14}
            color={'inherit'}
            ellipsis={true}
            block={true}
            cursor={'pointer'}
          >
            {category.name}
          </Text>
        ),
        children: templates
          .filter(
            (template) =>
              template.parentCategoryId === category.id &&
              template.status !== 'Archived',
          )
          .map(
            (template): TreeItem => ({
              id: template.id,
              label: (
                <Text
                  size={14}
                  color={'inherit'}
                  ellipsis={true}
                  block={true}
                  cursor={'pointer'}
                >
                  {template.name}
                </Text>
              ),
            }),
          ),
      }),
    );

    const archivedCategoriesInTree = categoriesForArchivedTemplates.map(
      (category): TreeItem => ({
        id: `ARCHIVED-${category.id}`,
        label: (
          <Text
            size={14}
            color={color.format(-0.5)}
            italic={true}
            ellipsis={true}
            block={true}
            cursor={'pointer'}
          >
            {category.name}
          </Text>
        ),
        children: templates
          .filter(
            (template) =>
              template.status === 'Archived' &&
              template.parentCategoryId === category.id,
          )
          .sort((first, second) =>
            first.updatedAt < second.updatedAt ? 1 : -1,
          )
          .map((template): TreeItem => {
            const updatedAtFormatted = moment(template.updatedAt)
              .parseZone()
              .format('D MMM YY');

            return {
              id: template.id,
              label: (
                <Text
                  size={14}
                  color={color.format(-0.5)}
                  italic={true}
                  ellipsis={true}
                  block={true}
                  cursor={'pointer'}
                >
                  {`${template.name}, archived ${updatedAtFormatted}`}
                </Text>
              ),
            };
          }),
      }),
    );

    if (archivedCategoriesInTree?.length > 0) {
      categoriesInTree.push({
        id: 'ARCHIVED',
        label: (
          <Text
            size={14}
            color={color.format(-0.5)}
            italic={true}
            ellipsis={true}
            block={true}
            cursor={'pointer'}
          >
            {`Archived Templates`}
          </Text>
        ),
        children: archivedCategoriesInTree,
      });
    }

    return [
      {
        id: 'ALL',
        label: (
          <Text
            size={14}
            color={'inherit'}
            ellipsis={true}
            block={true}
            cursor={'pointer'}
          >
            {'All'}
          </Text>
        ),
        children: categoriesInTree,
      },
    ];
  }, [categories, templates]);

  return (
    <div css={styles.base}>
      <CsvDialog
        onSelectFilter={(to) =>
          dispatch(csvExportActions.selectedFiltersChanged(to))
        }
        onStartDateChange={(to) =>
          dispatch(csvExportActions.csvExportChangeStartDate(to))
        }
        onEndDateChange={(to) =>
          dispatch(csvExportActions.csvExportChangeEndDate(to))
        }
        startDate={startDate}
        endDate={endDate}
        selectedFilters={selectedFilters}
        loadingFilters={isLoading}
        filters={filtersFromTemplatesAndCategories}
      />
      <ActionBar
        actions={[
          {
            id: 'Cancel',
            label: 'Cancel',
            isEnabled: true,
            onAction: () => dispatch(hideModalDialog()),
          },
          {
            id: 'OK',
            label: 'OK',
            isEnabled: !isLoading,
            onAction: () => dispatch(csvExportActions.csvExportConfirmed()),
          },
        ]}
      />
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
};
