import { automatedActionConfigurationSlice } from '@seeeverything/ui.forms/src/redux/automatedActionConfiguration/index.ts';
import { showStatusBar } from '@seeeverything/ui.shell/src/redux/sheets/actions.ts';
import { SheetToolbarDropdownClickAction } from '@seeeverything/ui.shell/src/redux/sheets/types.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { combineEpics, ofType } from 'redux-observable';
import { Observable, concatAll, filter, from, map } from 'rxjs';
import { GlobalAppEpicDependencies, GlobalAppState } from '../../../types.ts';

export const automatedActionEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalAppState,
  GlobalAppEpicDependencies
>(toggleShowAllEpic, exportToCsvEpic, exportToCsvFailedEpic);

function toggleShowAllEpic(
  action$: Observable<SheetToolbarDropdownClickAction>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_DROPDOWN_CLICK'),
    filter(
      (action) =>
        action.payload.sheet.type === 'SETTINGS_AUTOMATED_ACTIONS' &&
        action.payload.itemId === 'ShowHistorical',
    ),
    map(() => automatedActionConfigurationSlice.toggleShowAll()),
  );
}

function exportToCsvEpic(action$: Observable<SheetToolbarDropdownClickAction>) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_DROPDOWN_CLICK'),
    filter(
      (action) =>
        action.payload.sheet.type === 'SETTINGS_AUTOMATED_ACTIONS' &&
        action.payload.itemId === 'Export',
    ),
    map(() =>
      from([
        showStatusBar(
          'INFO',
          'A CSV file is being generated and will be downloaded by your browser automatically. This may take a few minutes.',
        ),
        automatedActionConfigurationSlice.exportToCsv(),
      ]),
    ),
    concatAll(),
  );
}

function exportToCsvFailedEpic(
  action$: Observable<SheetToolbarDropdownClickAction>,
) {
  return action$.pipe(
    filter(automatedActionConfigurationSlice.exportToCsvResult.match),
    filter((action) => !action.payload.isSuccess),
    map(() =>
      showStatusBar(
        'ERROR',
        'There was a problem with downloading the export CSV file. Please try again later.',
      ),
    ),
  );
}
