/** @jsxImportSource @emotion/react */
import {
  IFormInstance,
  Signature,
} from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import { appealSlice } from '@seeeverything/ui.forms/src/redux/formInstanceAppeal/index.ts';
import { getUserAppealPermissions } from '@seeeverything/ui.forms/src/util/util.instance.ts';
import { hideModalDialog } from '../../redux/modalDialog/actions.ts';
import { useShellDispatch } from '../../redux/store.ts';
import { TextInputConfirmationDialog } from './TextInputConfirmationDialog.tsx';

export type AppealConfirmationDialogProps = {
  instance: IFormInstance;
  personId: string;
  signature: Signature;
};

export const AppealConfirmationDialog: React.FC<
  AppealConfirmationDialogProps
> = ({ instance, personId, signature }) => {
  const dispatch = useShellDispatch();

  return (
    <TextInputConfirmationDialog
      title={'Appeal - Confirmation'}
      description={
        getUserAppealPermissions(instance, personId).canAppealAsManager
          ? 'This will request an appeal on the outcome. Are you sure you want to do this?'
          : 'This will request an appeal on the outcome. Ensure you have agreement from your manager in advance. Are you sure you want to do this?'
      }
      inputLabel={'Appeal Reason (Required)'}
      inputError={'You must specify a reason for appeal.'}
      onActionOK={(request) => {
        dispatch(hideModalDialog());
        dispatch(
          appealSlice.serverAppeal({
            instanceId: instance.id,
            signature,
            request,
          }),
        );
      }}
      onActionCancel={() => dispatch(hideModalDialog())}
    />
  );
};
