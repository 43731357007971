/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem, TreeViewItem } from './components/TreeViewItem.tsx';
import { TreeViewSkeleton } from './components/TreeViewSkeleton.tsx';

export type TreeViewProps = {
  isLoading?: boolean;
  items: TreeItem[];
  onItemSelect: (selectedIds: string[]) => void;
  selected?: string[];
};

/**
 * Renders a hierarchy of selectable items in a tree/directory structure.
 */
export const TreeView: React.FC<TreeViewProps> = ({
  isLoading,
  items,
  onItemSelect,
  selected,
}) => (
  <div css={styles.base}>
    {isLoading ? (
      <TreeViewSkeleton />
    ) : (
      <SimpleTreeView
        selectedItems={selected}
        multiSelect={true}
        onSelectedItemsChange={(_, itemIds) => onItemSelect?.(itemIds)}
      >
        {items.map((item) => (
          <TreeViewItem key={item.id} item={item} />
        ))}
      </SimpleTreeView>
    )}
  </div>
);

const styles = {
  base: css({
    flex: '1 1 auto',
    height: 500,
    marginTop: 10,
    marginBottom: 10,
    overflow: 'auto',
  }),
};
