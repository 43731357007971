/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { propsAreEqualFastCompare } from '@seeeverything/ui.util/src/react/memoFastCompare.ts';
import { scrollSlice } from '@seeeverything/ui.util/src/redux/scroll/index.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { memo, useCallback } from 'react';
import { useShellDispatch, useShellSelector } from '../../../../redux/store.ts';
import { Badge } from '../Badge.tsx';

const INDEX_IN_VIEW_BG = color.create(COLORS.BLUE).alpha(0.1).css();
const INDEX_IN_VIEW_HOVER_BG = color.create(COLORS.BLUE).alpha(0.2).css();

export interface ISheetIndexItemContainerProps {
  error?: boolean;
  icon?: string;
  iconColor?: number | string;
  id: string;
  label: string;
  labelColor?: number | string;
  status?: number | string;
  scrollOffset: number;
  scrollSmooth: boolean;
  type: 'ROOT' | 'CHILD';
}

/**
 * A single item within the Sheet navigation index.
 */
const View: React.FC<ISheetIndexItemContainerProps> = ({
  error,
  icon,
  iconColor = -0.4,
  id,
  label,
  labelColor = -0.5,
  scrollOffset,
  scrollSmooth,
  status,
  type = 'ROOT',
}) => {
  const dispatch = useShellDispatch();
  const isContentViewport = useShellSelector((state) => {
    const referenceCount = state.scroll.dataIdInViewReferenceCount[id] ?? 0;
    return referenceCount > 0;
  });

  const scrollToIndexItem = useCallback(
    () =>
      dispatch(
        scrollSlice.scrollToDataId({
          scrollContainerId: 'scrollPanel',
          dataId: id,
          offsetPx: scrollOffset,
          smooth: scrollSmooth,
        }),
      ),
    [dispatch, id, scrollOffset, scrollSmooth],
  );

  const Icon = icon && Icons[icon];
  const elIcon = Icon && <Icon fill={color.format(iconColor)} />;

  const elBadge = status && (
    <Badge value={status} type={error ? 'error' : 'default'} />
  );

  return (
    <Button
      style={styles.button(isContentViewport)}
      onClick={scrollToIndexItem}
    >
      <>
        <div css={styles.icon}>{elIcon}</div>
        <div css={styles.label}>
          {type === 'ROOT' && (
            <Text
              cursor={'pointer'}
              style={styles.textBase}
              selectable={false}
              uppercase={true}
              weight={FontWeight.black}
              size={13}
              color={labelColor}
            >
              {label}
            </Text>
          )}
          {type === 'CHILD' && (
            <Text
              cursor={'pointer'}
              style={styles.textBase}
              selectable={false}
              weight={FontWeight.normal}
              size={12}
              color={labelColor}
            >
              {label}
            </Text>
          )}
          {elBadge}
        </div>
      </>
    </Button>
  );
};

const styles = {
  button: (isIndexInView = false) =>
    css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'stretch',
      flex: '1 1 auto',
      cursor: 'pointer',
      background: isIndexInView ? INDEX_IN_VIEW_BG : undefined,
      boxShadow: isIndexInView ? `inset 5px 0 0 0 ${COLORS.BLUE}` : undefined,
      transition: 'background-color 400ms', // Override the transition in a <Button /> to only transition on the background color.
      padding: '5px 10px 5px 5px',
      borderRadius: 0,
      '&:hover': {
        backgroundColor: isIndexInView
          ? INDEX_IN_VIEW_HOVER_BG
          : color.format(-0.06),
      },
    }),
  icon: css({
    width: 45,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  label: css({
    flex: '1 1 0',
    top: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'stretch',
    minHeight: 30,
  }),
  textBase: css({ flex: '1 1 auto' }),
};

export const SheetIndexItemContainer = memo(View, propsAreEqualFastCompare);
