/** @jsxImportSource @emotion/react */
import { AlertDialog } from '@seeeverything/ui.primitives/src/components/AlertDialog/AlertDialog.tsx';
import { hideAlertDialog } from '../../redux/sheets/actions.ts';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';

export const AlertDialogContainer: React.FC = () => {
  const dispatch = useShellDispatch();

  const alertDialogId = useShellSelector((state) => state.sheets.alertDialogId);

  return (
    <AlertDialog
      onConfirmClick={() => dispatch(hideAlertDialog(alertDialogId, 'Confirm'))}
      onCancelClick={() => dispatch(hideAlertDialog(alertDialogId, 'Cancel'))}
      isVisible={Boolean(alertDialogId)}
      title={'Are you sure you want to continue?'}
      bodyText={'Your changes will not be saved.'}
    />
  );
};
