import { Person } from '@se/data/orgHierarchy/types.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import gql from 'graphql-tag';
import { PersonSelection, PersonSubject, TeamSubject } from './types.ts';

type Response = {
  orgHierarchy: {
    person: {
      lineManager?: Person;
      primaryMemberTeam?: {
        name: string;
        managers: {
          nodes: Array<Person>;
        };
      };
    };
  };
};

export const useSubjectPersonQuery = (
  client: IGraphQLClient,
  instanceLabel: string,
  subject?: PersonSubject | TeamSubject,
) => {
  const { data, isFetching } = useQuery({
    placeholderData: keepPreviousData,
    enabled: subject?.type === 'PERSON',
    queryKey: [{ key: 'peopleDropdown.subjectPerson', subject }],
    queryFn: async () => {
      const response = await client.query<Response>({
        query: gql`
          query DropdownSubjectPersonManagers($personId: ID!) {
            orgHierarchy {
              person(id: $personId) {
                email
                firstName
                id
                isActive
                lastName
                positionTitle
                primaryMemberTeam {
                  id
                  name
                  path
                  businessUnit
                  managers {
                    nodes {
                      id
                      email
                      firstName
                      lastName
                      positionTitle
                      isActive
                    }
                  }
                }
                lineManager {
                  email
                  firstName
                  id
                  isActive
                  lastName
                  positionTitle
                }
              }
            }
          }
        `,
        variables: { personId: subject.id },
        fetchPolicy: 'cache-first',
      });

      const person = response.data.orgHierarchy.person;

      const primaryTeam = person.primaryMemberTeam;
      const primaryTeamManagers = primaryTeam?.managers.nodes ?? [];

      const categoryGrouping = `Related to ${instanceLabel}`;

      return [
        person.lineManager?.isActive &&
          toManagerItem(
            {
              id: person.lineManager.id,
              email: person.lineManager.email,
              firstName: person.lineManager.firstName,
              isActive: person.lineManager.isActive,
              lastName: person.lineManager.lastName,
              positionTitle: person.lineManager.positionTitle,
              type: 'LINE_MANAGER',
            },
            subject.label,
            categoryGrouping,
          ),
        ...primaryTeamManagers.map((teamManager) =>
          toManagerItem(
            {
              id: teamManager.id,
              email: teamManager.email,
              firstName: teamManager.firstName,
              isActive: teamManager.isActive,
              lastName: teamManager.lastName,
              positionTitle: teamManager.positionTitle,
              type: 'TEAM_MANAGER',
            },
            primaryTeam.name,
            categoryGrouping,
          ),
        ),
      ].filter(Boolean);
    },
  });

  return {
    subjectPersonManagers: data,
    isFetchingSubjectPersonManagers: isFetching,
  };
};

const toManagerItem = (
  person: PersonSelection,
  subjectLabel: string,
  category: string,
): ISelectionListItem<IListItemLabel, PersonSelection> => {
  const name = `${person.firstName} ${person.lastName}`.trim();

  return {
    id: person.id,
    icon: Icons.supervisorAccount,
    value: person,
    category,
    content: {
      description: [person.positionTitle, person.email]
        .filter(Boolean)
        .join(' - '),
      text: name,
      secondaryText: `(Manager of ${subjectLabel})`,
    },
  };
};
