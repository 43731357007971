/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormsBulkUploadWorkflowType } from '@se/data/forms/types.ts';
import { RadioGroup } from '@seeeverything/ui.primitives/src/components/CheckboxRadioGroup/index.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Switch } from '@seeeverything/ui.primitives/src/components/Switch/Switch.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useTenantLocale } from '@seeeverything/ui.primitives/src/hooks/useTenantLocale.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useCallback } from 'react';

export type WorkflowTabProps = {
  onChange: (selectedId: FormsBulkUploadWorkflowType) => void;
  onCreateAutomatedActionsChange: (to: boolean) => void;
  createAutomatedActions: boolean;
  includeAutomatedActions: boolean;
};

export const WorkflowTab: React.FC<WorkflowTabProps> = ({
  onChange,
  onCreateAutomatedActionsChange,
  createAutomatedActions,
  includeAutomatedActions,
}) => {
  const { formLabel, formAssignedToLabel } = useTenantLocale((l) => ({
    formLabel: l.label.form,
    formAssignedToLabel: l.label.formAssignedTo,
  }));

  const handleCreateSmartActionChange = useCallback(() => {
    if (!includeAutomatedActions) return;
    onCreateAutomatedActionsChange(!createAutomatedActions);
  }, [
    createAutomatedActions,
    includeAutomatedActions,
    onCreateAutomatedActionsChange,
  ]);

  const elCreateSmartAction = includeAutomatedActions && (
    <div
      css={styles.createSmartActions}
      onClick={handleCreateSmartActionChange}
    >
      <div css={styles.createSmartActionsTitle}>
        <Text style={styles.title}>{'Create configured Smart Actions?'}</Text>
        <Icons.info
          fill={color.format(-0.6)}
          tooltip={`Smart Actions are automated remediation actions, based on any issues that have been identified in the review.
            These can be configured as appropriate for your organization. Note that any actions with placeholder text will not be created.`}
        />
      </div>
      <div>
        <Switch
          isChecked={createAutomatedActions}
          isEnabled={true}
          label={createAutomatedActions ? 'Yes' : 'No'}
          labelStyle={{ marginRight: 0 }}
        />
      </div>
    </div>
  );

  return (
    <div css={styles.base}>
      {elCreateSmartAction}
      <div>
        <Text style={styles.title}>{'Select Workflow'}</Text>
        <div css={styles.radioGroup}>
          <RadioGroup
            options={[
              {
                id: 'Default',
                label: `${formAssignedToLabel} to complete the ${formLabel}`,
              },
              {
                id: 'SignOffAssignedTo',
                label: `Sign off on behalf of the ${formAssignedToLabel}`,
              },
            ]}
            isEnabled={true}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    marginTop: 10,
  }),
  title: css({
    fontSize: 16,
    fontWeight: 900,
    width: '100%',
    paddingLeft: 10,
  }),
  radioGroup: css({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 30,
  }),
  createSmartActions: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    ':hover': { backgroundColor: color.format(-0.1) },
    paddingRight: 10,
  }),
  createSmartActionsTitle: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  }),
};
