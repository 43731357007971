/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DashboardTimespanVariable } from '@se/data/dashboards/query/types.ts';
import { useQueryDashboardCoachingSummaryInsights } from '@se/data/forms/hooks/useQueryDashboardCoachingSummaryInsights.ts';
import { CoachingSummaryError } from '@seeeverything/ui.forms/src/components/FormLineCoachingSummary/components/CoachingSummaryError.tsx';
import { CoachingSummaryHighlightGroup } from '@seeeverything/ui.forms/src/components/FormLineCoachingSummary/components/highlights/CoachingSummaryHighlightGroup.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { SkeletonDetailedItemsList } from '@seeeverything/ui.primitives/src/components/SkeletonDetailedItemsList/SkeletonDetailedItemsList.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useMeasure } from '@seeeverything/ui.primitives/src/hooks/useMeasure.ts';
import { useTenantModule } from '@seeeverything/ui.primitives/src/hooks/useTenantModule.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { groupBy, prop } from 'ramda';
import { useCallback, useEffect, useState } from 'react';

export type HighlightsContainerProps = {
  highlightListId: string;
  entityId: string;
  entityType: 'person' | 'team';
  inViewport: boolean;
  timespan: DashboardTimespanVariable;
};

export const HighlightsContainer: React.FC<HighlightsContainerProps> = ({
  highlightListId,
  entityId,
  entityType,
  inViewport,
  timespan,
}) => {
  const module = useTenantModule();

  const [isQueryEnabled, setIsQueryEnabled] = useState(inViewport);
  useEffect(() => {
    if (inViewport && !isQueryEnabled) setIsQueryEnabled(true);
  }, [inViewport, isQueryEnabled]);

  const {
    insights: highlights,
    isLoadingInsights: isLoadingHighlights,
    isFetchingInsights: isFetchingHighlights,
    isErrorInsights: isErrorHighlights,
    refetchInsights: refetchHighlights,
  } = useQueryDashboardCoachingSummaryInsights({
    cacheId: `${module}-${highlightListId}-${entityId}`,
    entityId,
    entityType: entityType === 'person' ? 'Person' : 'Team',
    sectionType: 'HighlightsIdentified',
    timespan,
    isQueryEnabled,
  });

  const [measureRef, highlightsSize] = useMeasure({
    remeasureDependency: isLoadingHighlights || isFetchingHighlights,
  });

  const [showLess, setShowLess] = useState<boolean>(false);
  const [userShowMore, setUserShowMore] = useState<boolean>(false);

  useEffect(() => {
    if (highlights?.length === 0) {
      setShowLess(false);
      return undefined;
    }
    if (!highlightsSize) return undefined;

    if (!userShowMore && highlightsSize.height < 199) setShowLess(false);
    if (!userShowMore && highlightsSize.height >= 199) setShowLess(true);
  }, [highlights?.length, highlightsSize, userShowMore]);

  const handleShowMore = useCallback(() => {
    setShowLess(false);
    setUserShowMore(true);
  }, []);

  const highlightGroups = Object.entries(groupBy(prop('label'), highlights));

  const elHighlights = !isErrorHighlights &&
    isQueryEnabled &&
    !isLoadingHighlights &&
    highlights.length > 0 && (
      <div ref={measureRef} css={styles.groups}>
        {highlightGroups.map(([label, group]) => (
          <CoachingSummaryHighlightGroup
            key={label}
            label={group[0].label}
            highlights={group}
            sectionExpanded={false}
            onAccordionChange={handleShowMore}
            size={'small'}
          />
        ))}
      </div>
    );

  return (
    <div
      css={[
        styles.base,
        showLess ? styles.showLess : undefined,
        isFetchingHighlights ? styles.mask : undefined,
      ]}
    >
      {isLoadingHighlights === true && (
        <SkeletonDetailedItemsList
          itemStyle={styles.skeletonList}
          numberOfSkeletonItems={3}
        />
      )}
      {isErrorHighlights && (
        <CoachingSummaryError
          type={'highlights'}
          onRetryLoad={refetchHighlights}
        />
      )}
      {elHighlights}
      {!isErrorHighlights &&
        isQueryEnabled &&
        !isLoadingHighlights &&
        highlights.length === 0 && (
          <Text color={color.format(-0.65)} size={14}>
            {'There are no recent highlights to show.'}
          </Text>
        )}
      {showLess && (
        <div css={styles.expand} onClick={handleShowMore}>
          <Text color={COLORS.BLUE} cursor={'pointer'} size={14}>
            {'Show more'}
          </Text>
          <Icons.arrowDropDown size={40} cursor={'pointer'} />
        </div>
      )}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    position: 'relative',
    overflow: 'hidden',
  }),
  showLess: css({
    maxHeight: 240,
    cursor: 'pointer',
  }),
  expand: css({
    position: 'absolute',
    inset: 'auto 0 0 0',
    height: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderTop: 'solid 6px #E5E5E5',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fafafa',
    },
  }),
  groups: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  mask: css({
    opacity: 0.6,
  }),
  skeletonList: css({
    margin: 8,
  }),
};
