/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Spacer } from '@seeeverything/ui.primitives/src/components/Spacer/Spacer.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ChipKey,
  IEntityDictionary,
} from '../../../../api/api.queryBuilder/types.ts';
import { deserialize } from '../../common/serialization.ts';
import {
  addChipFromInput,
  deleteLastChip,
  handleChipDelete,
  handleChipFinishedEditing,
  handleChipSelect,
  handleChipShowDropdown,
  handleChipStartEditing,
  handleTextInputStartEditing,
  handleTextInputStopEditing,
  selectNext,
  selectPrev,
  updateChipValue,
  updateTextInput,
} from '../../common/state/actions.ts';
import {
  IInternalState,
  IQueryBuilderAction,
  OnChangeHandler,
} from '../../types.ts';
import { QueryChip } from '../QueryChip/QueryChip.tsx';
import { ChipChangeEventHandler } from '../QueryChip/types.ts';
import {
  ChipEditEventHandler,
  DropdownLocationChangeEventHandler,
  FormatChipEventHandler,
  ShowDropdownEventHandler,
} from './types.ts';

export interface IQueryBuilderInputRefProps {
  focus: () => void;
  blur: () => void;
  containsFocus: () => boolean;
}

export interface IQueryBuilderInputProps {
  isEnabled?: boolean;
  state?: IInternalState;
  entities: IEntityDictionary;
  onChange: OnChangeHandler;
  onClick?: () => void;
  onMouseDown?: () => void;
  isVisuallyFocused?: boolean; // Show or hide the input (i.e. opaque vs. transparent)
  isFocused?: boolean; // Controls when the input will respond to key events.

  onFocus?: () => void;
  onFormatChip?: FormatChipEventHandler;
  onDropdownLocationChange?: DropdownLocationChangeEventHandler;
  onShowDropdown?: ShowDropdownEventHandler;
  onChipStartedEditing?: ChipEditEventHandler;
  onChipStoppedEditing?: ChipEditEventHandler;
}

/**
 * The input of the Query Builder responsible for rendering chips and the textual input. Not responsible for rendering
 * autocomplete.
 */
const View: React.ForwardRefRenderFunction<
  IQueryBuilderInputRefProps,
  IQueryBuilderInputProps
> = (
  {
    entities,
    isEnabled = true,
    isFocused,
    isVisuallyFocused,
    onChange,
    onChipStartedEditing,
    onChipStoppedEditing,
    onClick,
    onDropdownLocationChange,
    onFocus,
    onFormatChip,
    onMouseDown,
    onShowDropdown,
    state: propsState,
  },
  ref,
) => {
  const chipRefs = useRef<Record<number, QueryChip>>({});

  const containerRef = useRef<HTMLDivElement>(null);
  const textInputRef = useRef<HTMLInputElement>(null);

  const [currentDropdownPos, setCurrentDropdownPos] = useState<{
    left: number;
    top: number;
  }>();

  const emptyState = useMemo(() => deserialize({ entities }), [entities]);
  const state = useMemo(
    () => propsState || emptyState,
    [emptyState, propsState],
  );

  const [stateTextInputFocused, setStateTextInputFocused] = useState(
    state.textInputFocused,
  );

  /**
   * Determines whether focus exists within the input, or a chip.
   */
  const hasFocus = useCallback(
    () => Boolean(containerRef.current?.contains(document.activeElement)),
    [],
  );

  /**
   * Focuses the text input.
   */
  const focus = useCallback(
    (force = false) => {
      if ((force || !hasFocus()) && textInputRef.current) {
        textInputRef.current.focus();
      }
    },
    [hasFocus],
  );

  /**
   * Force blurs the text input.
   */
  const blur = useCallback(() => {
    textInputRef.current?.blur();
  }, []);

  useImperativeHandle(ref, () => ({
    focus,
    blur,
    containsFocus: hasFocus,
  }));

  // Ensure input is focused when specified within an incoming property change.
  useEffect(() => {
    if (state.textInputFocused && !stateTextInputFocused) {
      focus();
      setStateTextInputFocused(true);
    }
  }, [focus, state, stateTextInputFocused]);

  // When moving from a selected chip to no selection,
  // ensure the filter-text input is focused.
  useEffect(() => {
    if (!hasFocus()) return;
    if (state?.selectedChip !== undefined) return;
    if (state?.editingChip !== undefined) return;
    if (!isLastChipSelected(state)) return;
    setTimeout(() => {
      focus(true);
    }, 0);
  }, [focus, hasFocus, state]);

  useEffect(() => {
    if (!onDropdownLocationChange) return;

    let top: number | undefined = undefined;
    let left: number | undefined = undefined;

    const editingOrSelectedChipKey =
      (state.editingChip && state.editingChip.key) ||
      (state.selectedChip && state.selectedChip.key);

    if (editingOrSelectedChipKey !== undefined) {
      // Position the dropdown around the editing/selected chip.
      const chip = chipRefs.current?.[editingOrSelectedChipKey];
      const chipPosition = chip?.getPosition();
      if (chipPosition) {
        top = chipPosition.bottom; // Bottom of chip = top of autocomplete.
        left = chipPosition.left;
      }
    } else {
      // Position the dropdown around the text-filter.
      if (textInputRef) {
        const boundingClient = textInputRef.current.getBoundingClientRect();
        top = Math.round(boundingClient.bottom); // Bottom of input = top of autocomplete
        left = Math.round(boundingClient.left);
      }
    }

    // Account for container position.
    if (containerRef.current && top && left) {
      const containerBoundingClientRect =
        containerRef.current.getBoundingClientRect();

      left -= containerBoundingClientRect.left;
      top -= containerBoundingClientRect.top;

      left = Math.round(left);
      top = Math.round(top);

      if (
        !currentDropdownPos ||
        left !== currentDropdownPos.left ||
        top !== currentDropdownPos.top
      ) {
        const position = { left, top };
        setCurrentDropdownPos(position);
        onDropdownLocationChange({ position });
      }
    }
  }, [currentDropdownPos, state, onDropdownLocationChange]);

  /**
   * Helper function to create change handlers, to be passed down to child components.
   * Enforces immutability.
   */
  const changeHandler = createChangeHandler(
    () => state,
    (nextState) => onChange(nextState),
  );

  const handleTextInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const newState = updateTextInput(state, event.currentTarget.value);
    onChange(newState);
  };

  const handleQueryChipChange: ChipChangeEventHandler = changeHandler(
    (nextState, data) =>
      updateChipValue(nextState, data.key, data.value, data.label),
  );

  const handleQueryChipSelect = changeHandler((nextState, chipKey: ChipKey) => {
    if (!isVisuallyFocused && onFocus) {
      onFocus();
    }
    return handleChipSelect(nextState, chipKey);
  });

  const handleQueryChipStartEditing = changeHandler(
    (nextState, chipKey: ChipKey) => {
      const { index, chip } = findChip(nextState, chipKey);
      let isCancelled = false;
      if (onChipStartedEditing && chip) {
        onChipStartedEditing({
          index,
          chip,
          cancel: () => (isCancelled = true),
        });
      }

      return isCancelled
        ? nextState
        : handleChipStartEditing(nextState, chipKey);
    },
  );

  const handleQueryChipStopEditing = changeHandler(
    (nextState, chipKey: ChipKey) => {
      const { index, chip } = findChip(nextState, chipKey);
      let isCancelled = false;
      if (onChipStoppedEditing && chip) {
        onChipStoppedEditing({
          index,
          chip,
          cancel: () => (isCancelled = true),
        });
      }

      setTimeout(() => focus(true), 0);

      return isCancelled
        ? handleChipFinishedEditing(state)
        : handleChipFinishedEditing(nextState);
    },
  );

  const handleQueryChipShowDropdown = changeHandler(
    (nextState, chipKey: ChipKey) => {
      const { index, chip } = findChip(nextState, chipKey);
      if (onShowDropdown && chip) {
        onShowDropdown({ index, chip });
      }
      return handleChipShowDropdown(nextState, chipKey);
    },
  );
  const handleQueryChipDelete = changeHandler((nextState, chipKey: ChipKey) =>
    handleChipDelete(nextState, chipKey),
  );

  const handleQueryChipNext = changeHandler(selectNext);
  const handleQueryChipPrev = changeHandler(selectPrev);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // TODO: Meta keys
    const key = e.key;
    if (key === 'ArrowUp') {
      // Prevent the up arrow moving the caret to the beginning of the filter text.
      e.preventDefault();
    }
    if (key === ':') {
      e.preventDefault();
      performAction(addChipFromInput);
      return;
    }
    if (key === 'Backspace') {
      if (e.currentTarget.value.length === 0) {
        e.preventDefault();
        performAction(deleteLastChip);
      }
    }

    // Don't respond to meta events when the input is not focused.
    if (!isFocused) {
      const META_KEYS = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
      if (META_KEYS.includes(key)) {
        e.preventDefault();
        return;
      }
    }
    if (key === 'ArrowLeft') {
      const isCursorAtBeginningOfInput = e.currentTarget.selectionStart === 0;
      if (isCursorAtBeginningOfInput) {
        e.preventDefault();
        performAction(selectPrev);
      }
    }
    if (key === 'ArrowDown') {
      e.preventDefault();
      e.stopPropagation(); // Stop the global event helpers picking up this event.
    }
  };

  const handleTextInputFocus = () => {
    if (onFocus) {
      onFocus();
    }
    changeHandler(handleTextInputStartEditing)(state);
  };

  const handleTextInputBlur = () => {
    changeHandler(handleTextInputStopEditing)(state);
  };

  const performAction = changeHandler(
    (nextState, action: IQueryBuilderAction) => action(nextState),
  );

  const computedStyles = {
    base: css({
      opacity: isEnabled ? 1 : 0.3,
    }),
    textInput: css({
      color: isFocused ? 'black' : 'white',
    }),
  };

  const chips = isEnabled && isFocused ? state.chips : state.chips.slice(-3);

  return (
    <div
      data-test={'shell-queryBuilderInput-field'}
      ref={containerRef}
      css={[styles.base, computedStyles.base]}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {!isFocused && state.chips.length > 3 && (
        <div css={styles.moreChips}>
          <Text selectable={false} cursor={'pointer'} color={'white'} size={14}>
            {`+ ${state.chips.length - 3} more`}
          </Text>
        </div>
      )}
      {chips.map((chip, index) => {
        const display = onFormatChip?.({ index, chip: chip.data }) || {
          label: chip.data.label,
        };

        const isSelected =
          (state.selectedChip && state.selectedChip.key) === chip.key;
        const isEditing =
          (state.editingChip && state.editingChip.key) === chip.key;

        return (
          <Spacer paddingRight={2} key={chip.key}>
            <QueryChip
              ref={(r) => {
                chipRefs.current[chip.key] = r;
              }}
              entityDictionary={state.entityDictionary}
              // State.
              chipKey={chip.key}
              type={chip.data.type}
              value={chip.data.value}
              label={display.label}
              icon={display.icon}
              // Flags.
              isTransparent={!isVisuallyFocused}
              isSelected={isSelected}
              isEditing={isEditing}
              isActive={isFocused}
              canEdit={display.canEdit}
              // Events.
              onChange={handleQueryChipChange}
              onSelect={handleQueryChipSelect}
              onStartEditing={handleQueryChipStartEditing}
              onStopEditing={handleQueryChipStopEditing}
              onShowDropdown={handleQueryChipShowDropdown}
              onDelete={handleQueryChipDelete}
              onSelectPrev={handleQueryChipPrev}
              onSelectNext={handleQueryChipNext}
            />
          </Spacer>
        );
      })}
      <input
        css={[styles.textInput, computedStyles.textInput]}
        ref={textInputRef}
        value={state.textInput}
        onKeyDown={handleKeyDown}
        onChange={handleTextInputChange}
        onFocus={handleTextInputFocus}
        onBlur={handleTextInputBlur}
      />
    </div>
  );
};

const styles = {
  base: css({
    minHeight: 28,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: '1 1 auto',
    flexWrap: 'wrap',
  }),
  textInput: css({
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 100,
    alignSelf: 'stretch',
    border: 'none',
    outline: 'none',
    background: 'none',
    fontSize: 14,
  }),
  moreChips: css({
    display: 'flex',
    alignItems: 'center',
    border: `dashed 1px ${color.format(0.33)}`,
    borderRadius: 2,
    backgroundColor: color.format(0.24),
    padding: '5px 10px',
    marginRight: 2,
    height: 14,
  }),
};

/**
 * Helper function to help create functions that create change handlers,
 * to be passed down to child components.
 * This function abstracts away from any particular state implementation.
 * Enforces immutability.
 */
function createChangeHandler(
  getState: () => IInternalState,
  setState: (state: IInternalState) => void,
) {
  return function <T>(
    handler: (state: IInternalState, data: T) => IInternalState,
  ) {
    return function (data: T) {
      const currentState = getState();
      const newState = handler(currentState, data);
      setState(newState);
    };
  };
}

function isLastChipSelected(state: IInternalState) {
  const lastChip = state.chips[state.chips.length - 1];
  const lastKey = lastChip && lastChip.key;
  return lastKey
    ? (state.editingChip && state.editingChip.key) === lastKey ||
        (state.selectedChip && state.selectedChip.key) === lastKey
    : false;
}

function findChip(state: IInternalState, chipKey: ChipKey) {
  const index = state.chips.findIndex((chip) => chip.key === chipKey);
  const chip = state.chips[index];
  return { index, chip: chip.data };
}

export const QueryBuilderInput = forwardRef(View);
