/** @jsxImportSource @emotion/react */
import { ClassNames, css, Interpolation, Theme } from '@emotion/react';
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CodeToggle,
  CreateLink,
  headingsPlugin,
  InsertTable,
  InsertThematicBreak,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  MDXEditorMethods,
  Separator,
  StrikeThroughSupSubToggles,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useEffect, useRef, useState } from 'react';
import { CommonStyles } from '../../common/commonStyles.ts';
import { FONTS } from '../../common/constants.ts';
import { MarkdownTextField } from '../MarkdownTextField/MarkdownTextField.tsx';
import { OutsideAlerter } from '../OutsideAlerter/OutsideAlerter.tsx';
import { Transition } from '../Transition/index.ts';

const PLUGINS = [
  headingsPlugin(),
  listsPlugin(),
  thematicBreakPlugin(),
  markdownShortcutPlugin(),
  linkDialogPlugin(),
  linkPlugin(),
  tablePlugin(),
  toolbarPlugin({
    toolbarContents: () => (
      <>
        <BoldItalicUnderlineToggles />
        <Separator />
        <CreateLink />
        <Separator />
        <ListsToggle options={['bullet', 'number']} />
      </>
    ),
  }),
];

const PLUGINS_EXTENDED = [
  headingsPlugin(),
  listsPlugin(),
  thematicBreakPlugin(),
  markdownShortcutPlugin(),
  linkDialogPlugin(),
  linkPlugin(),
  tablePlugin(),
  toolbarPlugin({
    toolbarContents: () => (
      <>
        <BoldItalicUnderlineToggles />
        <Separator />
        <BlockTypeSelect />
        <CreateLink />
        <Separator />
        <StrikeThroughSupSubToggles />
        <Separator />
        <ListsToggle options={['bullet', 'number', 'check']} />
        <InsertThematicBreak />
        <CodeToggle />
        <Separator />
        <InsertTable />
      </>
    ),
  }),
];

export type MarkdownEditorProps = {
  id: string;
  alwaysFocused?: boolean;
  error?: string;
  includeAllToolbarControls?: boolean;
  markdown: string;
  maxHeight?: string | number;
  onChange: (to: string) => void;
  placeholder?: string;
  style?: Interpolation<Theme>;
  title?: string;
  disableTransition?: boolean;
};

export const MarkdownEditor: React.FC<MarkdownEditorProps> = ({
  id,
  alwaysFocused,
  error,
  includeAllToolbarControls,
  markdown,
  maxHeight,
  onChange,
  placeholder,
  style,
  title,
  disableTransition,
}) => {
  const [focused, setFocused] = useState(alwaysFocused);

  const mounted = useRef(true);
  const editorRef = useRef<MDXEditorMethods>(null);

  useEffect(
    () => () => {
      mounted.current = false;
    },
    [],
  );

  const handleFocus = () => {
    if (alwaysFocused) return;
    setFocused(true);
  };

  const handleBlur = () => {
    if (alwaysFocused) return;

    /**
     * Allow clicks in other areas to register before removing focus on this.
     * This component resizes between focus/blur, and its really annoying for users
     * to try to click on something like a save button that shifts under them.
     */
    setTimeout(() => {
      setFocused(false);
    }, 125);
  };

  const handleChange = (to: string) => {
    if (!mounted.current) return;

    if (focused) {
      onChange(addWhitespaceToNewlines(to));
      return;
    }

    /**
     * Don't allow changes if not focused. We need to update the editor to the
     * previous markdown to effectively block this change.
     *
     * This also helps workaround the undo bug:
     *  https://github.com/mdx-editor/editor/issues/554
     */
    if (!editorRef.current) return;
    editorRef.current.setMarkdown(markdown);
  };

  const elMarkdownEditor = (
    <div>
      {title && (
        <Text
          size={12}
          style={[focused && styles.textFocused, error && styles.textError]}
        >
          {title}
        </Text>
      )}
      <div
        css={[styles.border, focused && styles.focused, error && styles.error]}
      >
        <ClassNames>
          {(classnames) => (
            <MDXEditor
              className={classnames.css({
                borderRadius: 'none',
                'div[role=toolbar]': {
                  borderRadius: '5px 5px 0 0',
                  padding: 2,
                },
              })}
              contentEditableClassName={classnames.css({
                maxHeight,
                overflow: maxHeight !== undefined ? 'auto' : undefined,
                fontFamily: FONTS.Roboto.family,
                p: {
                  lineHeight: '1.4375em',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                  marginBlockStart: 0,
                  marginBlockEnd: 0,
                },
                ul: {
                  marginBlockStart: 0,
                  marginBlockEnd: 0,
                  paddingInlineStart: 30,
                },
                ol: {
                  marginBlockStart: 0,
                  marginBlockEnd: 0,
                  paddingInlineStart: 30,
                },
              })}
              plugins={includeAllToolbarControls ? PLUGINS_EXTENDED : PLUGINS}
              autoFocus={{ defaultSelection: 'rootEnd' }}
              markdown={markdown}
              onChange={handleChange}
              placeholder={placeholder}
              ref={editorRef}
              suppressHtmlProcessing={true}
            />
          )}
        </ClassNames>
      </div>
      {error && (
        <Text
          size={12}
          color={COLORS.ERROR_RED}
          style={CommonStyles.AnimationShake}
        >
          {error}
        </Text>
      )}
    </div>
  );

  const elEditingText = disableTransition ? (
    focused && elMarkdownEditor
  ) : (
    <Transition.Slide
      in={focused}
      mountOnEnter={true}
      unmountOnExit={true}
      direction={'down'}
      timeout={focused ? 100 : 0}
    >
      {elMarkdownEditor}
    </Transition.Slide>
  );

  const elViewingText = !focused && (
    <div data-test={`MarkdownEditor-Viewing-${id}`} onClick={handleFocus}>
      <MarkdownTextField
        label={title}
        value={markdown}
        isEnabled={true}
        error={error}
        maxHeight={maxHeight}
        stripMarkdown={false}
      />
    </div>
  );

  return (
    <OutsideAlerter onClickedOutside={handleBlur}>
      <div css={[styles.base, style]}>
        {elEditingText}
        {elViewingText}
      </div>
    </OutsideAlerter>
  );
};

/**
 * Workaround for MDX Editor.
 * Markdown doesn't properly recognise newlines, this workaround will add
 * a space character to ensure newlines are retained.
 */
const addWhitespaceToNewlines = (input: string) =>
  input.replaceAll('\n\n\n', '\n&#x20;\n\n');

const styles = {
  base: [
    CommonStyles.MaterialCubicTransitions,
    css({
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
      maxHeight: 600,
      overflow: 'hidden',
      backgroundColor: 'white',
    }),
  ],
  border: css({
    border: `solid 1px ${color.format(-0.2)}`,
    borderRadius: 5,
  }),
  textFocused: css({
    color: COLORS.BLUE,
  }),
  textError: css({
    color: COLORS.ERROR_RED,
  }),
  focused: css({
    border: `solid 1px ${COLORS.BLUE}`,
  }),
  error: css({
    border: `solid 1px ${COLORS.ERROR_RED}`,
  }),
};
